import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from '@/components/ui/form';
import {
  useController,
  useFormContext,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { ChangeEvent, forwardRef, useEffect, useMemo, useState } from 'react';
import { COLLATERAL_DECIMALS, useMarketStore } from '@/store/use-markets-store';
import { OrderType } from '@/types/enums';
import {
  BigDecimal,
  bigIntToDecimalStr,
  bigIntToNum,
  formatBigInt,
  parseDecimalToBigInt,
  parseInputToDecimalStr,
  validatedDecimalStr,
} from '@/utils/value-format';
import { useStore } from 'zustand';
import { Account, useAccountStore } from '@/store/use-account-store';
import {
  getPositionInitMargin,
  getRealizedPnl,
  getUpdatedAccountOnFill,
  MarketInfo,
} from '@/features/account/utils/math';
import { getPriceFromPnl, getSizeFromNumeraire } from '../utils/math';
import { UnitSelect } from './unit-select';
import { Input } from './input';
import { abbrFromSym } from '@/utils/token-symbol';
import { cn } from '@/utils/cn';
import { NewOrder } from '@/store/use-orders-store';

/**
 * Component handles single Take Profit OR Stop Loss order.
 */

// TODO: refactor for performance (add some debouncing)

type TpslInputProps = {
  orderType: OrderType.STOP_LOSS | OrderType.TAKE_PROFIT;
  symbol: string;
  name: string; // form name
  canChangeSize?: boolean;
  showOrderType?: boolean;
  parentOrderName?: string;
  shouldSizeAutoResize?: boolean;
  index: number;
  initMarginRatio?: bigint;
};

const pnlUnits: string[] = ['%', 'USDC'];

export const TpslInput = forwardRef(
  (
    {
      orderType,
      parentOrderName,
      name,
      symbol,
      initMarginRatio,
      canChangeSize = false,
      showOrderType = false,
      shouldSizeAutoResize = true,
      index,
      ...props
    }: TpslInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { control } = useFormContext();

    const parentOrder = useWatch({
      control,
      name: parentOrderName || '',
      disabled: !parentOrderName,
    });

    const fields = useWatch({
      control,
      name: orderType === OrderType.TAKE_PROFIT ? 'tps' : 'sls',
    });

    const { marketData, marketSpec } = useStore(
      useMarketStore(symbol),
      (state) => ({
        marketData: state.marketData,
        marketSpec: state.marketSpec,
      }),
    );
    const price =
      parentOrder && parentOrder.orderType === OrderType.LIMIT
        ? parseDecimalToBigInt(parentOrder.limitPrice, marketSpec.priceDecimals)
        : marketData.markPrice;

    const { minLimitPrice, maxLimitPrice } = useMemo(() => {
      const minLimitPrice = price / 10n; // TODO: round up
      const maxLimitPrice = price * 10n; // TODO: round down
      return { minLimitPrice, maxLimitPrice };
    }, [price]);

    const { account } = useAccountStore((state) => ({
      account: state.account,
    }));

    const updatedAccount = useMemo(() => {
      let updatedAccount: Account = account;
      if (parentOrder !== undefined) {
        updatedAccount = getUpdatedAccountOnFill(
          symbol,
          parentOrder.isBuy,
          parseDecimalToBigInt(parentOrder.size, marketSpec.sizeDecimals),
          price,
          0n,
          initMarginRatio ||
            parseDecimalToBigInt(parentOrder.initMarginRatio, 4n),
          account,
          marketData,
        );
      }
      return updatedAccount;
    }, [account, price, marketData, marketSpec, parentOrder]);

    // const defaultSize
    const { field: limitPriceField } = useController({
      name: `${name}.limitPrice`,
      control,
    });
    const { field: sizeField } = useController({
      name: `${name}.size`,
      control,
      disabled: !canChangeSize,
      defaultValue: updatedAccount.positions[symbol]?.size,
    }); // TODO: default size

    const limitPriceWatch = useWatch({ control, name: `${name}.limitPrice` });
    const sizeWatch = useWatch({ control, name: `${name}.size` });

    const handleChangePriceInput = (value: string) => {
      value = validatedDecimalStr(value, Number(marketSpec.priceDecimals), 10);
      const valueBigInt = parseDecimalToBigInt(value, marketSpec.priceDecimals);
      if (valueBigInt > maxLimitPrice) {
        return;
      }
      limitPriceField.onChange(value);
      setLastChanged('price');
    };

    const [pnlUnit, setPnlUnit] = useState(pnlUnits[0]);
    const handleChangePnlUnit = (value: string) => {
      setPnlUnit(value);
      setLastChanged('pnl');
    };

    const defaultPnlInput = useMemo(() => {
      if (
        limitPriceField.value === '' ||
        !account.positions[symbol] ||
        account.positions[symbol].size === 0n
      ) {
        return '';
      }
      // temp same code as below
      const sign = orderType === OrderType.TAKE_PROFIT ? 1n : -1n;
      const expectedPnl = getRealizedPnl(
        symbol,
        !account.positions[symbol].isLong,
        parseDecimalToBigInt(sizeField.value, marketSpec.sizeDecimals),
        parseDecimalToBigInt(limitPriceField.value, marketSpec.priceDecimals),
        0n, // Assume no funding
        0n, // Assume no fees
        account,
        marketSpec,
      );
      const initMargin = getPositionInitMargin(symbol, account, {
        [symbol]: { marketData, marketSpec } as MarketInfo,
      }).bigint;
      const expectedRoe = (expectedPnl.bigint * 10n ** 6n) / initMargin;
      return formatBigInt(sign * expectedRoe, 4n, { digits: 0, round: 'ceil' });
    }, []);

    const [pnlInput, setPnlInput] = useState(defaultPnlInput);

    const handleChangePnlInput = (value: string) => {
      const inputValue = value.replace(/,/g, '');
      if (inputValue !== '' && !/^(\d+|\d*\.\d{0,2})$/.test(inputValue)) {
        return;
      }
      setPnlInput((prev) =>
        inputValue.endsWith('.') && prev.length > inputValue.length
          ? inputValue.slice(0, -1)
          : inputValue,
      );
      setLastChanged('pnl');
    };

    const handleBlurPnlInput = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value.endsWith('.')) return;
      setPnlInput(e.target.value.slice(0, -1));
    };

    const [lastChanged, setLastChanged] = useState<'price' | 'pnl' | null>(
      null,
    );

    const sizeUnits = [...pnlUnits, abbrFromSym(symbol)];
    const [sizeUnit, setSizeUnit] = useState(sizeUnits[0]);

    const updatedSizeInput = useMemo(() => {
      if (!account.positions[symbol] || !fields) return '';
      const size = bigIntToNum(
        account.positions[symbol].size,
        marketSpec.sizeDecimals,
      );
      const sizePct = Math.floor((size / fields.length / size) * 100);
      const remainder =
        index === fields.length - 1 ? 100 - sizePct * fields.length : 0;
      return (sizePct + remainder).toString();
    }, [index, fields?.length]);

    const defaultSizeInput = useMemo(() => {
      if (!account.positions[symbol]) return '';
      const size = parseDecimalToBigInt(
        sizeField.value,
        marketSpec.sizeDecimals,
      );
      const sizePct = (size * 10n ** 6n) / account.positions[symbol].size;
      return formatBigInt(sizePct, 4n, { digits: 0, round: 'ceil' });
    }, []);

    const [sizeInput, setSizeInput] = useState(
      shouldSizeAutoResize ? updatedSizeInput : defaultSizeInput,
    );

    useEffect(() => {
      if (sizeUnit !== '%' || !shouldSizeAutoResize) return;
      setSizeInput(updatedSizeInput);
    }, [updatedSizeInput, shouldSizeAutoResize]);

    const handleChangeSizeUnit = (newUnit: string) => {
      if (sizeUnit === newUnit) return;
      const currentPosition = account.positions[symbol];

      const input = sizeInput || '0';

      switch (sizeUnit) {
        case '%':
          const percentage = parseDecimalToBigInt(
            parseInputToDecimalStr(input, 0),
            0,
          );
          if (newUnit === 'USDC') {
            // % -> USDC
            const usdcValue =
              (currentPosition.size * marketData.markPrice * percentage) / 100n;
            setSizeInput(
              formatBigInt(
                usdcValue,
                marketSpec.priceDecimals + marketSpec.sizeDecimals,
                { maxDigits: 2, round: 'floor', commas: false },
              ),
            );
          } else {
            // % -> coin
            const coinValue = (currentPosition.size * percentage) / 100n;
            setSizeInput(
              bigIntToDecimalStr(coinValue, marketSpec.sizeDecimals),
            );
          }
          break;
        case 'USDC':
          const usdcTotal = currentPosition.size * marketData.markPrice;
          const usdcValue = parseDecimalToBigInt(
            input,
            marketSpec.priceDecimals + marketSpec.sizeDecimals,
          );
          const percentageValue =
            (usdcValue * 100n + usdcTotal - 1n) / usdcTotal;
          if (newUnit === '%') {
            // USDC -> %
            setSizeInput(
              bigIntToDecimalStr(
                percentageValue > 100n ? 100n : percentageValue,
                0,
              ),
            );
          } else {
            // USDC -> coin
            const coinTotal = currentPosition.size;
            const coinValue = (coinTotal * percentageValue) / 100n;
            setSizeInput(
              bigIntToDecimalStr(coinValue, marketSpec.sizeDecimals),
            );
          }
          break;
        case abbrFromSym(symbol):
          const coinsTotal = currentPosition.size;
          const coinsValue = parseDecimalToBigInt(
            input,
            marketSpec.sizeDecimals,
          );
          const coinsPercentage =
            (coinsValue * 100n + coinsTotal - 1n) / coinsTotal;
          if (newUnit === '%') {
            // coin -> %
            setSizeInput(bigIntToDecimalStr(coinsPercentage, 0));
          } else {
            // coin -> USDC
            const usdcTotal = currentPosition.size * marketData.markPrice;
            const usdcValue = (usdcTotal * coinsPercentage) / 100n;
            setSizeInput(
              formatBigInt(
                usdcValue,
                marketSpec.priceDecimals + marketSpec.sizeDecimals,
                { maxDigits: 2, commas: false },
              ),
            );
          }
          break;
      }
      setSizeUnit(newUnit);
    };

    const handleChangeSizeInput = (value: string) => {
      switch (sizeUnit) {
        case 'USDC':
          value = validatedDecimalStr(value, 2, 10);
          if (
            parseDecimalToBigInt(
              value,
              marketSpec.sizeDecimals + marketSpec.priceDecimals,
            ) >
            updatedAccount.positions[symbol].size * price
          ) {
            return;
          }
          setSizeInput(value);
          break;

        case '%':
          value = validatedDecimalStr(value, 0, 3);
          if (Number(value) > 100) {
            return;
          }
          setSizeInput(value);
          break;

        case abbrFromSym(symbol):
          value = validatedDecimalStr(
            value,
            Number(marketSpec.sizeDecimals),
            10,
          );
          if (
            parseDecimalToBigInt(value, marketSpec.sizeDecimals) >
            updatedAccount.positions[symbol].size
          )
            return;
          setSizeInput(value);
          break;

        default:
          throw new Error('Invalid size unit');
      }
    };

    // set form.size from sizeInput and sizeUnit
    useEffect(() => {
      if (!canChangeSize || !updatedAccount.positions[symbol]) return;

      let size: bigint;

      switch (sizeUnit) {
        case 'USDC':
          const numeraireSize = parseDecimalToBigInt(
            sizeInput,
            COLLATERAL_DECIMALS,
          );
          size = getSizeFromNumeraire(numeraireSize, price, marketSpec);
          break;

        case '%':
          const sizePct = parseDecimalToBigInt(sizeInput, 4n);
          size = (sizePct * updatedAccount.positions[symbol].size) / 10n ** 6n;
          break;

        case abbrFromSym(symbol):
          size = parseDecimalToBigInt(sizeInput, marketSpec.sizeDecimals);
          break;

        default:
          throw new Error('Invalid size unit');
      }
      sizeField.onChange(bigIntToDecimalStr(size, marketSpec.sizeDecimals));
    }, [sizeInput, sizeUnit]);

    // Set pnl from price
    useEffect(() => {
      if (lastChanged !== 'price') return;

      // if we want price to update when we change fields, just add marketData to dependency array and setLastChanged to null at end

      if (limitPriceField.value === '' || !updatedAccount.positions[symbol]) {
        setPnlInput('');
        return;
      }

      const sign = orderType === OrderType.TAKE_PROFIT ? 1n : -1n;

      const size = parseDecimalToBigInt(
        sizeField.value,
        marketSpec.sizeDecimals,
      );
      const orderPrice = parseDecimalToBigInt(
        limitPriceField.value,
        marketSpec.priceDecimals,
      );

      const expectedPnl = parentOrder ? sign * (orderPrice - price) * size * (parentOrder.isBuy ? 1n : -1n):
        sign *
        getRealizedPnl(
          symbol,
          !updatedAccount.positions[symbol].isLong,
          size,
          orderPrice,
          0n, // Assume no funding
          0n, // Assume no fees
          updatedAccount,
          marketSpec,
        ).bigint;

      switch (pnlUnit) {
        case 'USDC':
          setPnlInput(
            formatBigInt(expectedPnl, COLLATERAL_DECIMALS, {
              digits: 2,
              round: 'ceil',
            }),
          ); // quantize to 2 decimal places?
          break;

        case '%':
          if (size === 0n || parentOrder) {
            // if size is 0 or setting TP/SL on order, pnl will be % change in mark price
            const leverage = account.leverages.find((lvg) => lvg.symbol === symbol)?.value || 1;
            const pctChange = BigInt(leverage) * (parentOrder ? parentOrder.isBuy ? 1n : -1n : 1n) * sign * ((orderPrice - price) * 10n ** 4n) / price;
            setPnlInput(
              formatBigInt(pctChange, 2n, {
                digits: 0,
                round: 'ceil',
                commas: false,
              }),
            );
            return;
          }
          const initMargin = getPositionInitMargin(symbol, updatedAccount, {
            [symbol]: {
              marketData: { ...marketData, markPrice: price },
              marketSpec,
            } as MarketInfo,
          }).bigint;
          const expectedRoe = (expectedPnl * 10n ** 4n) / initMargin;
          setPnlInput(
            formatBigInt(expectedRoe, 2n, { digits: 0, round: 'ceil' }),
          );
          break;
      }

      setLastChanged(null);
    }, [updatedAccount, symbol, lastChanged, limitPriceWatch, sizeWatch]);

    // Set price from pnl
    useEffect(() => {
      if (lastChanged !== 'pnl') return;

      if (pnlInput === '' || !updatedAccount.positions[symbol]) {
        limitPriceField.onChange('');
        return;
      }

      if (pnlInput) {
        const sign = orderType === OrderType.TAKE_PROFIT ? 1n : -1n;

        const size = parseDecimalToBigInt(
          sizeField.value,
          marketSpec.sizeDecimals,
        );

        let pnl: bigint;
        let orderPrice: BigDecimal;

        switch (pnlUnit) {
          case 'USDC': // must have size defined
            if (size === 0n) {
              limitPriceField.onChange('');
              return;
            }
            pnl = sign * parseDecimalToBigInt(pnlInput, COLLATERAL_DECIMALS);
            orderPrice = parentOrder ? 
             {
              bigint: price + (parentOrder.isBuy ? 1n : -1n) * pnl / size,
              decimal: bigIntToDecimalStr(price + (parentOrder.isBuy ? 1n : -1n) * pnl / size, marketSpec.priceDecimals),
            } : getPriceFromPnl(
              symbol,
              pnl,
              size,
              updatedAccount,
              marketSpec,
            );
            limitPriceField.onChange(orderPrice.decimal);
            break;

          case '%':
            if (size === 0n || parentOrder) {
              // If size is 0 or setting TP/SL on order, we interpret pnl as % change of mark price
              const leverage = account.leverages.find((lvg) => lvg.symbol === symbol)?.value || 1;
              const pnlPct = (parentOrder.isBuy ? 1n : -1n) * sign * parseDecimalToBigInt(pnlInput, 4n) / BigInt(leverage);
              const orderPrice = (price * (10n ** 6n + pnlPct)) / 10n ** 6n;
              limitPriceField.onChange(
                bigIntToDecimalStr(orderPrice, marketSpec.priceDecimals),
              );
              return;
            }
            const initMargin = getPositionInitMargin(symbol, updatedAccount, {
              [symbol]: { marketData, marketSpec } as MarketInfo,
            }).bigint;
            const pnlPct = sign * parseDecimalToBigInt(pnlInput, 2n);
            pnl = (pnlPct * initMargin) / 10n ** 4n; // note we are multiplying by pct so divide by 10^2 to multiply by fraction
            orderPrice = getPriceFromPnl(
              symbol,
              pnl,
              size,
              updatedAccount,
              marketSpec,
            );
            limitPriceField.onChange(orderPrice.decimal);
            break;
        }
      }
    }, [pnlInput, pnlUnit, updatedAccount, lastChanged, sizeWatch]);

    if (!updatedAccount.positions[symbol]) return <></>;

    return (
      <div className="mr-3 flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <FormField
            control={control}
            name={name}
            render={({ field }) => (
              <FormItem className="flex-1">
                <div
                  className={cn(
                    'flex items-center gap-2',
                    canChangeSize && 'flex-1',
                  )}
                >
                  <FormLabel className="text-vestgrey-500">
                    {showOrderType &&
                      (orderType === OrderType.TAKE_PROFIT ? 'TP' : 'SL')}{' '}
                    Price
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="flex-1"
                      {...limitPriceField}
                      onChange={handleChangePriceInput}
                    />
                  </FormControl>
                </div>
              </FormItem>
            )}
          />
          <div className="flex flex-1 items-center">
            <div className="mr-3 w-8 text-right text-sm text-vestgrey-500">
              {orderType === OrderType.TAKE_PROFIT ? 'Profit' : 'Loss'}
            </div>
            <div className="flex flex-1">
              <Input
                className="w-[7ch] flex-1"
                value={pnlInput}
                onChange={handleChangePnlInput}
                onBlur={handleBlurPnlInput}
              />
              <UnitSelect
                units={pnlUnits}
                onSelect={handleChangePnlUnit}
                disabled={
                  parseDecimalToBigInt(
                    sizeField.value,
                    marketSpec.sizeDecimals,
                  ) === 0n
                }
              />
            </div>
          </div>
          {canChangeSize && (
            <div className="flex flex-1 items-center">
              <div className="mr-3 text-sm text-vestgrey-500">Size</div>
              <Input
                className="flex-1"
                value={sizeInput}
                onChange={handleChangeSizeInput}
              />
              <UnitSelect
                units={[...pnlUnits, abbrFromSym(symbol)]}
                onSelect={handleChangeSizeUnit}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);
