import { useCallback } from 'react';
import {
  useWallet,
  useConnection,
  useAnchorWallet,
} from '@solana/wallet-adapter-react';
import {
  PublicKey,
  Keypair,
  Transaction,
  TransactionInstruction,
  ComputeBudgetProgram,
} from '@solana/web3.js';

import { MINT_RECIPIENT, USDC_MINT_ADDRESS } from '../wagmi/config';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { BN, AnchorProvider } from '@coral-xyz/anchor';

import {
  getDepositForBurnPdas,
  getPrioritizationFees,
  getPrograms,
} from '@/utils/solana';
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { toast } from 'sonner';
import { useFetchAccount } from '@/features/account/api/get-account';
import { getSimulationComputeUnits } from '@solana-developers/helpers';

import { useTransferDepositSolana } from './transfer-deposit-solana';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types';
import { logConsole } from '../utils';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useAccountStore } from '@/store/use-account-store';
import { getTransfers } from '@/features/account/api/get-transfers';
import { ToastProgressBar } from '@/components/ui/toast-progressbar';

const MEMO_PROGRAM_ID = new PublicKey(
  'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr',
);

export const useSolanaDeposit = (_size: string) => {
  const size = parseDecimalToBigInt(
    _size.endsWith('.') ? `${_size}00` : _size,
    COLLATERAL_DECIMALS,
  );

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useAnchorWallet();
  const { data: account, refetch } = useFetchAccount();

  const {
    mutateAsync: transferDepositSolana,
    error: _transferDepositSolanaError,
    isPending,
  } = useTransferDepositSolana();

  const transferDepositSolanaError =
    _transferDepositSolanaError as unknown as ErrorCode;

  const depositForBurn = useCallback(async () => {
    if (!publicKey || !wallet) {
      toast.error('Wallet is not connected.');
      return;
    }
    if (!account?.address) {
      toast.error(
        'No such registered address found. Retry connecting your wallet.',
      );
      return;
    }

    try {
      const provider = new AnchorProvider(connection, wallet, {
        preflightCommitment: 'processed',
      });

      const { messageTransmitterProgram, tokenMessengerMinterProgram } =
        getPrograms(provider);

      const usdcAddress = new PublicKey(USDC_MINT_ADDRESS);

      const userTokenAccount = await getAssociatedTokenAddress(
        new PublicKey(USDC_MINT_ADDRESS),
        publicKey,
      );

      const destinationDomain = 3;
      const amount = new BN(size);
      const mintRecipientHex = `0x000000000000000000000000${MINT_RECIPIENT}`;
      const mintRecipient = new PublicKey(
        Buffer.from(mintRecipientHex.slice(2), 'hex'),
      );

      const pdas = getDepositForBurnPdas(
        { messageTransmitterProgram, tokenMessengerMinterProgram },
        usdcAddress,
        destinationDomain,
      );

      // Generate a new keypair for the MessageSent event account.
      const messageSentEventAccountKeypair = Keypair.generate();

      const memoInstruction = new TransactionInstruction({
        keys: [
          {
            pubkey: publicKey,
            isSigner: true,
            isWritable: true,
          },
        ],
        programId: MEMO_PROGRAM_ID,
        data: Buffer.from(account?.address, 'utf-8'),
      });

      const instruction = await tokenMessengerMinterProgram.methods
        .depositForBurn({
          amount,
          destinationDomain,
          mintRecipient,
        })
        .accounts({
          owner: provider.wallet.publicKey,
          eventRentPayer: provider.wallet.publicKey,
          senderAuthorityPda: pdas.authorityPda.publicKey,
          burnTokenAccount: userTokenAccount,
          messageTransmitter: pdas.messageTransmitterAccount.publicKey,
          tokenMessenger: pdas.tokenMessengerAccount.publicKey,
          remoteTokenMessenger: pdas.remoteTokenMessengerKey.publicKey,
          tokenMinter: pdas.tokenMinterAccount.publicKey,
          localToken: pdas.localToken.publicKey,
          burnTokenMint: usdcAddress,
          messageTransmitterProgram: messageTransmitterProgram.programId,
          tokenMessengerMinterProgram: tokenMessengerMinterProgram.programId,
          messageSentEventData: messageSentEventAccountKeypair.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .signers([messageSentEventAccountKeypair])
        .instruction();

      let units: number = 270;
      try {
        units =
          (await getSimulationComputeUnits(
            connection,
            [instruction, memoInstruction],
            publicKey,
            [],
          )) ?? 270;
      } catch (error) {
        console.error('Compute units error', error);
      }

      const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: units * 1.1,
      });

      const microLamports = await getPrioritizationFees(connection);

      logConsole(false, 'microLamports', microLamports);

      // Call depositForBurn
      const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports,
      });

      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash('finalized');

      const transaction = new Transaction();

      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;
      transaction.feePayer = publicKey;

      transaction
        .add(modifyComputeUnits)
        .add(addPriorityFee)
        .add(instruction)
        .add(memoInstruction);

      transaction.sign(messageSentEventAccountKeypair);

      const promise = sendTransaction(transaction, connection, {
        skipPreflight: false,
        preflightCommitment: 'processed',
      });

      promise.then((depositForBurnTx) => {
        const toastPromise = new Promise((resolve, reject) => {
          logConsole(false, depositForBurnTx);

          try {
            const depositToast = toast.custom(
              (t) => <ToastProgressBar message="Depositing..." limit={20000} />,
              { duration: Infinity },
            );

            // Call the function once
            transferDepositSolana(depositForBurnTx as string);

            const time = useAccountStore.getState().getAccurateTime();

            let pollIntervalId: NodeJS.Timeout;
            let subscriberTimeoutId: NodeJS.Timeout;
            let unsubscribe: () => void;

            const cleanup = () => {
              clearTimeout(subscriberTimeoutId);
              clearInterval(pollIntervalId);
              unsubscribe();
              toast.dismiss(depositToast);
            };

            unsubscribe = useTransfersStore.subscribe((state) => {
              if (state.newDeposits.length > 0) {
                for (const deposit of state.newDeposits) {
                  if (
                    deposit.postTime >= time &&
                    ('size' in deposit ? deposit.size === size : true)
                  ) {
                    state.removeNewDeposit(deposit.id);
                    if (deposit.status === OrderStatus.FILLED) {
                      cleanup();
                      resolve(deposit);
                    } else if (deposit.status === OrderStatus.REJECTED) {
                      cleanup();
                      reject(deposit.code as ErrorCode);
                    }
                  }
                }
              }
            });

            subscriberTimeoutId = setTimeout(() => {
              cleanup();
              reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
            }, 120_000);

            pollIntervalId = setInterval(() => {
              getTransfers({ startTime: time });
            }, 2_000);
          } catch (error) {
            console.error('Error during transferDepositSolana:', error);
            reject(error as ErrorCode); // Reject with the error
          }
        });

        toast.promise(toastPromise, {
          success: () => {
            return `Deposited ${_size} USDC`;
          },
          error: (error) => {
            return `Deposit failed: ${ErrorMsgs[error as ErrorCode]}`;
          },
        });
      });

      promise.catch((error) => {
        const errorMessage =
          error?.message ||
          (typeof error === 'string' ? error : 'An unknown error occurred.');

        toast.error(
          errorMessage.includes('not connected')
            ? 'Wallet is not connected or wrong account is active.'
            : errorMessage,
        );
      });
    } catch (error) {
      console.error('Error during depositForBurn:', error);
      toast.error('Deposit failed. Check the console for more details.');
    }
  }, [connection, publicKey, size, account, wallet]);

  return { depositForBurn };
};
