import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { Order, useOrdersStore } from '@/store/use-orders-store';
import { OrderStatus, OrderType } from '@/types/enums';
import {
  COLLATERAL_DECIMALS,
  getAllMarketStoreStates,
  MarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { getUpdatedAccountOnFill } from '../utils/math';
import { useUnifiedAccount } from '@/hooks';
import { useEffect } from 'react';

interface GetOrdersParams {
  id?: string;
  symbol?: string;
  orderType?: OrderType;
  status?: OrderStatus;
  startTime?: number;
  endTime?: number;
  limit?: number;
}

export type NewResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  tpPrice?: string;
  slPrice?: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.NEW;
  initMarginRatio: string;
  postTime: number;
};

export type FilledResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  tpPrice?: string;
  slPrice?: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.FILLED;
  initMarginRatio: string;
  lastFilledSize: string;
  lastFilledPrice: string;
  lastFilledTime: number;
  avgFilledPrice: string;
  realizedPnl: string;
  settledFunding: string;
  fees: string;
  postTime: number;
  markPrice: string;
};

export type CancelledResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  tpPrice?: string;
  slPrice?: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.CANCELLED;
  initMarginRatio: string;
  postTime: number;
};

export type RejectedResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  tpPrice?: string;
  slPrice?: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.REJECTED;
  initMarginRatio: string;
  postTime: number;
  code: number;
};

export type OrderResponse =
  | NewResponse
  | FilledResponse
  | CancelledResponse
  | RejectedResponse;

export const orderResponseToOrder = (
  orderResponse: OrderResponse,
  marketStores: Record<string, MarketStore>,
): Order => {
  const { marketSpec } = marketStores[orderResponse.symbol];
  switch (orderResponse.status) {
    case OrderStatus.NEW:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
        tpPrice: orderResponse.tpPrice
          ? parseDecimalToBigInt(
              orderResponse.tpPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
        slPrice: orderResponse.slPrice
          ? parseDecimalToBigInt(
              orderResponse.slPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
      };
    case OrderStatus.FILLED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
        tpPrice: orderResponse.tpPrice
          ? parseDecimalToBigInt(
              orderResponse.tpPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
        slPrice: orderResponse.slPrice
          ? parseDecimalToBigInt(
              orderResponse.slPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
        lastFilledSize: parseDecimalToBigInt(
          orderResponse.lastFilledSize,
          marketSpec.sizeDecimals,
        ),
        lastFilledPrice: parseDecimalToBigInt(
          orderResponse.lastFilledPrice,
          marketSpec.priceDecimals,
        ),
        avgFilledPrice: parseDecimalToBigInt(
          orderResponse.avgFilledPrice,
          marketSpec.priceDecimals,
        ),
        realizedPnl: parseDecimalToBigInt(
          orderResponse.realizedPnl,
          COLLATERAL_DECIMALS,
        ),
        settledFunding: parseDecimalToBigInt(
          orderResponse.settledFunding,
          COLLATERAL_DECIMALS,
        ),
        fees: parseDecimalToBigInt(orderResponse.fees, COLLATERAL_DECIMALS),
        markPrice: parseDecimalToBigInt(
          orderResponse.markPrice,
          marketSpec.priceDecimals,
        ),
      };
    case OrderStatus.CANCELLED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
        tpPrice: orderResponse.tpPrice
          ? parseDecimalToBigInt(
              orderResponse.tpPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
        slPrice: orderResponse.slPrice
          ? parseDecimalToBigInt(
              orderResponse.slPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
      };
    case OrderStatus.REJECTED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
        tpPrice: orderResponse.tpPrice
          ? parseDecimalToBigInt(
              orderResponse.tpPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
        slPrice: orderResponse.slPrice
          ? parseDecimalToBigInt(
              orderResponse.slPrice,
              marketSpec.priceDecimals,
            )
          : undefined,
      };
  }
};

export const getOrders = async ({
  id,
  symbol,
  orderType,
  status,
  startTime,
  endTime = useAccountStore.getState().getAccurateTime(),
  limit = 1000,
}: GetOrdersParams): Promise<OrderResponse[]> => {
  return privateApi
    .get('/orders', {
      params: {
        id,
        symbol,
        orderType,
        status,
        startTime,
        endTime,
        limit,
      },
    })
    .then((res) => {
      res.forEach(handleOrder);
      return res;
    });
};

export const getOrdersQueryOptions = (
  params: GetOrdersParams,
  address: string | undefined,
) => {
  const { endTime, limit, ...restOfParams } = params;
  const paramsToCache = { ...restOfParams };
  return queryOptions({
    queryKey: ['Orders', paramsToCache, address],
    queryFn: () => getOrders(params),
  });
};

type UseOrdersOptions = GetOrdersParams & {
  queryConfig?: QueryConfig<typeof getOrdersQueryOptions>;
};

export const useOrders = ({
  queryConfig,
  ...params
}: UseOrdersOptions = {}) => {
  const { address } = useUnifiedAccount();
  const hasAuth = useAccountStore((state) => state.hasAuth);
  const result = useQuery({
    enabled: !!address && hasAuth,
    ...getOrdersQueryOptions(params, address),
    ...queryConfig,
    staleTime: 0,
  });

  // useEffect(() => {
  //   if (!address || !result.data) return;
  //   result.data.forEach(handleOrder);
  // }, [result.data, address]);

  return result;
};

const handleOrder = (orderResponse: OrderResponse) => {
  const marketStores = getAllMarketStoreStates();
  const { setOrder } = useOrdersStore.getState();

  const order = orderResponseToOrder(orderResponse, marketStores);
  setOrder(order);

  // Update account with filled order (primarily handle the case if ws misses a fill)
  const { account, accountLastUpdated, setAccount, setAccountLastUpdated } =
    useAccountStore.getState();

  if (
    order.status === OrderStatus.FILLED &&
    order.lastFilledTime > accountLastUpdated
  ) {
    const updatedAccount = getUpdatedAccountOnFill(
      order.symbol,
      order.isBuy,
      order.lastFilledSize,
      order.lastFilledPrice,
      order.realizedPnl,
      order.initMarginRatio,
      account,
      marketStores[order.symbol].marketData,
    );
    setAccount(updatedAccount);
    setAccountLastUpdated(order.lastFilledTime);
  }
};
