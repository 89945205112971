import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { privateApi } from '@/lib/api-clients/rest-client';
import { MutationConfig } from '@/lib/api-clients/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { signMessage } from 'viem/accounts';
import { encodeAbiParameters, keccak256 } from 'viem';
import {
  FilledOrder,
  NewOrder,
  useOrdersStore,
} from '@/store/use-orders-store';
import { ErrorCode, ErrorMsgs, OrderStatus, OrderType } from '@/types/enums';
import createOrderToast from '../components/toast/create-order';

export const createOrderInputSchema = z.object({
  symbol: z.string(),
  isBuy: z.boolean(),
  size: z
    .string()
    .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
    .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
  orderType: z.nativeEnum(OrderType),
  limitPrice: z
    .string()
    .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
    .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
  reduceOnly: z.boolean().optional(),
  initMarginRatio: z.string().optional(),
  tpPrice: z
    .string()
    .optional()
    .or(
      z
        .string()
        .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
        .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
    ),
  slPrice: z
    .string()
    .optional()
    .or(
      z
        .string()
        .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
        .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
    ),
  tpSignature: z
    .string()
    .optional()
    .or(
      z
        .string()
        .regex(/^0[xX][0-9a-fA-F]+$/, 'Signature must be hexadecimal string'),
    ),
  slSignature: z
    .string()
    .optional()
    .or(
      z
        .string()
        .regex(/^0[xX][0-9a-fA-F]+$/, 'Signature must be hexadecimal string'),
    ),
});

export type CreateOrderInput = z.infer<typeof createOrderInputSchema>;

export type CreateOrderResponse = {
  id: string;
  status: OrderStatus;
};

export const createOrder = async ({
  order,
  showToast,
  waitForConfirm = false,
  useSystemTime = true,
}: {
  order: CreateOrderInput;
  showToast: boolean;
  waitForConfirm?: boolean;
  useSystemTime?: boolean;
}): Promise<CreateOrderResponse> => {
  const { getAuthParams, nextNonce } = useAccountStore.getState();
  const authParams = getAuthParams();

  if (!authParams?.signingKey) {
    console.error('No signing key found');
    throw new Error('No signing key found');
  }

  const time = useAccountStore.getState().getAccurateTime();
  const nonce = nextNonce();

  const abiParamTypes = [
    { name: 'time', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'orderType', type: 'string' },
    { name: 'symbol', type: 'string' },
    { name: 'isBuy', type: 'bool' },
    { name: 'size', type: 'string' },
    { name: 'limitPrice', type: 'string' },
    { name: 'reduceOnly', type: 'bool' },
  ];

  const encodedOrder = encodeAbiParameters(abiParamTypes, [
    BigInt(time),
    BigInt(nonce),
    order.orderType,
    order.symbol,
    order.isBuy,
    order.size,
    order.limitPrice,
    order.reduceOnly ?? false, // TODO: dangerous?
  ]);

  const signature = await signMessage({
    message: { raw: keccak256(encodedOrder) },
    privateKey: authParams.signingKey,
  });

  // Helper function to generate signature for a price target
  const generatePriceSignature = async (orderType: string, targetPrice: string, nonce: number) => {
    const encodedTargetOrder = encodeAbiParameters(abiParamTypes, [
      BigInt(time),
      BigInt(nonce),
      orderType,
      order.symbol,
      !order.isBuy,
      order.size,
      targetPrice,
      true,
    ]);

    return signMessage({
      message: { raw: keccak256(encodedTargetOrder) },
      privateKey: authParams.signingKey,
    });
  };

  // Generate signatures for TP/SL if they exist
  // Signature depends on nonce for TP / SL.  To simplify coordination, we have the frontend and backend
  // treat TP nonce as original order nonce + 1 and SL nonce as original order nonce + 2
  const tpSignature = order.tpPrice
    ? await generatePriceSignature(OrderType.TAKE_PROFIT, order.tpPrice, nonce + 1)
    : undefined;
  const slSignature = order.slPrice
    ? await generatePriceSignature(OrderType.STOP_LOSS, order.slPrice, nonce + 2)
    : undefined;

  interface OrderBody {
    order: typeof order & { nonce: number; time: number };
    signature: string;
    recvWindow: number;
  }

  // Create a clean order object, removing tpPrice and slPrice
  const { tpPrice: _, slPrice: __, ...cleanOrder } = order;

  const body: OrderBody = {
    order: { ...cleanOrder, nonce, time },
    signature,
    recvWindow: 10_000,
  };

  // Only include TP/SL fields for LIMIT orders
  if (order.orderType === OrderType.LIMIT) {
    if (order.tpPrice) body.order.tpPrice = order.tpPrice;
    if (order.slPrice) body.order.slPrice = order.slPrice;
    if (tpSignature) body.order.tpSignature = tpSignature;
    if (slSignature) body.order.slSignature = slSignature;
  }

  const restPromise = privateApi.post('/orders', body);

  const confirmPromise = restPromise.then((res) => {
    const { id: orderId } = res;
    return new Promise<FilledOrder | NewOrder>((resolve, reject) => {
      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        unsubscribe();
      };

      const successStatus =
        order.orderType === OrderType.MARKET
          ? OrderStatus.FILLED
          : OrderStatus.NEW;

      unsubscribe = useOrdersStore.subscribe((state) => {
        if (orderId in state.orders) {
          const order = state.orders[orderId];
          if (order.status === successStatus) {
            cleanup();
            resolve(order);
            return;
          }

          if (order.status === OrderStatus.REJECTED) {
            cleanup();
            reject(order.code as ErrorCode);
            return;
          }
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 30_000); // 30 seconds timeout

      useOrdersStore.getState().pollOrders([[orderId, successStatus, time]]);
    });
  });

  if (showToast) {
    createOrderToast(confirmPromise, order, (error) => {
      if (error == ErrorCode.ORDER_EXPIRED && useSystemTime) {
        createOrder({
          order,
          showToast,
          waitForConfirm,
          useSystemTime: false,
        });

        return `Order rejected: ${ErrorMsgs[error as ErrorCode]} Retrying with accurate time obtained from reliable third-party sources.`;
      }
      return `Order rejected: ${ErrorMsgs[error as ErrorCode]}`;
    });
  }

  return waitForConfirm ? confirmPromise : restPromise;
};

type UseCreateOrderOptions = {
  mutationConfig?: MutationConfig<typeof createOrder>;
};

export const useCreateOrder = ({
  mutationConfig,
}: UseCreateOrderOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  return useMutation({
    onSuccess: (data, variables, context) => {},
    ...restConfig,
    mutationFn: createOrder,
  });
};
