import {
  cancelOrder,
  CancelOrderResponse,
} from '@/features/trade/order/api/cancel-order';
import {
  createOrder,
  createOrderInputSchema,
  CreateOrderResponse,
} from '@/features/trade/order/api/create-order';
import { z } from 'zod';
import { MarketSpec } from '@/store/use-markets-store';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types/enums';
import { useOrdersStore } from '@/store/use-orders-store';
import { toast } from 'sonner';
import { parseInputToDecimalStr } from '@/utils/value-format';
import { useAccountStore } from '@/store/use-account-store';

export const CreateOrderInputSchemaWithId = createOrderInputSchema.extend({
  id: z.string(),
});

export type CreateOrderInputWithId = z.infer<
  typeof CreateOrderInputSchemaWithId
>;

// for one symbol
export const updateOrders = (
  currentOrders: CreateOrderInputWithId[],
  updatedOrders: CreateOrderInputWithId[],
  marketSpec: MarketSpec,
) => {
  const queue: (() => Promise<CancelOrderResponse | CreateOrderResponse>)[] =
    []; // list of functions to call

  const toCreate = updatedOrders.filter(
    (uo) => !currentOrders.find((co) => co.id === uo.id),
  );
  toCreate.forEach((o) => {
    const validatedCreateOrderParams = {
      ...o,
      limitPrice: parseInputToDecimalStr(
        o.limitPrice,
        marketSpec.priceDecimals,
      ),
      size: parseInputToDecimalStr(o.size, marketSpec.sizeDecimals),
    };
    queue.push(() =>
      createOrder({ order: validatedCreateOrderParams, showToast: false }),
    );
  });

  const toEdit = updatedOrders.filter((uo) =>
    currentOrders.find(
      (co) =>
        co.id === uo.id &&
        (co.limitPrice !== uo.limitPrice || co.size !== uo.size),
    ),
  );
  toEdit.forEach((o) => {
    const { id, ...createOrderParams } = o;
    const validatedCreateOrderParams = {
      ...createOrderParams,
      limitPrice: parseInputToDecimalStr(
        createOrderParams.limitPrice,
        marketSpec.priceDecimals,
      ),
      size: parseInputToDecimalStr(
        createOrderParams.size,
        marketSpec.sizeDecimals,
      ),
    };
    queue.push(() => cancelOrder({ id, showToast: false }));
    queue.push(() =>
      createOrder({ order: validatedCreateOrderParams, showToast: false }),
    );
  });

  const toDelete = currentOrders.filter(
    (co) => !updatedOrders.find((uo) => uo.id === co.id),
  );
  toDelete.forEach((o) => {
    queue.push(() => cancelOrder({ id: o.id, showToast: false }));
  });

  // Chain all requests to ensure nonce monotonicity and return array of promise results
  const chainedPromises = queue.reduce(
    (
      acc: Promise<(CreateOrderResponse | CancelOrderResponse)[]>,
      curr: () => Promise<CreateOrderResponse | CancelOrderResponse>,
    ) => acc.then((results) => curr().then((result) => [...results, result])),
    Promise.resolve([]),
  );

  const toastPromise = chainedPromises.then((results) => {
    return new Promise<void>((resolve, reject) => {
      let cancelledIds = new Set(
        results
          .filter((res) => res.status === OrderStatus.CANCELLED)
          .map((res) => (res as CancelOrderResponse).id),
      );
      let newIds = new Set(
        results
          .filter((res) => res.status === OrderStatus.NEW)
          .map((res) => (res as CreateOrderResponse).id),
      );

      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        unsubscribe();
      };

      unsubscribe = useOrdersStore.subscribe((state, prevState) => {
        const confirmedCancels =
          state.cancelledOrderIds.intersection(cancelledIds);
        const confirmedCreates = state.newOrderIds.intersection(newIds);

        cancelledIds = cancelledIds.difference(confirmedCancels);
        newIds = newIds.difference(confirmedCreates);

        if (cancelledIds.size === 0 && newIds.size === 0) {
          cleanup();
          resolve();
        }

        if (
          Array.from(cancelledIds.union(newIds)).some((id) =>
            state.rejectedOrderIds.has(id),
          )
        ) {
          cleanup();
          reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT); // TODO: better error handling
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        if (cancelledIds.size === 0 && newIds.size === 0) {
          cleanup();
          resolve();
        }
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 30_000); // 30 seconds timeout

      const cancelOrdersToPoll: [string, OrderStatus, number][] = Array.from(
        cancelledIds,
      ).map((id) => [
        id,
        OrderStatus.CANCELLED,
        useAccountStore.getState().getAccurateTime(),
      ]);
      const newOrdersToPoll: [string, OrderStatus, number][] = Array.from(
        newIds,
      ).map((id) => [
        id,
        OrderStatus.NEW,
        useAccountStore.getState().getAccurateTime(),
      ]);
      useOrdersStore
        .getState()
        .pollOrders([...cancelOrdersToPoll, ...newOrdersToPoll]);
    });
  });

  toast.promise(toastPromise, {
    loading: 'Updating TP/SL...',
    success: (data) => {
      return `TP/SL updated`;
    },
    error: (error) => {
      return `TP/SL update error: ${ErrorMsgs[error as ErrorCode]}`;
    },
  });

  return chainedPromises;
};
