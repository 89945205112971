import { SOLANA_CHAIN_ID, solNetwork } from '@/lib/wagmi/config';
import { useAccountStore } from '@/store/use-account-store';
import { NetworkType } from '@/types';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useMemo } from 'react';
import { useAccount } from 'wagmi';

export const useUnifiedAccount = () => {
  const {
    isConnected: _isConnected,
    chainId,
    connector,
    chain,
    address: activeAddress,
  } = useAccount();
  const { connected, wallet } = useWallet();
  const { connection } = useConnection();

  const { networkType, setNetworkType, address } = useAccountStore((state) => ({
    networkType: state.networkType,
    address: state.address,
    setNetworkType: state.setNetworkType,
  }));

  const isWrongAddress =
    address && networkType === NetworkType.EVM
      ? activeAddress != address
      : false;

  const getNetworkName = () => {
    const endpoint = connection.rpcEndpoint;

    if (endpoint.includes('devnet')) {
      return 'Solana Devnet';
    } else if (endpoint.includes('testnet')) {
      return 'Solana Testnet';
    } else if (endpoint.includes('mainnet')) {
      return 'Solana Mainnet Beta';
    } else {
      return 'Unknown';
    }
  };

  const isConnected = _isConnected || connected;

  useEffect(() => {
    if (!_isConnected && connected) setNetworkType(NetworkType.SOLANA);
  }, [_isConnected, connected]);

  const info = useMemo(() => {
    if (isConnected) {
      const networkName = getNetworkName();
      return connected && networkType === NetworkType.SOLANA
        ? {
            networkType,
            address,
            chain: { name: networkName, id: SOLANA_CHAIN_ID },
            chainId: solNetwork,
            connector: wallet?.adapter,
          }
        : {
            networkType: NetworkType.EVM,
            address,
            chainId,
            chain,
            connector,
          };
    }
    return {
      networkType: NetworkType.EVM,
      address: undefined,
      chainId: '',
      connector: null,
    };
  }, [
    networkType,
    isConnected,
    connected,
    wallet,
    connector,
    address,
    chainId,
    connection,
  ]);

  return { isConnected, ...info, isWrongAddress };
};
