import { Button, Table } from '@/components/ui/';
import { createColumnHelper } from '@tanstack/react-table';
import { PositionRow } from './position-row';
import { useMemo, useState } from 'react';
import { useMarketStores } from '@/store/use-markets-store';
import {
  bigIntToDecimalStr,
  formatDate,
  formatNumber,
  formatOrderType,
} from '@/utils/value-format';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '../api/get-account';
import {
  symbolToBaseQuote,
  abbrFromSym,
  imgFromAbbr,
} from '@/utils/token-symbol';
import { cn } from '@/utils/cn';
import { useOrders } from '../api/get-orders';
import { getOrderValueFromPrice } from '@/features/trade/order/utils/math';
import { useCancelOrder } from '@/features/trade/order/api/cancel-order';
import { useOrdersStore } from '@/store/use-orders-store';
import { OrderStatus, OrderType } from '@/types/enums';
import { useShallow } from 'zustand/react/shallow';
import { ArrowLeftRight } from 'lucide-react';
import useGeofenceCheck from '@/utils/geofence-check';
import { Link } from 'react-router-dom';
import { TPSLEdit } from './tpsl-edit';

// all relevant fields are in decimal strings
type OpenOrder = {
  id: string;
  orderType: OrderType;
  symbol: string;
  isBuy: boolean;
  size: string;
  value: string;
  limitPrice: string;
  tpPrice: string;
  slPrice: string;
  markPrice: string;
  reduceOnly: boolean;
  postTime: number;
};

const columnHelper = createColumnHelper<OpenOrder>();

export const OpenOrdersTableContainer = () => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});
  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount({});
  const { isPending: isNewOrdersPending, isError: isNewOrdersError } =
    useOrders({ status: OrderStatus.NEW });

  if (isExchangeInfoPending) {
    return <></>;
  }

  if (isExchangeInfoError) {
    return <div>Error</div>;
  }

  return <OpenOrdersTable />;
};

const OpenOrdersTable = () => {
  const { data: isSanctioned } = useGeofenceCheck();
  const [valueActive, setValueActive] = useState(false);
  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]).filter(
        (order) => order.orderType !== OrderType.MARKET,
      ),
    ),
  );
  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));
  // TODO: newOrder markets

  const { mutateAsync: cancelOrder } = useCancelOrder();

  const openOrders: OpenOrder[] = useMemo(() => {
    return newOrders
      .map((order) => {
        return {
          ...order,
          size: `${bigIntToDecimalStr(
            order.size,
            markets[order.symbol].marketSpec.sizeDecimals,
          )} ${abbrFromSym(order.symbol)}`,
          limitPrice: bigIntToDecimalStr(
            order.limitPrice,
            markets[order.symbol].marketSpec.priceDecimals,
          ),
          value:
            formatNumber(
              getOrderValueFromPrice(
                order.size,
                order.limitPrice,
                markets[order.symbol].marketSpec,
              )?.decimal,
              { digits: 2, round: 'ceil' },
            ) ?? '0',
          markPrice: bigIntToDecimalStr(
            markets[order.symbol].marketData.markPrice,
            markets[order.symbol].marketSpec.priceDecimals,
          ),
          tpPrice: order.tpPrice ? bigIntToDecimalStr(order.tpPrice, markets[order.symbol].marketSpec.priceDecimals) : '',
          slPrice: order.slPrice ? bigIntToDecimalStr(order.slPrice, markets[order.symbol].marketSpec.priceDecimals) : '',
        };
      })
      .sort((a, b) => b.postTime - a.postTime);
  }, [newOrders, markets]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('postTime', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {formatDate(new Date(info.getValue()), {
              showTime: true,
              showSeconds: true,
            })}
          </div>
        ),
        header: () => 'TIME',
        meta: { width: 'w-[14.5%]' },
      }),
      columnHelper.accessor('orderType', {
        cell: (info) => <p>{formatOrderType(info.getValue())}</p>,
        header: () => 'TYPE',
        meta: { width: 'w-[5.5%]' },
      }),

      columnHelper.accessor('symbol', {
        cell: (info) => (
          <Link
            to={`/trade/${info.getValue()}`}
            className="flex items-center gap-4"
          >
            <img
              src={imgFromAbbr(abbrFromSym(info.getValue()))}
              title={info.getValue()}
              className="h-5 w-5"
            />
            <span className="font-sans">
              {symbolToBaseQuote(info.getValue())}
            </span>
          </Link>
        ),
        header: () => 'MARKET',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('isBuy', {
        cell: (info) => (
          <p className={cn(info.getValue() ? 'text-green' : 'text-red')}>
            {info.getValue() ? 'Long' : 'Short'}
          </p>
        ),
        header: () => 'SIDE',
        meta: { width: 'w-[6%]' },
      }),
      columnHelper.accessor(valueActive ? 'value' : 'size', {
        cell: (info) => info.getValue(),
        header: () => (
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setValueActive((prev) => !prev);
              }}
            >
              <ArrowLeftRight size={14} />
            </button>
            <span className="uppercase">{valueActive ? 'Value' : 'Size'}</span>
          </div>
        ),
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('limitPrice', {
        id: 'limitPrice',
        cell: (info) => formatNumber(info.getValue()),
        header: () => <span>LIMIT PRICE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('markPrice', {
        cell: (info) => formatNumber(info.getValue()),
        header: () => <span>MARK PRICE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.display({
        id: 'tp-sl',
        cell: ({ row }) => (
          <div className="flex items-center gap-4">
            <p className="text-ellipsis whitespace-nowrap">
              {row.original.tpPrice ? formatNumber(row.original.tpPrice) : '-'} /{' '}
              {row.original.slPrice ? formatNumber(row.original.slPrice) : '-'}
            </p>
          </div>
        ),
        header: () => 'TP/SL',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('reduceOnly', {
        cell: (info) => (info.getValue() ? 'Yes' : 'No'),
        header: () => <span>REDUCE ONLY</span>,
        meta: { width: 'w-[7.5%]' },
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <div className="flex justify-end">
            <Button
              onClick={() =>
                cancelOrder({ id: row.original.id, showToast: true })
              }
              disabled={isSanctioned}
              className="h-10 rounded-none bg-black-alt px-6 py-2 text-sm font-normal uppercase tracking-wider text-white transition-colors duration-200 hover:bg-black-alt focus:outline-none"
            >
              CANCEL
            </Button>
          </div>
        ),
        header: () => '',
        meta: { width: 'w-[6%]' },
      }),
    ],
    [valueActive, isSanctioned],
  );

  return (
    // @ts-ignore
    <Table data={openOrders} columns={columns} RowComponent={PositionRow} />
  );
};
