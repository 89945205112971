import { create } from 'zustand';

export interface ModalStore {
  isGuideModalOpen: boolean;
  handleGuideModalOpen: (isOpen: boolean) => void;
  isSwitchModalOpen: boolean;
  handleSwitchModalOpen: (isOpen: boolean) => void;
}

export const useModalStore = create<ModalStore>((set, get) => ({
  isGuideModalOpen: false,
  handleGuideModalOpen: (isOpen) => {
    set({
      isGuideModalOpen: isOpen,
    });
  },
  isSwitchModalOpen: false,
  handleSwitchModalOpen: (isOpen) => {
    set({
      isSwitchModalOpen: isOpen,
    });
  },
}));
