import {
  MarketsDropdown,
  MarketInfo,
  PositionsTableContainer,
} from '@/features/trade/components';
import TradingView from '@/features/trade/components/tv-chart';
import { OrderFormContainer } from '@/features/trade/order/components';
import { OrdersHistoryTableContainer } from '@/features/account/components';
import { useParams, useSearchParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui';
import { useTickerLatest } from '@/features/markets/api/get-ticker-latest';
import { OpenOrdersTableContainer } from '@/features/account/components/open-orders-table';
import { TransfersTableContainer } from '@/features/account/components/transfers-table';
import { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { OrderHistoryFilterType, OrderType, TableTabType } from '@/types/enums';
import { useMarketStore } from '@/store/use-markets-store';
import { bigIntToDecimalStr } from '@/utils/value-format';
import { useStore } from 'zustand';
import UseReferralCodeDialog from '@/features/referrals/components/use-referral-code-dialog';
import LeverageModal from '@/components/header/leverage-modal';
import Activity from '@/features/markets/components/activity';
import { useAccountStore } from '@/store/use-account-store';
import { useOrdersStore } from '@/store/use-orders-store';

export default function TradePage() {
  const [searchParams] = useSearchParams();
  const defaultTab = searchParams.get('view') as TableTabType;
  const isDefaultTabCorrect =
    !!defaultTab && Object.values(TableTabType).includes(defaultTab);
  const [tableTab, setTableTab] = useState<TableTabType>(
    isDefaultTabCorrect ? defaultTab : TableTabType.POSITIONS,
  );
  const [orderHistoryFilter, setOrderHistoryFilter] =
    useState<OrderHistoryFilterType>(OrderHistoryFilterType.ALL);
  const { symbol } = useParams() as { symbol: string };
  const { marketData, marketSpec } = useStore(
    useMarketStore(symbol),
    (state) => state,
  );
  const positions = useAccountStore((state) => state.account.positions);

  const openOrders = useOrdersStore((state) =>
    Array.from(state.newOrderIds, (id) => state.orders[id]).filter(
      (order) => order.orderType !== OrderType.MARKET,
    ),
  );

  // const { isSuccess: isAccountSuccess } = useFetchAccount({});
  useTickerLatest(); // TEMP for MarketsDropdown

  useEffect(() => {
    document.title = `${bigIntToDecimalStr(
      marketData.markPrice,
      marketSpec.priceDecimals,
    )} | ${symbol} | Vest`;
  }, [marketData.markPrice, marketSpec.priceDecimals]);

  useEffect(() => {
    if (!isDefaultTabCorrect) return;
    setTableTab(defaultTab);
  }, [defaultTab]);

  return (
    <div className="flex h-full flex-col">
      <UseReferralCodeDialog />
      <div className="grid h-[56px] shrink-0 grid-cols-5 border-b border-border bg-background">
        <div className="col-span-4 grid grid-cols-[320px_1fr]">
          <MarketsDropdown />
          <MarketInfo />
        </div>
        <LeverageModal />
      </div>
      <div className="flex flex-1">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1">
            <TradingView symbol={symbol || 'BTC-PERP'} />
            <Activity />
          </div>
          <Tabs
            value={tableTab}
            onValueChange={(value) => setTableTab(value as TableTabType)}
            className="flex max-h-[246px] w-full flex-1 flex-col"
          >
            <div className="flex">
              <TabsList>
                <TabsTrigger className="gap-2" value="positions">
                  POSITIONS
                  <span className="text-vestgrey-100">
                    ({Object.keys(positions).length})
                  </span>
                </TabsTrigger>
                <TabsTrigger className="gap-2" value="open-orders">
                  OPEN ORDERS
                  <span className="text-vestgrey-100">
                    ({openOrders.length})
                  </span>
                </TabsTrigger>
                <TabsTrigger value="order-history">ORDER HISTORY</TabsTrigger>
                <TabsTrigger value="transfers">TRANSFERS</TabsTrigger>
              </TabsList>
              <div className="flex w-full items-center justify-end gap-4 border-y border-border bg-background font-mono">
                {tableTab === TableTabType.ORDER_HISTORY && (
                  <Select
                    onValueChange={(value) =>
                      setOrderHistoryFilter(value as OrderHistoryFilterType)
                    }
                  >
                    <SelectTrigger
                      id="filter"
                      className="h-full max-w-32 rounded-none border-y-0 border-r-0"
                    >
                      <SelectValue placeholder="Filter" />
                    </SelectTrigger>
                    <SelectContent className="font-mono">
                      <SelectItem value={OrderHistoryFilterType.ALL}>
                        All
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.FILLED}>
                        Filled
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.REJECTED}>
                        Rejected
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.CANCELLED}>
                        Cancelled
                      </SelectItem>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>
            <TabsContent className="relative min-h-48 flex-1" value="positions">
              <div className="absolute inset-0 h-full w-full">
                <PositionsTableContainer />
              </div>
            </TabsContent>
            <TabsContent
              className="relative min-h-48 flex-1"
              value="open-orders"
            >
              <div className="absolute inset-0 h-full w-full">
                <OpenOrdersTableContainer />
              </div>
            </TabsContent>
            <TabsContent
              className="relative min-h-48 flex-1"
              value="order-history"
            >
              <div className="absolute inset-0 h-full w-full">
                <OrdersHistoryTableContainer filter={orderHistoryFilter} />
              </div>
            </TabsContent>
            <TabsContent className="relative min-h-48 flex-1" value="transfers">
              <div className="absolute inset-0 h-full w-full">
                <TransfersTableContainer />
              </div>
            </TabsContent>
          </Tabs>
        </div>
        {/* <div className="col-span-4"></div> */}
        <div className="flex w-full max-w-sm flex-col border-l border-border bg-background lg:max-w-md">
          <OrderFormContainer />
          {/* <ConnectionStatus /> */}
        </div>
      </div>
    </div>
  );
}
