import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      position="top-right"
      toastOptions={{
        classNames: {
          toast:
            'group toast !bg-background group-[.toaster]:border group-[.toaster]:shadow-lg w-full [&>button]:!bg-background [&>button]:!border-border !h-auto [&>button]:!left-auto [&>button]:!-right-5',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
