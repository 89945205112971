import { useUnifiedAccount } from '@/hooks';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { queryOptions, useQuery } from '@tanstack/react-query';

export type PointsHistoryRecord = {
  point: number;
  ts: string;
};

export type Points = {
  point: number | null;
  rank: number | null;
  league: number | null;
  history: PointsHistoryRecord[];
};

async function getPoints(): Promise<Points> {
  return privateApi.get('/points');
}

export const getPointsQueryOptions = (address: string | undefined) => {
  return queryOptions({
    queryKey: ['points', address],
    queryFn: () => getPoints(),
  });
};

export default function usePoints() {
  const { address } = useUnifiedAccount();
  const hasAuth = useAccountStore((state) => state.hasAuth);
  return useQuery({
    enabled: hasAuth && !!address,
    ...getPointsQueryOptions(address),
    staleTime: 0,
  });
}
