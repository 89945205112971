import { Button } from '@/components/ui';
import { useUnifiedAccount, useUnifiedConnections } from '@/hooks';
import { useModalStore } from '@/store/use-modal-store';
import { Adapter } from '@solana/wallet-adapter-base';

import { FC } from 'react';
import { Connector } from 'wagmi';

interface IProps {
  icon: string;
  label: string;
  connector: Connector | Adapter;
  isMore?: boolean;
  onClick: () => void;
}

export const ButtonWallet: FC<IProps> = ({
  icon,
  label,
  isMore,
  onClick,
  connector: _connector,
}) => {
  const connections = useUnifiedConnections();

  const { handleGuideModalOpen } = useModalStore();

  if (isMore) {
    return (
      <Button onClick={onClick} variant="outline">
        <div className="btn-wallet--more">{label}</div>
      </Button>
    );
  }

  const isConnectedWallet = connections.includes(_connector);

  return (
    <Button
      onClick={() =>
        isConnectedWallet ? handleGuideModalOpen(true) : onClick()
      }
      variant="outline"
      className={`relative h-auto px-3 py-6 hover:bg-vestgrey-800 ${isConnectedWallet ? 'border-primary bg-vestgrey-800' : ''}`}
    >
      <div className="btn-wallet flex w-full flex-col items-center justify-start gap-3 font-mono text-lg font-normal">
        <img src={icon} className="h-12 w-12 rounded-md" alt="icon" />
        {label.toUpperCase()}
      </div>
      {isConnectedWallet && (
        <div className="absolute bottom-1.5 text-primary">connected</div>
      )}
    </Button>
  );
};
