import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { MutationConfig } from '@/lib/api-clients/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { encodeAbiParameters, keccak256 } from 'viem';
import { signMessage } from 'viem/accounts';
import { privateApi } from '@/lib/api-clients/rest-client';
import { toast } from 'sonner';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types/enums';
import { useOrdersStore } from '@/store/use-orders-store';

export type CancelOrderResponse = {
  id: string;
  status: OrderStatus.CANCELLED;
};

export const cancelOrder = async ({
  id,
  showToast,
}: {
  id: string;
  showToast: boolean;
}): Promise<CancelOrderResponse> => {
  const { getAuthParams, nextNonce } = useAccountStore.getState();
  const authParams = getAuthParams();

  if (!authParams?.signingKey) {
    console.error('No signing key found');
    throw new Error('No signing key found');
  }

  const time = useAccountStore.getState().getAccurateTime();
  const nonce = nextNonce();

  const encodedOrder = encodeAbiParameters(
    [
      { name: 'time', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'id', type: 'string' },
    ],
    [BigInt(time), BigInt(nonce), id],
  );

  const signature = await signMessage({
    message: { raw: keccak256(encodedOrder) },
    privateKey: authParams.signingKey,
  });

  const body = {
    order: { id, nonce, time },
    signature,
    recvWindow: 10_000,
  };

  const restPromise = privateApi.post('/orders/cancel', body);

  const confirmPromise = restPromise.then((res) => {
    const { id: orderId } = res;
    if (orderId != id) {
      throw ErrorCode.INTERNAL_ID_MISMATCH;
    }

    return new Promise<void>((resolve, reject) => {
      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        unsubscribe();
      };

      unsubscribe = useOrdersStore.subscribe((state, prevState) => {
        if (state.cancelledOrderIds.has(orderId)) {
          cleanup();
          resolve();
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 30_000); // 30 seconds timeout

      useOrdersStore
        .getState()
        .pollOrders([[orderId, OrderStatus.CANCELLED, time]]);
    });
  });

  if (showToast) {
    toast.promise(confirmPromise, {
      loading: 'Order cancelling',
      success: (data) => {
        return `Order cancelled`;
      },
      error: (error) => {
        return `Could not cancel order: ${ErrorMsgs[error as ErrorCode]}`;
      },
    });
  }

  return restPromise;
};

type UseCancelOrderOptions = {
  mutationConfig?: MutationConfig<typeof cancelOrder>;
};

export const useCancelOrder = ({
  mutationConfig,
}: UseCancelOrderOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  return useMutation({
    onSuccess: (data, variables, context) => {},
    ...restConfig,
    mutationFn: cancelOrder,
  });
};
