import { NetworkType } from '@/types';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface Position {
  isLong: boolean;
  size: bigint;
  cost: bigint;
  entryFunding: bigint;
  initMarginRatio: bigint;
}

export interface Positions {
  [symbol: string]: Position;
}

export type Leverage = {
  value: number;
  symbol: string;
};

export interface Account {
  collateral: bigint;
  positions: Positions;
  leverages: Leverage[];
  connections: {
    twitter: string | null;
    discord: string | null;
  };
}

export interface AuthParams {
  address: string; // NOTE: currently checksummed, match wagmi; this is unused except during store rehydration
  signingKey: `0x${string}`;
  apiKey: string;
  accountGroup: number;
  listenKey: string;
  listenKeyExpiry: number;
  shouldPersist: boolean;
  connector: string | undefined;
}

export interface AccountStore {
  _hasHydrated: boolean; // internal zustand hydration
  setHasHydrated: (hasHydrated: boolean) => void;

  clearAccountStore: () => void;
  clearAccountStoreForAddress: (address: string) => void;

  authHydrated: boolean; // api-provider auth hydration based on connected wallet
  setAuthHydrated: (authHydrated: boolean) => void;
  hasAuth: boolean;
  setHasAuth: (hasAuth: boolean) => void;
  address: string | undefined;
  setAddress: (address: string | undefined) => void;

  authParamsByAddress: { [address: string]: AuthParams }; // store multiple authParams by address
  setAuthParams: (address: string, authParams: AuthParams) => void;
  removeAuthParams: (address: string) => void;
  getAuthParamsByAddress: (
    address: string | undefined,
  ) => AuthParams | undefined;
  getAuthParams: () => AuthParams | undefined;
  timeOffset: number;
  setTimeOffset: (offset: number) => void;
  networkType: NetworkType;
  setNetworkType: (networkType: NetworkType) => void;

  nonce: number;
  nextNonce: () => number; // returns new nonce

  account: Account;
  setAccount: (account: Account) => void;

  accountLastUpdated: number;
  setAccountLastUpdated: (accountLastUpdated: number) => void;

  getAccurateTime: () => number;
}

export const useAccountStore = create<AccountStore>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      networkType: NetworkType.EVM,
      setNetworkType: (networkType: NetworkType) => {
        set({ networkType });
      },
      address: undefined,
      setAddress: (address: string | undefined) => {
        set({ address });
      },
      timeOffset: 0,
      setTimeOffset: (offset) => set({ timeOffset: offset }),

      clearAccountStore: () =>
        set({
          address: undefined,
          hasAuth: false,
          authParamsByAddress: {}, // reset authParams storage
          account: {
            collateral: BigInt(0),
            positions: {},
            leverages: [],
            connections: {
              twitter: null,
              discord: null,
            },
          },
        }),

      clearAccountStoreForAddress: (address: string) =>
        set((state) => {
          // Remove authParams for the given address, keeping others intact
          const { [address]: _, ...remainingAuthParams } =
            state.authParamsByAddress;
          return {
            authParamsByAddress: remainingAuthParams,
            account: {
              collateral: BigInt(0),
              positions: {},
              leverages: [],
              connections: {
                twitter: null,
                discord: null,
              },
            },
          };
        }),

      authHydrated: false,
      setAuthHydrated: (authHydrated) => set({ authHydrated }),
      hasAuth: false,
      setHasAuth: (hasAuth) => set({ hasAuth }),
      authParamsByAddress: {}, // Initialize as an empty object for multiple wallet support
      setAuthParams: (address, authParams) => {
        set((state) => {
          const updatedAuthParams = {
            ...state.authParamsByAddress,
            [address]: authParams,
          };
          return { authParamsByAddress: updatedAuthParams };
        });
      },

      removeAuthParams: (address) => {
        set((state) => {
          const { [address]: _, ...remainingAuthParams } =
            state.authParamsByAddress;
          return { authParamsByAddress: remainingAuthParams };
        });
      },

      getAuthParamsByAddress: (address: string | undefined) => {
        if (!address) {
          return undefined; // Return null if address is null or undefined
        }

        const state = get();
        return state.authParamsByAddress[address] || null; // Return null if authParams not found for the address
      },

      getAuthParams: () => {
        const state = get();
        return state.address
          ? state.authParamsByAddress[state.address] || null
          : undefined; // Return null if authParams not found for the address
      },

      nonce: Date.now(),
      nextNonce: () => {
        let nextNonce: number = 0;

        set((state) => {
          nextNonce = Math.max(Date.now(), state.nonce + 1);
          return { nonce: nextNonce };
        });

        return nextNonce + get().timeOffset;
      },
      getAccurateTime: () => Date.now() + get().timeOffset,

      account: {
        collateral: BigInt(0),
        positions: {},
        leverages: [],
        connections: {
          discord: null,
          twitter: null,
        },
      },
      setAccount: (account) => set({ account }),

      accountLastUpdated: 0,
      setAccountLastUpdated: (accountLastUpdated) =>
        set({ accountLastUpdated }),
    }),
    {
      name: 'auth',
      partialize: (state) => {
        if (
          Object.values(state.authParamsByAddress).some(
            (param) => param.shouldPersist,
          )
        ) {
          return {
            authParamsByAddress: state.authParamsByAddress,
            networkType: state.networkType,
            address: state.address,
          };
        }

        return {};
      },
      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            console.error('Error rehydrating account store', error);
            return;
          }
          if (!state) {
            console.error('State undefined after rehydration');
            return;
          }
          state.setHasHydrated(true);
        };
      },
    },
  ),
);
