import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/';
import { useEffect, useRef, useState } from 'react';
import cashmoneyvin from '@/assets/referral/cashmoneyvin.png';
import cashmoneyvinGlow from '@/assets/referral/cashmoneyvin-glow.png';
import trendUp from '@/assets/referral/trendup.png';
import trendUpGlow from '@/assets/referral/trendup-glow.png';
import business from '@/assets/referral/business.png';
import businessGlow from '@/assets/referral/business-glow.png';
import prison from '@/assets/referral/prison.png';
import prisonGlow from '@/assets/referral/prison-glow.png';
import pray from '@/assets/referral/pray.png';
import prayGlow from '@/assets/referral/pray-glow.png';
import stonkDown from '@/assets/referral/stonk-down.png';
import stonkDownGlow from '@/assets/referral/stonk-down-glow.png';
import { PositionColumn } from '@/features/account/components';
import { abbrFromSym, imgFromAbbr } from '@/utils/token-symbol';
import { cn } from '@/lib/utils';
import { formatNumber } from '@/utils/value-format';
import { Label } from '@/components/ui/label';
import useReferrals from '@/features/referrals/api/get-referrals';
import { Link } from 'react-router-dom';
import VestIcon from '@/assets/branding/vest-icon.svg';
import { Check, Copy, Edit } from 'lucide-react';
import { toPng } from 'html-to-image';
import { toast } from 'sonner';
import { privateApi } from '@/lib/api-clients/rest-client';
import { FilledOrder, NewOrder } from '@/store/use-orders-store';
import { OrderStatus } from '@/types';

const images = [
  {
    name: 'cashmoneyvin',
    png: cashmoneyvin,
    withGlow: cashmoneyvinGlow,
    className: '-bottom-4 right-0 h-full',
  },
  {
    name: 'trend-up',
    png: trendUp,
    withGlow: trendUpGlow,
    className: 'right-0 bottom-0 h-full',
  },
  {
    name: 'business',
    png: business,
    withGlow: businessGlow,
    className: 'h-full right-0 bottom-0',
  },

  {
    name: 'pray',
    png: pray,
    withGlow: prayGlow,
    className: 'bottom-0 right-0 h-full top-0',
  },
  {
    name: 'stonk-down',
    png: stonkDown,
    withGlow: stonkDownGlow,
    className: 'bottom-0 right-0 top-0 h-full',
  },
  {
    name: 'prison',
    png: prison,
    withGlow: prisonGlow,
    className: 'top-0 bottom-0 right-0 h-full',
  },
];

type SharedOrder = {
  symbol: string;
  roePct: string;
  pnl: string;
  isLong: boolean;
  leverage: string;
  entryPrice: string;
  markPrice: string;
  isFilled?: boolean;
};

export default function Share(order: SharedOrder) {
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [hideUSDCAmount, setHideUSDCAmount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: referrals,
    isLoading: areReferralsLoading,
    isSuccess,
  } = useReferrals();
  const [chosenImage, setChosenImage] = useState<string | null>(images[0].name);
  const [copied, setCopied] = useState(false);
  const copyTimer = useRef<number>();
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [frozenOrder, setFrozenOrder] = useState(order);
  // const [stats, setStats] = useState<Partial<PositionColumn>>(position);

  async function downloadImage() {
    if (!imageRef.current) return;
    await toPng(imageRef.current).then((scr: any) => {
      const link = document.createElement('a');
      link.href = scr;
      link.download = `vest-${frozenOrder.symbol}-position.png`; // The name of the file to be downloaded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  // const roePct = frozenOrder.status === OrderStatus.NEW ? (frozenOrder as NewOrder).

  async function shareOnTwitter(symbol = 'ETH-PERP', refCode?: string) {
    if (!imageRef.current) return;
    const text =
      Number(frozenOrder.pnl) > 0
        ? `I just made ${formatNumber(frozenOrder.roePct, {
            digits: 1,
            style: 'percent',
            showChange: true,
            round: 'ceil',
          })} while trading on @VestExchange${refCode ? '. Use my referral code for a 10% boost on all rewards!' : '!'}`
        : `Trade $${abbrFromSym(symbol)} perps with smooth execution on @VestExchange${refCode ? ' and earn a 10% boost on all rewards using my referral code!' : ''}`;
    if (referrals?.has_twitter_access) {
      setIsShareLoading(true);
      const promise = async function () {
        const base64 = await toPng(imageRef.current!);
        await privateApi.post('/account/twitter/send', {
          text,
          base64,
        });
      };

      toast.promise(promise, {
        loading: 'Sharing the post...',
        success: (data) => {
          setIsShareLoading(false);
          setIsOpen(false);
          return 'The post has been shared!';
        },
        error: (err) => {
          setIsShareLoading(false);
          console.error("Couldn't share the post: ", err);
          return 'An error occurred while sharing the post';
        },
      });
    } else {
      const searchParams = new URLSearchParams();
      if (refCode) {
        searchParams.set('symbol', symbol);
        searchParams.set('ref', refCode);
      }
      const url = `https://trade.vest.exchange/trade/${symbol}${refCode ? '?' + searchParams.toString() : ''}`;
      const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url,
      )}&text=${encodeURIComponent(text)}`;
      const mobileTweetUrl = `twitter://post?message=${encodeURIComponent(url)}`;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        window.location.href = mobileTweetUrl;
        setTimeout(() => {
          window.open(tweetUrl, '_blank', 'noopener,noreferrer');
        }, 500);
      } else {
        window.open(tweetUrl, '_blank', 'noopener,noreferrer');
      }
    }
  }

  useEffect(() => {
    if (!copied) return;
    copyTimer.current = window.setTimeout(() => setCopied(false), 2000);
    return () => {
      copyTimer.current && window.clearTimeout(copyTimer.current);
    };
  }, [copied]);

  useEffect(() => {
    if (!isOpen) return;
    setFrozenOrder(order);
  }, [isOpen]);

  const image = images.find((img) => img.name === chosenImage);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="ml-4 inline-block">
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66667 8.33463H5.33334C4.23975 8.33429 3.16687 8.63283 2.23064 9.198C1.29442 9.76317 0.530462 10.5735 0.0213371 11.5413C0.00693023 11.3617 -0.000186773 11.1815 3.72456e-06 11.0013C3.72456e-06 7.3193 2.98467 4.33464 6.66667 4.33464V0.667969L13.6667 6.33464L6.66667 12.0013V8.33463ZM5.33334 7.0013H8V9.20663L11.5473 6.33464L8 3.46264V5.66797H6.66667C5.9001 5.6671 5.14241 5.83191 4.44545 6.1511C3.7485 6.47029 3.12876 6.93633 2.62867 7.5173C3.48956 7.17607 4.40729 7.00099 5.33334 7.0013Z"
            fill="#F5F5F5"
          />
        </svg>
      </DialogTrigger>
      <DialogContent className="max-w-max">
        <DialogHeader className="pb-4">
          {/* <DialogTitle className="text-3xl font-normal uppercase text-primary [text-shadow:_0_0_30px_rgb(255,90,68)]">
            share your trade
          </DialogTitle> */}
        </DialogHeader>
        <div className="grid grid-cols-[max-content_1fr] gap-8">
          <div
            ref={imageRef}
            className="relative flex h-[480px] w-[640px] flex-col justify-between gap-2 overflow-hidden border border-border bg-background p-6"
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-start justify-between">
                <div className="flex items-center">
                  <img
                    src={VestIcon}
                    alt="Vest Icon"
                    className="mr-2 h-10 w-10"
                  />
                  <svg
                    viewBox="0 0 54 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16"
                  >
                    <path
                      d="M12.3652 3.66785H10.4172C10.3355 3.66785 10.2634 3.71835 10.2355 3.79458L6.34634 14.6816L2.44467 3.79458C2.41775 3.71835 2.34468 3.66785 2.26391 3.66785H0.32942C0.195775 3.66785 0.103473 3.79935 0.148662 3.92323L5.1618 17.6128H7.5328L12.5469 3.92323C12.5921 3.79935 12.4998 3.66785 12.3661 3.66785H12.3652Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M24.7904 4.24819C23.7952 3.60307 22.5905 3.28003 21.1752 3.28003C19.7599 3.28003 18.6235 3.58401 17.612 4.19006C16.5996 4.79707 15.8121 5.65851 15.2468 6.77247C14.6824 7.88738 14.4007 9.20716 14.4007 10.7299C14.4007 12.2527 14.6872 13.4572 15.2602 14.5464C15.8333 15.6346 16.6342 16.4827 17.6639 17.0897C18.6927 17.6967 19.8984 17.9997 21.2791 17.9997C22.6597 17.9997 23.8741 17.6624 24.9731 16.9868C26.0711 16.3102 27.1912 15.0581 27.6287 13.4677L25.1605 13.4696C24.774 14.21 24.2539 14.7827 23.5981 15.1848C22.9116 15.6069 22.104 15.8175 21.1752 15.8175C19.7936 15.8175 18.7302 15.3659 17.9831 14.4625C17.337 13.6802 16.9707 12.6215 16.8832 11.2855H27.6758C27.745 9.63312 27.5306 8.20851 27.0306 7.01165C26.5316 5.81574 25.7845 4.89427 24.7904 4.24819ZM16.9332 9.43968C17.0726 8.30857 17.4226 7.39948 17.9831 6.71434C18.7302 5.8024 19.8195 5.34595 21.2531 5.34595C22.5905 5.34595 23.5981 5.77 24.276 6.61809C24.8115 7.28895 25.1432 8.22852 25.2711 9.43968H16.9332Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M34.134 5.81097C34.7023 5.45839 35.4436 5.29926 36.355 5.33356C37.3194 5.35929 38.1078 5.58704 38.7193 6.01776C39.3318 6.44847 39.6942 7.03738 39.8077 7.78637H42.2306C42.1595 6.7515 41.7768 5.82622 41.2605 5.21064C40.7432 4.59505 40.0635 4.11955 39.2212 3.78412C38.3789 3.44774 37.4232 3.28003 36.355 3.28003C35.2868 3.28003 34.334 3.44774 33.5216 3.78412C32.7091 4.11955 32.0774 4.59505 31.6265 5.21064C31.1746 5.82622 30.9487 6.54281 30.9487 7.36041C30.9487 8.02364 31.1006 8.58682 31.4044 9.05184C31.7082 9.51686 32.2168 9.92566 32.9293 10.2782C33.6408 10.6318 34.6177 10.972 35.8599 11.2988C37.0069 11.5999 37.8646 11.8582 38.4328 12.0735C39.002 12.2889 39.3779 12.5185 39.5606 12.7644C39.7423 13.0093 39.8337 13.3304 39.8337 13.7259C39.8337 14.4063 39.5606 14.9437 39.0126 15.3401C38.4655 15.7356 37.7098 15.9338 36.7464 15.9338C35.7301 15.9338 34.8782 15.7127 34.1927 15.2687C33.5062 14.8256 33.0677 14.2252 32.8764 13.4677H30.4487C30.6448 15.0485 31.4063 16.1682 32.4793 16.8962C33.5523 17.6233 34.9263 17.9873 36.6031 17.9873C38.2799 17.9873 39.7337 17.6014 40.7326 16.8314C41.7316 16.0615 42.2306 15.0047 42.2306 13.6611C42.2306 12.9388 42.0787 12.3299 41.7749 11.8344C41.4711 11.3398 40.9605 10.9091 40.2442 10.5432C39.5279 10.1772 38.5482 9.8399 37.3059 9.52925C36.2031 9.25386 35.3628 9.00896 34.7849 8.7936C34.2081 8.57824 33.8129 8.35049 33.5994 8.10941C33.387 7.86832 33.2802 7.57958 33.2802 7.24416C33.2802 6.64192 33.5648 6.16451 34.134 5.81097Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M49.7532 5.56516H54.0001V3.6679H49.7532V0.190716C49.7532 0.0306261 49.5667 -0.057995 49.4408 0.0420611L47.5063 1.57626C47.4611 1.61247 47.4342 1.66678 47.4342 1.72491V3.6679H44.5805V5.56516H47.4342V13.845C47.4342 17.2755 49.9446 17.6129 51.6002 17.6129H54.0001V15.6346H51.5685C50.7176 15.6346 49.7532 15.2849 49.7532 13.704V5.56516Z"
                      fill="#F5F5F5"
                    />
                  </svg>
                </div>

                {referrals?.ref_code && image?.name !== 'prison' && (
                  <div>
                    <p className="text-right font-mono uppercase text-vestgrey-600">
                      referral code
                    </p>
                    <p className="mt-1 text-right text-3xl font-bold text-primary">
                      {referrals.ref_code}
                    </p>
                  </div>
                )}
              </div>
              {referrals?.ref_code && image?.name === 'prison' && (
                <div className="relative flex flex-col">
                  <p className="font-mono uppercase text-vestgrey-600">
                    referral code
                  </p>
                  <p className="absolute top-6 text-3xl font-bold text-primary">
                    {referrals.ref_code}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-3">
                <img
                  src={imgFromAbbr(abbrFromSym(frozenOrder.symbol))}
                  alt="symbol"
                  className="h-6 w-6"
                />
                <h4 className="font-sans text-xl font-semibold">
                  {abbrFromSym(frozenOrder.symbol)}
                </h4>
                <h5
                  className={cn(
                    frozenOrder.isLong ? 'text-green' : 'text-red',
                    'font-mono',
                  )}
                >
                  {frozenOrder.isLong ? 'Long' : 'Short'}
                </h5>
                <div
                  className={cn(
                    'flex items-center justify-center rounded px-2 py-0.5',
                    frozenOrder.isLong
                      ? 'bg-green/10 text-green'
                      : 'bg-red/10 text-red',
                  )}
                >
                  <p className="text-center font-mono">
                    {formatNumber(frozenOrder.leverage, {
                      maxDigits: 1,
                      style: 'leverage',
                    })}
                  </p>
                </div>
              </div>
              <p
                className={cn(
                  'block font-mono text-8xl tracking-tighter',
                  Number(frozenOrder.pnl) < 0 ? 'text-red' : 'text-green',
                )}
              >
                <span>
                  {hideUSDCAmount && (Number(frozenOrder.pnl) < 0 ? '-' : '+')}
                </span>
                <span>
                  {formatNumber(frozenOrder.roePct, {
                    digits: 1,
                    style: 'percent',
                    showChange: true,
                    round: 'ceil',
                  })}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-4">
              {/* <div className="flex items-center gap-3">
                <img
                  src={imgFromAbbr(abbrFromSym(position.symbol))}
                  alt="symbol"
                  className="h-6 w-6"
                />
                <h4 className="font-sans text-xl font-semibold">
                  {abbrFromSym(position.symbol)}
                </h4>
                <h5
                  className={cn(
                    position.isLong ? 'text-green' : 'text-red',
                    'font-mono',
                  )}
                >
                  {position.isLong ? 'Long' : 'Short'}
                </h5>
                <div
                  className={cn(
                    'flex items-center justify-center rounded px-2 py-0.5',
                    position.isLong
                      ? 'bg-green/10 text-green'
                      : 'bg-red/10 text-red',
                  )}
                >
                  <p className="text-center font-mono">
                    {formatNumber(position.leverage, {
                      maxDigits: 1,
                      style: 'leverage',
                    })}
                  </p>
                </div>
              </div>
              <p
                className={cn(
                  'block font-mono text-8xl tracking-tighter',
                  Number(position.unrealizedPnl) < 0
                    ? 'text-red'
                    : 'text-green',
                )}
              >
                <span>
                  {hideUSDCAmount &&
                    (Number(position.unrealizedPnl) < 0 ? '-' : '+')}
                </span>
                <span>
                  {formatNumber(stats.roePct, {
                    digits: 1,
                    style: 'percent',
                    showChange: true,
                    round: 'ceil',
                  })}
                </span>
              </p> */}
              <div className="flex h-7 items-center gap-4">
                <p>
                  <span className="font-mono text-vestgrey-600">Entry:</span>{' '}
                  <span className="font-mono">{frozenOrder.entryPrice}</span>
                </p>
                <p>
                  <span className="font-mono text-vestgrey-600">
                    {frozenOrder.isFilled ? 'Close' : 'Current'}:
                  </span>{' '}
                  <span className="font-mono">{frozenOrder.markPrice}</span>
                </p>
              </div>
            </div>
            {image && (
              <img
                className={cn('absolute object-contain', image.className)}
                src={image.withGlow}
                alt=""
              />
            )}
          </div>
          <div className="flex flex-col justify-between gap-4">
            <div className="flex min-w-96 flex-col gap-4">
              <div className="flex items-center justify-between gap-3">
                <h3 className="text-xl font-medium">Your Referral Code</h3>
                {isSuccess && !referrals.ref_code && (
                  <Link
                    to="/referrals"
                    className={cn(
                      'flex items-center gap-2 px-4 underline',
                      isSuccess && !referrals.ref_code
                        ? 'text-primary'
                        : 'text-foreground/75',
                    )}
                  >
                    Get code
                  </Link>
                )}
              </div>
              {!areReferralsLoading && isSuccess && referrals.ref_code && (
                <div className="flex items-center gap-3">
                  <span className="text-font text-5xl font-bold uppercase">
                    {referrals.ref_code}
                  </span>
                  {referrals.ref_code && (
                    <Button
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://trade.vest.exchange/trade/${frozenOrder.symbol}?ref=${referrals.ref_code}`,
                        );
                        setCopied(true);
                      }}
                      className="h-10 w-10 rounded-none bg-background font-mono uppercase text-foreground hover:bg-background hover:text-foreground"
                    >
                      {copied ? <Check size={16} /> : <Copy size={16} />}
                    </Button>
                  )}
                </div>
              )}
              {/* {!isLoading && (
                <Button
                  className="mb-2 h-10 rounded-none font-mono uppercase tracking-wider"
                  asChild
                >
                  <Link to="/referrals">
                    {isSuccess && referrals.ref_code ? 'Edit code' : 'Add code'}
                  </Link>
                </Button>
              )} */}
              {/* <div className="mt-2 flex items-center">
                <Checkbox
                  className="h-5 w-5"
                  id="hide-usdc-amount"
                  checked={hideUSDCAmount}
                  onCheckedChange={(checked) =>
                    setHideUSDCAmount(checked as boolean)
                  }
                />
                <Label
                  htmlFor="hide-usdc-amount"
                  className="pl-3 text-vestgrey-100"
                >
                  Hide USDC amount
                </Label>
              </div> */}
              <div className="mb-4 mt-2">
                <p className="font-mono text-foreground/80">Image</p>
                <div className="mt-2 grid grid-cols-4 gap-2">
                  <button
                    onClick={() => setChosenImage(null)}
                    className={cn(
                      'flex h-16 items-center justify-center border',
                      !chosenImage ? 'border-primary' : 'border-border',
                    )}
                  >
                    <div className="absolute h-px w-24 origin-center -rotate-[32deg] bg-border"></div>
                  </button>
                  {images.map((img) => (
                    <button
                      onClick={() => setChosenImage(img.name)}
                      className={cn(
                        'h-16 border',
                        chosenImage === img.name
                          ? 'border-primary'
                          : 'border-border',
                      )}
                      key={`w-${img.name}`}
                    >
                      <img
                        className="h-full w-full object-contain object-right-bottom"
                        src={img.png}
                        alt=""
                        key={img.name}
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="grid">
              <Button
                type="button"
                onClick={() =>
                  shareOnTwitter(frozenOrder.symbol, referrals?.ref_code)
                }
                className="h-14 rounded-none font-mono text-lg font-normal uppercase tracking-wider"
                size="lg"
              >
                {isShareLoading ? 'Sharing on X...' : 'share on X'}
              </Button>
              <button
                type="button"
                onClick={downloadImage}
                className="h-14 rounded-none bg-black font-mono text-lg font-normal uppercase tracking-wider text-white disabled:opacity-50"
              >
                Download image
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function shareOnTwitter(symbol = 'ETH-PERP', refCode?: string) {
  const searchParams = new URLSearchParams();
  if (refCode) {
    searchParams.set('symbol', symbol);
    searchParams.set('ref', refCode);
  }
  const url = `https://trade.vest.exchange/trade/${symbol}${refCode ? '?' + searchParams.toString() : ''}`;
  const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url,
  )}&text=${encodeURIComponent(`Trade $${abbrFromSym(symbol)} perps with smooth execution on @VestExchange${refCode ? `  using my referral code to earn rewards` : ''}.`)}`;
  const mobileTweetUrl = `twitter://post?message=${encodeURIComponent(url)}`;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    window.location.href = mobileTweetUrl;
    setTimeout(() => {
      window.open(tweetUrl, '_blank', 'noopener,noreferrer');
    }, 500);
  } else {
    window.open(tweetUrl, '_blank', 'noopener,noreferrer');
  }
}
