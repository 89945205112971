import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchAccount, getAccountQueryOptions } from './get-account';
import { Leverage, useAccountStore } from '@/store/use-account-store';
import { privateApi } from '@/lib/api-clients/rest-client';
import { toast } from 'sonner';
import { abbrFromSym } from '@/utils/token-symbol';
import { ErrorCode, ErrorMsgs } from '@/types';
import { MutationConfig } from '@/lib/api-clients/react-query';
import { useUnifiedAccount } from '@/hooks';

async function updateLeverage({ symbol, value }: Leverage) {
  const { getAccurateTime } = useAccountStore.getState();

  const restPromise: Promise<Leverage> = privateApi.post('/account/leverage', {
    time: getAccurateTime(),
    value,
    symbol,
  });

  const confirmPromise: Promise<Leverage> = restPromise.then((updatedLvg) => {
    return new Promise((resolve, reject) => {
      let pollIntervalId: NodeJS.Timeout;
      let timeoutId: NodeJS.Timeout;

      const cleanup = () => {
        clearInterval(pollIntervalId);
        clearTimeout(timeoutId);
      };

      pollIntervalId = setInterval(() => {
        fetchAccount()
          .then((acc) => {
            const currentLeverage = acc.leverages.find(
              (lvg) => lvg.symbol === updatedLvg.symbol,
            )?.value;
            if (currentLeverage === updatedLvg.value) {
              cleanup();
              resolve(updatedLvg);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }, 1_000);

      timeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 30_000); // 30 seconds timeout
    });
  });

  toast.promise(confirmPromise, {
    success: (data) =>
      `Leverage for ${abbrFromSym(data.symbol)} successfully modified to ${data.value}X!`,
    error: (err) => ErrorMsgs[err.code as ErrorCode],
    loading: 'Changing leverage...',
  });

  return confirmPromise;
}

export default function useUpdateLeverage(
  mutationConfig?: MutationConfig<typeof updateLeverage>,
) {
  const { address } = useUnifiedAccount();
  const { authParams } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    authParams: state.getAuthParamsByAddress(address),
  }));

  const { queryKey } = getAccountQueryOptions(authParams, address);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateLeverage,
    ...(mutationConfig || {}),
    onSuccess: (...args) => {
      mutationConfig?.onSuccess && mutationConfig.onSuccess(...args);
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
}
