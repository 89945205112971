'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { WagmiProvider } from 'wagmi';

import { WebSocketProvider } from '@/context/ws-provider';
import { queryConfig } from '@/lib/api-clients/react-query';
import { solNetwork, wagmiConfig } from '@/lib/wagmi/config';
import { ApiProvider } from '@/context/api-provider';
import FavoriteMarketsProvider from '@/app/favorite-markets-provider';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';

import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';

import { clusterApiUrl } from '@solana/web3.js';

import { useMemo } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import config from '@/config';

type AppProviderProps = {
  children: React.ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export const AppProvider = ({ children }: AppProviderProps) => {
  const { QUIKNODE_MAINNET_RPC_ENDPOINT, isDevelopment } = config;
  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(
    () =>
      solNetwork === WalletAdapterNetwork.Mainnet
        ? QUIKNODE_MAINNET_RPC_ENDPOINT
        : clusterApiUrl(solNetwork),
    [solNetwork],
  );

  const wallets = useMemo(
    () => [new UnsafeBurnerWalletAdapter()],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solNetwork],
  );
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center"></div>
      }
    >
      <WagmiProvider config={wagmiConfig}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <QueryClientProvider client={queryClient}>
              <ApiProvider>
                <WebSocketProvider>
                  {isDevelopment && <ReactQueryDevtools />}
                  <FavoriteMarketsProvider>{children}</FavoriteMarketsProvider>
                </WebSocketProvider>
              </ApiProvider>
            </QueryClientProvider>
          </WalletProvider>
        </ConnectionProvider>
      </WagmiProvider>
    </React.Suspense>
  );
};
