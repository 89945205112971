import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { OrdersHistoryTableContainer } from '@/features/account/components';
import { OpenOrdersTableContainer } from '@/features/account/components/open-orders-table';
import { TransfersTableContainer } from '@/features/account/components/transfers-table';
import PortfolioChart from '@/features/portfolio/components/chart';
import PortfolioStats from '@/features/portfolio/components/stats';
import { PositionsTableContainer } from '@/features/trade/components';
import { OrderHistoryFilterType, TableTabType } from '@/types/enums';
import { useState } from 'react';

export default function PortfolioPage() {
  const [tableTab, setTableTab] = useState<TableTabType>(
    TableTabType.POSITIONS,
  );
  const [orderHistoryFilter, setOrderHistoryFilter] =
    useState<OrderHistoryFilterType>(OrderHistoryFilterType.ALL);

  return (
    <div className="flex h-full flex-col">
      <div className="grid grid-cols-4">
        <PortfolioStats />
        <PortfolioChart />
      </div>
      <Tabs
        value={tableTab}
        onValueChange={(value) => setTableTab(value as TableTabType)}
        defaultValue="positions"
        className="flex w-full flex-1 flex-col"
      >
        {/* to make right side of tabs foreground div */}
        <div className="flex">
          <TabsList>
            <TabsTrigger className="h-14" value="positions">
              POSITIONS
            </TabsTrigger>
            <TabsTrigger className="h-14" value="open-orders">
              OPEN ORDERS
            </TabsTrigger>
            <TabsTrigger className="h-14" value="order-history">
              ORDER HISTORY
            </TabsTrigger>
            <TabsTrigger className="h-14" value="transfers">
              TRANSFERS
            </TabsTrigger>
          </TabsList>
          {/* to make right side of tabs foreground div */}
          <div className="flex w-full items-center justify-end gap-4 border-y border-border bg-background">
            {tableTab === TableTabType.ORDER_HISTORY && (
              <Select
                onValueChange={(value) =>
                  setOrderHistoryFilter(value as OrderHistoryFilterType)
                }
              >
                <SelectTrigger
                  id="filter"
                  className="h-full max-w-32 rounded-none border-y-0 border-r-0"
                >
                  <SelectValue placeholder="Filter" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={OrderHistoryFilterType.ALL}>
                    All
                  </SelectItem>
                  <SelectItem value={OrderHistoryFilterType.FILLED}>
                    Filled
                  </SelectItem>
                  <SelectItem value={OrderHistoryFilterType.REJECTED}>
                    Rejected
                  </SelectItem>
                </SelectContent>
              </Select>
            )}
          </div>
        </div>
        <TabsContent className="relative flex-1" value="positions">
          <div className="absolute inset-0 h-full w-full">
            <PositionsTableContainer />
          </div>
        </TabsContent>
        <TabsContent className="relative flex-1" value="open-orders">
          <div className="absolute inset-0 h-full w-full">
            <OpenOrdersTableContainer />
          </div>
        </TabsContent>
        <TabsContent className="relative flex-1" value="order-history">
          <div className="absolute inset-0 h-full w-full">
            <OrdersHistoryTableContainer filter={orderHistoryFilter} />
          </div>
        </TabsContent>
        <TabsContent className="relative flex-1" value="transfers">
          <div className="absolute inset-0 h-full w-full">
            <TransfersTableContainer />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}
