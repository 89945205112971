import { useAccountStore } from '@/store/use-account-store';
import { NetworkType } from '@/types';
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';

export const useSetAccount = () => {
  const { address, addresses, isConnected: _isConnected } = useAccount();

  const { publicKey, connected } = useWallet();

  const { setAddress, networkType, address: staleAddress } = useAccountStore();
  const isConnected = _isConnected || connected;

  useEffect(() => {
    if (isConnected) {
      setAddress(
        connected && networkType === NetworkType.SOLANA
          ? publicKey?.toBase58() || ''
          : (address as string),
      );
    } else setAddress(undefined);
  }, [address, isConnected, connected, networkType, publicKey, setAddress]);
};
