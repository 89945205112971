import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormItem,
} from '@/features/trade/order/components/order-form-components';
import { Input } from '@/components/ui';
import { UnitSelect } from './unit-select';
import { abbrFromSym } from '@/utils/token-symbol';
import {
  adjustDecimals,
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import {
  COLLATERAL_DECIMALS,
  COLLATERAL_SYMBOL,
  useMarketStore,
} from '@/store/use-markets-store';
import { useFormContext } from 'react-hook-form';
import { CustomSlider } from './custom-slider';
import { rangeCount } from '../utils/helpers';
import { useStore } from 'zustand';

interface SizeInputProps {
  symbol: string;
  onSizeChange: (value: bigint) => void;
  onSizeUnitChange?: (unit: string) => void;
  onHasSizeInputChange?: (hasSizeInput: boolean) => void;
  maxSize?: bigint;
  isSuccess?: boolean;
  limitPrice?: bigint;
}

export const SizeInput: React.FC<SizeInputProps> = ({
  symbol,
  limitPrice,
  maxSize,
  onSizeUnitChange,
  onSizeChange,
  onHasSizeInputChange,
  isSuccess,
}) => {
  const { formState } = useFormContext();

  const { marketData, marketSpec } = useStore(
    useMarketStore(symbol),
    (state) => ({ marketData: state.marketData, marketSpec: state.marketSpec }),
  );

  const price = limitPrice || marketData.markPrice;

  const [sizeInput, setSizeInput] = useState<string>(
    maxSize
      ? formatBigInt(
          maxSize * price,
          marketSpec.sizeDecimals + marketSpec.priceDecimals,
          { digits: 2 },
        )
      : '',
  );

  const [sizeUnit, setSizeUnit] = useState<string>(COLLATERAL_SYMBOL);
  const [sizePct, setSizePct] = useState<number>(100);

  const [lastChangedInput, setLastChangedInput] = useState<
    'sizeInput' | 'sizePct' | null
  >(null);

  useEffect(() => {
    if (lastChangedInput === 'sizeInput' || sizePct <= 0) return;
    if (maxSize === 0n) {
      setSizeInput('');
      onSizeChange(0n);
      onHasSizeInputChange?.(false);
    } else {
      handleSizePctChange(sizePct);
    }
  }, [maxSize, lastChangedInput]);

  useEffect(() => {
    handleSizePctChange(sizePct);
  }, [sizeUnit]);

  const handleSizeChange = (value: string) => {
    let validatedValue = value;
    if (sizeUnit === COLLATERAL_SYMBOL) {
      validatedValue = validatedDecimalStr(validatedValue, 2, 10);
    } else if (sizeUnit === abbrFromSym(symbol)) {
      validatedValue = validatedDecimalStr(
        validatedValue,
        Number(marketSpec.sizeDecimals),
        10,
      );
    } else {
      throw new Error('Invalid size unit');
    }

    const valueBigInt = parseDecimalToBigInt(
      validatedValue,
      sizeUnit === COLLATERAL_SYMBOL
        ? COLLATERAL_DECIMALS
        : marketSpec.sizeDecimals,
    );

    onHasSizeInputChange?.(value !== '');

    if (maxSize !== undefined) {
      // if (valueBigInt > maxSize) return;
      setSizeInput(validatedValue);
      onSizeChange(valueBigInt);
      setLastChangedInput('sizeInput');
      const sizePctBigInt = (valueBigInt * 10n ** 6n) / maxSize;
      setSizePct(
        Number(formatBigInt(sizePctBigInt, 4, { maxDigits: 1, commas: false })),
      );
    } else {
      setSizeInput(validatedValue);
      onSizeChange(valueBigInt);
      setLastChangedInput('sizeInput');
    }
  };

  const handleUnitChange = (unit: string) => {
    setSizeUnit(unit);
    setLastChangedInput('sizeInput');
    onSizeUnitChange?.(unit);
  };

  const handleSizePctChange = (val: number) => {
    if (maxSize === undefined || val > 100) return;

    setSizePct(val);

    const valBigInt = BigInt((val * 10 ** 4).toFixed(0));
    const newSize = (valBigInt * maxSize) / 10n ** 6n;

    switch (sizeUnit) {
      case COLLATERAL_SYMBOL:
        const numeraireSize = adjustDecimals(
          newSize,
          COLLATERAL_DECIMALS -
            marketSpec.priceDecimals -
            marketSpec.sizeDecimals,
        );
        onSizeChange(numeraireSize);
        setSizeInput(
          formatNumber(bigIntToDecimalStr(numeraireSize, COLLATERAL_DECIMALS), {
            maxDigits: 2,
            commas: false,
          }),
        );
        break;

      case abbrFromSym(symbol):
        onSizeChange(newSize);
        setSizeInput(bigIntToDecimalStr(newSize, marketSpec.sizeDecimals));
        break;

      default:
        throw new Error('Invalid size unit');
    }

    setLastChangedInput('sizePct');
  };

  // Set baseSize from sizeInput, sizeUnit, leverage change
  // useEffect(() => {
  //   if (lastChangedInput !== 'sizeInput') return;
  //   let baseSize: bigint;
  //   if (sizeUnit === COLLATERAL_SYMBOL) {
  //     if (price === 0n) {
  //       baseSize = 0n;
  //     } else {
  //       baseSize = getSizeFromNumeraire(
  //         parseDecimalToBigInt(sizeInput, COLLATERAL_DECIMALS),
  //         price,
  //         marketSpec,
  //         false,
  //       );
  //     }
  //   } else {
  //     baseSize = parseDecimalToBigInt(sizeInput, marketSpec.sizeDecimals);
  //   }
  //   const leverageBigInt = parseDecimalToBigInt(String(leverage), 2n);
  //   baseSize = (baseSize * leverageBigInt) / 10n ** 2n;
  //   onBaseSizeChange(baseSize);
  // }, [sizeInput, sizeUnit, leverage, price]);

  useEffect(() => {
    if (formState.isSubmitting || !isSuccess) return;
    setSizeInput('');
    onHasSizeInputChange?.(false);
    setSizePct(0);
  }, [formState.isSubmitting, isSuccess]);

  return (
    <span>
      <FormItem className="h-10">
        <p className="text-vestgrey-200">Size</p>
        <div className="flex" onClick={(e) => e.stopPropagation()}>
          <FormControl>
            <Input
              className="max-w-[15ch] rounded-none bg-vestgrey-800 font-mono text-base text-foreground"
              onChange={(e) => handleSizeChange(e.target.value)}
              value={sizeInput}
              autoComplete="off"
            />
          </FormControl>
          <UnitSelect
            units={['USDC', abbrFromSym(symbol)]}
            onSelect={handleUnitChange}
          />
        </div>
      </FormItem>
      <FormItem className="flex-col items-stretch gap-2">
        {/* <p className="text-vestgrey-200">Close</p> */}
        <CustomSlider
          onChange={handleSizePctChange}
          value={sizePct}
          minValue={0}
          maxValue={100}
          unitSymbol="%"
          buttonValues={rangeCount(25, 100, 4, 0)}
          step={1}
          disabled={maxSize === undefined}
        />
      </FormItem>
    </span>
  );
};

// const changeSizeToMax = (e: MouseEvent<HTMLButtonElement>) => {
//   e.stopPropagation();
//   e.preventDefault();
// if (typeof maxNumeraireSize !== 'bigint') return;
// if (typeof openPrice !== 'bigint') {
//   // limit order
//   switch (sizeUnit) {
//     case COLLATERAL_SYMBOL:
//       setSizeInput(
//         formatBigInt(maxNumeraireSize, COLLATERAL_DECIMALS, {
//           digits: 2,
//           commas: false,
//         }),
//       );
//       break;
//     case abbrFromSym(symbol):
//       const maxBaseSize = getSizeFromNumeraire(
//         maxNumeraireSize,
//         price,
//         marketSpec,
//       );
//       setSizeInput(
//         formatBigInt(maxBaseSize, marketSpec.sizeDecimals, {
//           maxDigits: Number(marketSpec.sizeDecimals),
//           commas: false,
//         }),
//       );
//       break;
//   }
// } else {
//   // market order
//   let correctMaxBaseSize: bigint = getSizeFromNumeraire(
//     maxNumeraireSize,
//     price,
//     marketSpec,
//   );
//   if (
//     adjustDecimals(
//       baseSize * openPrice,
//       COLLATERAL_DECIMALS -
//         marketSpec.sizeDecimals -
//         marketSpec.priceDecimals,
//       'ceil',
//     ) >= maxNumeraireSize
//   ) {
//     const maxBaseSize = maxNumeraireSize / price;
//     if (maxBaseSize * openPrice > maxNumeraireSize) {
//       correctMaxBaseSize = getSizeFromNumeraire(
//         maxNumeraireSize,
//         (price + openPrice) / 2n,
//         marketSpec,
//         false,
//       );
//       sizeUnit === COLLATERAL_SYMBOL &&
//         onBaseSizeChange(correctMaxBaseSize);
//     }
//   }
//   switch (sizeUnit) {
//     case COLLATERAL_SYMBOL:
//       setSizeInput(
//         formatBigInt(maxNumeraireSize, COLLATERAL_DECIMALS, {
//           digits: 2,
//           commas: false,
//         }),
//       );
//       break;
//     case abbrFromSym(symbol):
//       setSizeInput(
//         formatBigInt(correctMaxBaseSize, marketSpec.sizeDecimals, {
//           maxDigits: Number(marketSpec.sizeDecimals),
//           commas: false,
//         }),
//       );
//       break;
//   }
// }
// setLastChangedInput('sizeInput');
// };
