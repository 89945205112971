import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export default function OAuthRedirectPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const platform = searchParams.get('platform');
    if (!platform || !['discord', 'twitter'].includes(platform)) return;
    toast.success(`Successfully connected your ${platform} account!`);
  }, []);

  return <Navigate to="/points" />;
}
