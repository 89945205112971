import useLpPerformance, { PERIODS } from '../api/get-performance';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createChart, ISeriesApi, UTCTimestamp } from 'lightweight-charts';
import { cn } from '@/lib/utils';
import triangleLoader from '@/assets/branding/triangles-loader.gif';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui';

const igniteAPR = 3;

export default function PerformanceChart() {
  const [period, setPeriod] = useState(PERIODS[1]);
  const [activeTab, setActiveTab] = useState<'roi' | 'pnl' | 'tvl'>('roi');
  const { data, isLoading } = useLpPerformance(period);

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ReturnType<typeof createChart>>();
  const seriesRef = useRef<ISeriesApi<'Line'>>();

  const formattedData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    if (data.length === 0) return [];
    const formattedData = [...data];
    formattedData.sort(
      (a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime(),
    );
    switch (activeTab) {
      case 'roi':
        const numberOfDays =
          (new Date(formattedData[formattedData.length - 1].ts).getTime() -
            new Date(formattedData[0].ts).getTime()) /
          (24 * 60 * 60 * 1000);
        return formattedData.map(({ pricePerShare, ts }, i, arr) => ({
          value:
            (parseFloat(pricePerShare) / parseFloat(arr[0].pricePerShare) - 1) *
              100 +
            (igniteAPR * (numberOfDays / 365) * i) / data.length,
          time: (new Date(ts).getTime() / 1000) as UTCTimestamp,
        }));
      case 'pnl':
        return formattedData
          .filter(({ pnl }) => pnl !== null)
          .map(({ pnl, ts }) => ({
            value: parseFloat(pnl as string),
            time: (new Date(ts).getTime() / 1000) as UTCTimestamp,
          }));
      case 'tvl':
        return formattedData.map(({ shares, pricePerShare, ts }) => ({
          value: parseFloat(shares) * parseFloat(pricePerShare),
          time: (new Date(ts).getTime() / 1000) as UTCTimestamp,
        }));
    }
  }, [data, period.offset, activeTab]);

  useEffect(() => {
    if (isLoading || !chartContainerRef.current) return;
    chartRef.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: { color: '#0f0f0f' }, // Corrected property name and provided the correct type
        textColor: '#F5F5F5',
      },
      grid: {
        vertLines: {
          color: '#222127', // Match TradingView chart grid color
        },
        horzLines: {
          color: '#222127', // Match TradingView chart grid color
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
      autoSize: true,
    });

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current = chartRef.current.addLineSeries({
      color: lineColor,
      lineWidth: 2,
      priceFormat: {
        type: 'percent',
      },
    });

    seriesRef.current.setData(formattedData);

    return () => chartRef.current?.remove();
  }, [isLoading]);

  useEffect(() => {
    if (isLoading || !seriesRef.current) return;

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current.applyOptions({
      color: lineColor,
      priceFormat: { type: activeTab === 'roi' ? 'percent' : 'price' },
    });
    seriesRef.current.setData(formattedData);

    if (!chartRef.current || formattedData.length === 0) return;
    chartRef.current.timeScale().setVisibleRange({
      from: formattedData[0].time,
      to: formattedData[formattedData.length - 1].time,
    });
  }, [isLoading, data, formattedData]);

  return (
    <div className="col-span-3 flex flex-col">
      <div className="flex">
        <Tabs
          value={activeTab}
          onValueChange={(value) =>
            setActiveTab(value as 'roi' | 'pnl' | 'tvl')
          }
        >
          <TabsList>
            <TabsTrigger value="roi" className="h-14 border-t-0">
              ROI
            </TabsTrigger>
            <TabsTrigger value="pnl" className="h-14 border-t-0">
              P&L
            </TabsTrigger>
            <TabsTrigger value="tvl" className="h-14 border-t-0">
              TVL
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex flex-1 justify-end border-b border-border px-8">
          {PERIODS.map((p) => (
            <button
              className={cn(
                'w-20 font-mono',
                period.offset === p.offset
                  ? 'font-semibold text-foreground'
                  : 'text-vestgrey-600',
              )}
              onClick={() => setPeriod(p)}
              key={p.offset}
            >
              {p.label}
            </button>
          ))}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <img src={triangleLoader} alt="" className="w-12" />
        </div>
      ) : (
        <div className="flex-1">
          <div className="h-full w-full" ref={chartContainerRef} />
        </div>
      )}
    </div>
  );
}
