import { cn } from '@/lib/utils';
import { COLLATERAL_SYMBOL, useMarketStore } from '@/store/use-markets-store';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatBigInt,
} from '@/utils/value-format';
import { useParams } from 'react-router-dom';
import { useStore } from 'zustand';
import { motion, useAnimation } from 'framer-motion';
import { CSSProperties, useEffect } from 'react';

type Props = {
  price: bigint;
  size: bigint;
  total: bigint;
  type: 'bid' | 'ask';
  unit: string;
  maxTotal: bigint;
  isBar?: boolean;
};

export default function OrderBookRow({
  price,
  size,
  total,
  maxTotal,
  unit,
  type,
  isBar,
}: Props) {
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const { marketSpec, marketData } = useStore(
    useMarketStore(symbol),
    (state) => ({
      marketSpec: state.marketSpec,
      marketData: state.marketData,
    }),
  );

  const animation = useAnimation();

  const unitTotal =
    unit === COLLATERAL_SYMBOL ? total * marketData.markPrice : total;

  const totalNum = bigIntToNum(
    unitTotal,
    unit === COLLATERAL_SYMBOL
      ? marketSpec.sizeDecimals + marketSpec.priceDecimals
      : marketSpec.sizeDecimals,
  );

  const maxTotalNum = bigIntToNum(
    unit === COLLATERAL_SYMBOL ? maxTotal * marketData.markPrice : maxTotal,
    unit === COLLATERAL_SYMBOL
      ? marketSpec.sizeDecimals + marketSpec.priceDecimals
      : marketSpec.sizeDecimals,
  );

  useEffect(() => {
    if (!isBar) return;
    animation.start({
      opacity: [0.2, 0.1],
      transition: [
        { duration: 0.5, ease: 'easeOut' },
        { duration: 2, ease: 'easeOut' },
      ],
    });
  }, [price, size, isBar]);

  if (isBar) {
    return (
      <motion.div
        animate={animation}
        className={cn(
          'h-[20px] opacity-10',
          type === 'ask' ? 'bg-red' : 'bg-green',
        )}
        style={{ width: `${(totalNum / maxTotalNum) * 100}%` }}
      />
    );
  }

  return (
    <tr className="relative [&>td:first-child]:pl-4 [&>td:first-child]:text-left [&>td:last-child]:pr-4 [&>td]:text-right">
      <td
        className={cn('font-mono', type === 'bid' ? 'text-green' : 'text-red')}
      >
        {bigIntToDecimalStr(price, marketSpec.priceDecimals)}
      </td>
      <td className="font-mono">
        {formatBigInt(
          unit === COLLATERAL_SYMBOL ? size * marketData.markPrice : size,
          unit === COLLATERAL_SYMBOL
            ? marketSpec.sizeDecimals + marketSpec.priceDecimals
            : marketSpec.sizeDecimals,
          {
            digits:
              unit === COLLATERAL_SYMBOL ? 2 : Number(marketSpec.sizeDecimals),
          },
        )}
      </td>
      <td className="relative font-mono">
        {formatBigInt(
          unitTotal,
          unit === COLLATERAL_SYMBOL
            ? marketSpec.sizeDecimals + marketSpec.priceDecimals
            : marketSpec.sizeDecimals,
          {
            digits:
              unit === COLLATERAL_SYMBOL ? 2 : Number(marketSpec.sizeDecimals),
          },
        )}
      </td>
    </tr>
  );
}
