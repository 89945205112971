import commonConfig from './common';

const getConfig = () => ({
  ...commonConfig,
  isDevelopment: import.meta.env.VITE_ENV === 'development',
});

const config = getConfig();

export default config;
