import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { truncateAddress } from '@/utils/format';
import { useAccountStore } from '@/store/use-account-store';
import {
  Connection,
  useAccount,
  useConnections,
  useSwitchAccount,
  useSwitchChain,
} from 'wagmi';
import { TransferDialog } from './modals/transfer-dialog';
import { NetworkType, TransferType } from '@/types/enums';
import { AccountMenu } from './account-menu';
import { Button } from '../ui';
import RegisterDialog from './modals/register-dialog';
import { MintingDialog } from './modals/minting-modal';
import { ConnectModal } from './modals/connect-modal';
import { AccountModal } from './modals/account-modal';
import { Chain } from 'viem';
import { useUnifiedAccount } from '@/hooks';
import { updatePrivateApiHeaders } from '@/lib/api-clients/rest-client';
import { useWallet } from '@solana/wallet-adapter-react';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useOrdersStore } from '@/store/use-orders-store';
import { GuideModal } from './modals/guide-modal';
import { useModalStore } from '@/store/use-modal-store';
import { SwitchModal } from './modals/switch-modal';

interface IRKConnectButtonProps {}

export const RKConnectButton: React.FC<IRKConnectButtonProps> = ({}) => {
  const {
    isConnected,
    address,
    networkType,
    chainId,
    connector,
    isWrongAddress,
  } = useUnifiedAccount();
  const { address: activeAddress } = useAccount();
  const { wallet, publicKey } = useWallet();
  const { chains, switchChain } = useSwitchChain();

  const {
    hasAuth,
    setNetworkType,
    getAuthParamsByAddress,
    setHasAuth,
    setAddress,
  } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    setNetworkType: state.setNetworkType,
    getAuthParamsByAddress: state.getAuthParamsByAddress,
    setHasAuth: state.setHasAuth,
    setAddress: state.setAddress,
  }));
  const connections = useConnections();
  const { clearTransfersStore } = useTransfersStore.getState();
  const { clearOrdersStore } = useOrdersStore.getState();
  const { handleSwitchModalOpen } = useModalStore();

  // const connections = useUnifiedConnections();
  const { switchAccountAsync } = useSwitchAccount();

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  useEffect(() => {
    const authParams = getAuthParamsByAddress(address);
    if (authParams) {
      if (address === authParams.address) {
        updatePrivateApiHeaders({
          xrestservermm: `restserver${authParams.accountGroup}`,
          'X-API-KEY': authParams.apiKey,
        });

        setHasAuth(true);
      }
    } else {
      setHasAuth(false);
    }
  }, [address]);

  useEffect(() => {
    if (isConnected && !hasAuth) {
      if (isWrongAddress) {
        handleSwitchModalOpen(true);
        setAddress(activeAddress);
      } else setIsRegisterModalOpen(true);
      setIsConnectModalOpen(false);
    } else if (isConnected && hasAuth) {
      setIsRegisterModalOpen(false);
      setIsConnectModalOpen(false);
    }
  }, [isConnected, hasAuth, isRegisterModalOpen, address]);

  useEffect(() => {
    if (
      networkType === NetworkType.EVM &&
      isConnected &&
      chainId &&
      !chains.some((c: Chain) => c.id === chainId)
    ) {
      switchChain({ chainId: chains[0].id });
    }
  }, [chainId, isConnected]);

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const menuOptions = useMemo(() => {
    const evmConnections = connections
      .flatMap((connection) =>
        connection.accounts.map((account) => ({
          address: account,
          connector: connection.connector,
        })),
      )
      .map((account) => ({
        label: account.address,
        connector: account.connector,
        action: async () => {
          clearTransfersStore();
          clearOrdersStore();
          if (account.connector === connector) {
            setAddress(account.address);
          } else {
            setNetworkType(NetworkType.EVM);
            await switchAccountAsync({
              connector: account.connector,
            });
            setAddress(account.address);
          }
        },
      }));

    const solanaConnection = wallet
      ? [
          {
            label: publicKey?.toBase58() || '',
            connector: wallet.adapter,
            action: () => {
              clearTransfersStore();
              clearOrdersStore();
              setNetworkType(NetworkType.SOLANA);
            },
          },
        ]
      : [];

    return [...evmConnections, ...solanaConnection];
  }, [connections, wallet, publicKey, connector]);

  return (
    <div className="grid w-full">
      {!isConnected || !address ? (
        <div className="flex w-full items-center justify-between">
          <Button
            className="ml-6 h-14 w-full rounded-none font-mono text-base font-normal uppercase tracking-wider"
            onClick={() => setIsConnectModalOpen(true)}
          >
            CONNECT YOUR WALLET
          </Button>
        </div>
      ) : (
        <div className="flex w-full justify-between">
          <div className="flex gap-2">
            <AccountMenu
              options={menuOptions}
              openAccountModal={() => setIsAccountModalOpen(true)}
              openConnectModal={() => setIsConnectModalOpen(true)}
            >
              <span className="flex items-center text-primary">
                {truncateAddress(address as string, 6)}
              </span>
            </AccountMenu>
          </div>
          <div className="flex items-center gap-4">
            {import.meta.env.VITE_ENV === 'development' &&
              networkType === NetworkType.EVM && <MintingDialog />}
            <TransferDialog orderType={TransferType.DEPOSIT} />

            <TransferDialog
              orderType={TransferType.WITHDRAW}
              isOpen={isWithdrawModalOpen}
              onOpenChange={setIsWithdrawModalOpen}
            />

            <RegisterDialog
              open={isRegisterModalOpen}
              setOpen={setIsRegisterModalOpen}
              openAccountModal={() => setIsAccountModalOpen(true)}
            />
          </div>
        </div>
      )}
      <ConnectModal
        isOpen={isConnectModalOpen}
        onOpenChange={setIsConnectModalOpen}
      />
      <GuideModal />
      <SwitchModal />
      <AccountModal
        isOpen={isAccountModalOpen}
        onOpenChange={setIsAccountModalOpen}
      />
    </div>
  );
};
