import { CustomSlider } from '@/features/trade/order/components';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui';
import { useMarketStore } from '@/store/use-markets-store';
import { useStore } from 'zustand';
import { useParams } from 'react-router-dom';
import { getMaxLeverage } from '@/features/trade/order/utils/math';
import { abbrFromSym } from '@/utils/token-symbol';
import { FormEvent, useEffect, useState } from 'react';
import { useAccountStore } from '@/store/use-account-store';
import { useFetchAccount } from '@/features/account/api/get-account';
import triangleLoader from '@/assets/branding/triangles-loader.gif';
import useUpdateLeverage from '@/features/account/api/update-leverage';
import { cn } from '@/lib/utils';
import { SquarePen } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

export default function LeverageModal() {
  const [isOpen, setIsOpen] = useState(false);
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const marketSpec = useStore(
    useMarketStore(symbol),
    (state) => state.marketSpec,
  );

  const { isLoading: isAccountLoading } = useFetchAccount();

  const {
    hasAuth,
    account: { leverages, positions },
  } = useAccountStore();

  const maxLeverageDecimal = getMaxLeverage(marketSpec)?.decimal;
  const maxLeverage = maxLeverageDecimal ? Number(maxLeverageDecimal) : 0;

  const currentLeverage = leverages.find((lvg) => lvg.symbol === symbol)?.value;

  const [leverage, setLeverage] = useState(1);

  useEffect(() => {
    if (!currentLeverage) return;
    setLeverage(currentLeverage);
  }, [currentLeverage]);

  const { mutateAsync: mutateLeverageAsync, isPending } = useUpdateLeverage({
    onSuccess: (_lvg) => {
      setIsOpen(false);
    },
  });

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    mutateLeverageAsync({ symbol, value: leverage });
  }

  const isSmallerThanCurrent =
    !!currentLeverage && !!positions[symbol] && leverage < currentLeverage;

  const isDisabled =
    currentLeverage === leverage || isPending || isSmallerThanCurrent;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        disabled={!hasAuth || !currentLeverage}
        className={cn(
          'ml-auto flex h-full max-w-max items-center gap-4 border-l border-border px-8 text-primary transition-colors',
          hasAuth && !!currentLeverage
            ? 'hover:bg-vestgrey-900'
            : 'cursor-not-allowed',
        )}
      >
        <span className="inline-block text-vestgrey-100">Leverage:</span>
        <span className="font-mono tracking-widest">
          {isAccountLoading ? (
            <img className="mx-auto w-12" src={triangleLoader} alt="" />
          ) : !hasAuth ? (
            '-'
          ) : currentLeverage ? (
            currentLeverage + 'X'
          ) : (
            '-'
          )}
        </span>
      </DialogTrigger>
      <DialogContent
        className="gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <form onSubmit={onSubmit}>
          <DialogHeader className="px-5 pt-6">
            <DialogTitle className="text-xl text-white">
              Set Leverage
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 px-5 py-4">
            <p className="text-vestgrey-200">
              Control the leverage used for {abbrFromSym(symbol)} positions. The
              maximum leverage is {maxLeverage}x.
            </p>
            <CustomSlider
              onChange={(val) => setLeverage(val)}
              value={leverage}
              minValue={1}
              maxValue={maxLeverage}
              unitSymbol="X"
              buttonValues={
                maxLeverage == 50 
                  ? [10, 20, 30, 40, 50]
                  : maxLeverage == 20
                  ? [1, 5, 10, 15, 20]
                  : maxLeverage == 10
                    ? [1, 2, 5, 8, 10]
                    : [1, 2, 3, 4, 5]
              }
              step={1}
            />
          </div>
          <DialogFooter className="mt-6">
            <TooltipProvider>
              <Tooltip open={!isDisabled ? false : undefined} delayDuration={0}>
                <TooltipTrigger>
                  <div className="w-full">
                    <Button
                      disabled={isDisabled}
                      className="h-16 w-full rounded-none py-6 font-mono text-lg font-normal tracking-wider"
                    >
                      {isSmallerThanCurrent ? 'INVALID LEVERAGE' : 'SET'}
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  Cannot lower leverage with a position open
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
