import { useAccountStore } from '@/store/use-account-store';
import { NetworkType } from '@/types';
import { useWallet } from '@solana/wallet-adapter-react';
import { useDisconnect } from 'wagmi';

export const useUnifiedConnect = () => {
  const { disconnect } = useDisconnect();
  const { disconnect: solDisconnect, connected } = useWallet();
  const { networkType, setNetworkType } = useAccountStore((state) => ({
    networkType: state.networkType,
    setNetworkType: state.setNetworkType,
  }));

  return {
    disconnect: networkType
      ? () => {
          solDisconnect();
          setNetworkType(NetworkType.EVM);
        }
      : () => {
          disconnect();
          if (connected) setNetworkType(NetworkType.SOLANA);
        },
  };
};
