import { Interval } from '@/features/markets/api/get-klines';
import { publicApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { useQuery } from '@tanstack/react-query';

export type LpPerformanceRecord = {
  shares: string;
  pricePerShare: string;
  ts: string;
  pnl: string | null;
};

const getDays = (offset: number) =>
  Math.ceil(offset / (24 * 60 * 60 * 1000)) + 1;

const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
const sevenDaysOffset = new Date().getTime() - sevenDaysAgo.getTime();

const monthAgo = new Date();
monthAgo.setMonth(monthAgo.getMonth() - 1);
const monthOffset = new Date().getTime() - monthAgo.getTime();

const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
const threeMonthsOffset = new Date().getTime() - threeMonthsAgo.getTime();

type Period = {
  label: string;
  interval: Interval;
  limit: number;
  offset: number;
};

export const PERIODS: Period[] = [
  {
    label: '1W',
    offset: sevenDaysOffset,
    limit: 24 * 7,
    interval: '1h',
  },
  {
    label: '1M',
    offset: monthOffset,
    limit: getDays(monthOffset),
    interval: '1d',
  },
  {
    label: '3M',
    offset: threeMonthsOffset,
    limit: getDays(threeMonthsOffset),
    interval: '1d',
  },
];

async function getPerformance(
  offset: number,
  limit: number,
  interval: Interval,
): Promise<LpPerformanceRecord[]> {
  const now = useAccountStore.getState().getAccurateTime();
  const endTime = new Date(now).getTime();
  let startTime = new Date(now);
  startTime.setTime(startTime.getTime() - offset);
  // TEMP: max timeFrom - 11th March 2025
  const march11th = new Date('2025-03-11');
  march11th.setUTCHours(0, 0, 0, 0);

  return publicApi
    .get('/lpPerformance', {
      params: {
        startTime:
          startTime.getTime() < march11th.getTime()
            ? march11th.getTime()
            : startTime.getTime(), // TEMP: max timeFrom - 11th March 2025
        endTime,
        limit,
        interval,
      },
    })
    .then((res) => res.data);
}

export default function useLpPerformance({
  offset,
  limit,
  interval,
}: Omit<(typeof PERIODS)[number], 'label'>) {
  return useQuery({
    queryKey: ['lp', 'performance', { offset, limit, interval }],
    queryFn: () => getPerformance(offset, limit, interval),
  });
}
