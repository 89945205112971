import axios from 'axios';
import { publicApi } from '../api-clients/rest-client';

export const fetchers = [
  async () => {
    // Own NTP
    const data = await publicApi.get('/exchangeInfo/ntp');
    return new Date(data.timestamp);
  },
  async () => {
    // TimeAPI.io
    const response = await axios.get(
      'https://timeapi.io/api/Time/current/zone?timeZone=UTC',
    );
    return new Date(response.data.dateTime);
  },
  async () => {
    // WorldTimeAPI
    const response = await axios.get(
      'http://worldtimeapi.org/api/timezone/Etc/UTC',
    );
    return new Date(response.data.datetime);
  },
  async () => {
    // Fallback: System time
    return new Date();
  },
];

export const fetchTime = async () => {
  for (const fetcher of fetchers) {
    try {
      const fetchedTime = await fetcher();

      return fetchedTime.getTime();
    } catch (err) {
      console.error('Time fetch failed:', err);
    }
  }
  return 0;
};

export const fetchOffset = async () => {
  const accurateTime = await fetchTime();

  if (Math.abs(accurateTime - Date.now()) > 5_000) {
    return accurateTime - Date.now();
  }

  return 0;
};
