import {
  FilledOrder,
  NewOrder,
  useOrdersStore,
} from '@/store/use-orders-store';
import { CreateOrderInput } from '../../api/create-order';
import { toast } from 'sonner';
import { ErrorCode, OrderStatus, OrderType } from '@/types';
import {
  COLLATERAL_DECIMALS,
  MarketSpec,
  MarketStore,
  OrderBook,
  useMarketStore,
} from '@/store/use-markets-store';
import { useStore } from 'zustand';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatBigInt,
  formatDate,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { abbrFromSym } from '@/utils/token-symbol';
import VestWhiteIcon from '@/assets/branding/vest-white';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion';
import binanceIcon from '@/assets/icons/binance.png';
import hlIcon from '@/assets/icons/hl.png';
import loader from '@/assets/branding/loader.gif';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { getEstFillPrice, getSlippage } from '../../utils/math';
import {
  defaultBook,
  ExtExchange,
  getBinanceOrderBook,
  getHLOrderBook,
  SYMBOL_SHOULD_SCALE,
} from '../../api/get-books';
import Loader from '@/components/ui/loader';

const Loading = ({ orderType }: Pick<CreateOrderInput, 'orderType'>) => {
  return (
    <div className="flex items-center gap-4">
      <img className="ml-1 mt-0.5 h-6 w-6" src={loader} alt="" />
      <p className="text-lg font-medium uppercase tracking-wide text-primary [text-shadow:_0_0_24px_rgb(255,90,68)]">
        {orderType === OrderType.MARKET ? 'Executing' : 'Adding'} order...
      </p>
    </div>
  );
};

const Spread = ({
  book,
  marketSpec,
}: Pick<MarketStore, 'book' | 'marketSpec'>) => {
  const { asks, bids } = book;
  if (asks.length === 0 || bids.length === 0) return '-';
  const lowestAskPrice = bigIntToNum(asks[0][0], marketSpec.priceDecimals);
  const highestBidPrice = bigIntToNum(bids[0][0], marketSpec.priceDecimals);
  const spreadPct = (
    (100 * (lowestAskPrice - highestBidPrice)) /
    ((highestBidPrice + lowestAskPrice) / 2)
  ).toString();
  return spreadPct.includes('.')
    ? spreadPct.replace(/(\d+\.\d*?[1-9])\d*/, '$1') + '%'
    : spreadPct + '%';
};

const Slippage = ({
  value,
  decimals,
}: {
  value?: bigint;
  decimals: number | bigint;
}) => {
  if (value === undefined) return '-';
  if (value === 0n) return '0';
  const valueStr = bigIntToDecimalStr(value, decimals);
  if (!valueStr.includes('.')) {
    return valueStr;
  }
  return valueStr.replace(/(\d+\.\d*?[1-9])\d*/, '$1');
};

export const Success = (order: FilledOrder) => {
  const { isBuy, symbol, lastFilledPrice, lastFilledSize, markPrice, fees } =
    order;
  const [isLoading, setIsLoading] = useState(false);
  const { book, marketSpec } = useStore(useMarketStore(symbol), (state) => ({
    marketSpec: state.marketSpec,
    book: state.book,
  }));
  const [timestamp, setTimestamp] = useState('-');
  const { priceDecimals, sizeDecimals } = marketSpec;
  const [orderBooks, setOrderBooks] = useState<Record<ExtExchange, OrderBook>>({
    hyperliquid: defaultBook,
    binance: defaultBook,
  });
  const symbolAbbr = abbrFromSym(symbol);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const books = await Promise.all([
        getHLOrderBook(
          symbol,
          marketSpec,
          symbolAbbr in SYMBOL_SHOULD_SCALE.hyperliquid,
        ),
        getBinanceOrderBook(
          symbol,
          marketSpec,
          symbolAbbr in SYMBOL_SHOULD_SCALE.binance,
        ),
      ]);
      books.forEach((book) => setOrderBooks((prev) => ({ ...prev, ...book })));
      setTimestamp(
        formatDate(new Date(), {
          showTime: true,
          showSeconds: true,
        }),
      );
      setIsLoading(false);
    })();
  }, [order]);

  const costs = useMemo(() => {
    const getCostsFromBook = (exchange: ExtExchange) => {
      const { asks, bids } = orderBooks[exchange];
      if (asks.length === 0 || bids.length === 0)
        return {
          slippage: undefined,
          fees: undefined,
          total: undefined,
          enoughLiquidity: true,
        };

      const estFillPrice = getEstFillPrice(
        isBuy,
        order.lastFilledSize,
        orderBooks[exchange],
        marketSpec,
      );
      if (!estFillPrice)
        return {
          slippage: undefined,
          fees: undefined,
          total: undefined,
          enoughLiquidity: true,
        };
      const slippage =
        (isBuy ? 1n : -1n) *
        order.lastFilledSize *
        (estFillPrice.bigint - (asks[0][0] + bids[0][0]) / 2n);

      let feesMultiplier = 0n;

      switch (exchange) {
        case 'hyperliquid':
          feesMultiplier = 35n;
          break;
        case 'binance':
          feesMultiplier = 40n;
          break;
      }

      const fees =
        (feesMultiplier * (order.lastFilledSize * estFillPrice.bigint)) /
        10n ** 5n;

      return {
        slippage,
        fees,
        total: slippage + fees,
        enoughLiquidity: estFillPrice.enoughLiquidity,
      };
    };

    return Object.keys(orderBooks).reduce(
      (prev, exchange) => ({
        ...prev,
        [exchange]: getCostsFromBook(exchange as ExtExchange),
      }),
      {} as Record<
        ExtExchange,
        {
          slippage: bigint | undefined;
          fees: bigint | undefined;
          total: bigint | undefined;
          enoughLiquidity: boolean;
        }
      >,
    );
  }, [orderBooks]);

  const slippage =
    (isBuy ? 1n : -1n) * lastFilledSize * (lastFilledPrice - markPrice);

  const total = slippage + fees;

  const saved = useMemo(() => {
    const maxTotal = Object.values(costs)
      .filter((exchange) => exchange.total !== undefined)
      .reduce((prev, curr) => (curr.total! > prev ? curr.total! : prev), 0n);
    return maxTotal - total;
  }, [costs]);

  const savedPct =
    bigIntToNum(saved, priceDecimals + sizeDecimals) /
    bigIntToNum(
      order.lastFilledPrice * order.lastFilledSize,
      priceDecimals + sizeDecimals,
    );

  return (
    <div className="w-full">
      <div className="space-y-3">
        <p className="text-lg font-medium uppercase tracking-wide text-primary [text-shadow:_0_0_30px_rgb(255,90,68)]">
          Order Complete!
        </p>
        <p className="text-foreground">
          Market order executed at{' '}
          {bigIntToDecimalStr(lastFilledPrice, priceDecimals)} for{' '}
          {bigIntToDecimalStr(lastFilledSize, sizeDecimals)}{' '}
          {abbrFromSym(symbol)}.
        </p>
      </div>
      <Accordion type="single" value={saved > 0n ? 'saved' : undefined}>
        <AccordionItem className="border-b-0" value="saved">
          <AccordionContent className="pb-0 pt-3">
            <p className="text-[13px]">
              You saved:{' '}
              {isLoading ? (
                <Loader className="inline-block w-6" />
              ) : (
                <span className="text-green underline">
                  $
                  {formatBigInt(saved, COLLATERAL_DECIMALS, {
                    digits: 2,
                  })}{' '}
                  (
                  {formatNumber(savedPct * 100, {
                    digits: 2,
                  })}
                  %)
                </span>
              )}{' '}
              in trade execution cost!
            </p>
            <Accordion className="mt-3" type="single" collapsible>
              <AccordionItem className="border-b-0" value="comparison">
                <AccordionContent className="pt-4">
                  <table className="w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th className="w-1/4 border border-border py-3"></th>
                        <th className="w-1/4 border-y border-r border-border py-3">
                          <div className="mx-auto w-8">
                            <VestWhiteIcon />
                          </div>
                        </th>
                        <th className="w-1/4 border-y border-r border-border py-3">
                          <img
                            className="mx-auto w-7"
                            src={hlIcon}
                            alt="HyperLiquid"
                          />
                        </th>
                        <th className="w-1/4 border-y border-r border-border py-3">
                          <img
                            className="mx-auto w-7"
                            src={binanceIcon}
                            alt="HyperLiquid"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="group/row">
                        <td className="w-1/4 border-x border-t border-border py-3 text-center font-mono uppercase tracking-wider text-primary group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600">
                          Spread
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          <Spread book={book} marketSpec={marketSpec} />
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          <Spread
                            book={orderBooks.hyperliquid}
                            marketSpec={marketSpec}
                          />
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          <Spread
                            book={orderBooks.binance}
                            marketSpec={marketSpec}
                          />
                        </td>
                      </tr>
                      <tr className="group/row">
                        <td className="w-1/4 border-x border-t border-border py-3 text-center font-mono uppercase tracking-wider text-primary group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600">
                          Slippage
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          <Slippage
                            value={slippage}
                            decimals={priceDecimals + sizeDecimals}
                          />
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {!costs.hyperliquid.enoughLiquidity && (
                            <span>{'>='}</span>
                          )}
                          <Slippage
                            value={costs.hyperliquid.slippage}
                            decimals={sizeDecimals + priceDecimals}
                          />
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {!costs.binance.enoughLiquidity && (
                            <span>{'>='}</span>
                          )}
                          <Slippage
                            value={costs.binance.slippage}
                            decimals={sizeDecimals + priceDecimals}
                          />
                        </td>
                      </tr>
                      <tr className="group/row">
                        <td className="w-1/4 border-x border-t border-border py-3 text-center font-mono uppercase tracking-wider text-primary group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600">
                          Fees
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(fees, priceDecimals + sizeDecimals, {
                            maxDigits: 3,
                            round: 'ceil',
                          })}
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(
                            costs.hyperliquid.fees,
                            priceDecimals + sizeDecimals,
                            {
                              digits: 3,
                              round: 'ceil',
                            },
                          )}
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(
                            costs.binance.fees,
                            priceDecimals + sizeDecimals,
                            {
                              digits: 3,
                              round: 'ceil',
                            },
                          )}
                        </td>
                      </tr>
                      <tr className="group/row">
                        <td className="w-1/4 border-x border-t border-border py-3 text-center font-mono uppercase tracking-wider text-primary group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600">
                          Total
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(total, priceDecimals + sizeDecimals, {
                            maxDigits: 3,
                            round: 'ceil',
                          })}
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(
                            costs.hyperliquid.total,
                            priceDecimals + sizeDecimals,
                            {
                              maxDigits: 3,
                              round: 'ceil',
                            },
                          )}
                        </td>
                        <td className="w-1/4 border-r border-t border-border py-3 text-center font-mono tracking-wider group-first/row:border-t-0 group-last/row:border-y group-last/row:border-vestgrey-600 group-last/row:bg-vestgrey-800">
                          {formatBigInt(
                            costs.binance.total,
                            priceDecimals + sizeDecimals,
                            {
                              maxDigits: 3,
                              round: 'ceil',
                            },
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="mt-2 text-vestgrey-600">
                    Timestamp: {timestamp}
                  </p>
                </AccordionContent>
                <AccordionTrigger className="justify-center py-2 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-foreground/75" />
              </AccordionItem>
            </Accordion>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default async function createOrderToast(
  promise: Promise<FilledOrder | NewOrder>,
  order: CreateOrderInput,
  onError: (error: ErrorCode) => string,
) {
  toast.promise(promise, {
    loading:
      order.orderType === OrderType.MARKET ? (
        <Loading orderType={order.orderType} />
      ) : (
        'Adding order...'
      ),
    success: (order) => {
      if (order.orderType === OrderType.MARKET) {
        return <Success {...(order as FilledOrder)} />;
      } else {
        const { marketSpec } = useMarketStore(order.symbol).getState();
        const { limitPrice, size, symbol } = order as NewOrder;
        return `Limit order added at ${formatBigInt(limitPrice, marketSpec.priceDecimals)} for ${formatBigInt(size, marketSpec.sizeDecimals)} ${abbrFromSym(symbol)}`;
      }
    },
    error: onError,
  });
}
