import { Button, Table } from '@/components/ui';
import Loader from '@/components/ui/loader';
import { useFetchAccount } from '@/features/account/api/get-account';
import usePoints, {
  PointsHistoryRecord,
} from '@/features/account/api/get-points';
import useReferrals from '@/features/referrals/api/get-referrals';
import { privateApi } from '@/lib/api-clients/rest-client';
import { cn } from '@/lib/utils';
import { useAccountStore } from '@/store/use-account-store';
import { formatNumber } from '@/utils/value-format';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

const columns: ColumnDef<PointsHistoryRecord>[] = [
  {
    accessorKey: 'ts',
    header: 'TIME',
    cell: ({ row }) =>
      new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long',
        timeStyle: 'long',
        timeZone: 'UTC',
      }).format(new Date(row.original.ts).getTime()),
  },
  {
    accessorKey: 'point',
    header: 'POINTS',
    cell: ({ row }) => formatNumber(row.original.point),
  },
];

export default function PointsPage() {
  const { isLoading } = useFetchAccount();
  const { data } = usePoints();
  const { hasAuth, connections } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    connections: state.account.connections,
  }));

  async function connectNew(platform: 'discord' | 'twitter') {
    const loadingId = toast.loading('Redirecting...');
    try {
      const data = await privateApi.get(`/account/${platform}/oauth`);
      window.location = data.url;
    } catch (err) {
      toast.error('An error occurred, please try again.');
    } finally {
      toast.dismiss(loadingId);
    }
  }

  return (
    <div className="flex h-full flex-col">
      <div className="grid h-40 shrink-0 grid-cols-5 items-center border-b border-border bg-background">
        <h1 className="pl-8 text-2xl font-semibold sm:text-3xl lg:text-4xl">
          Points
        </h1>
        <div className="col-span-3 px-8">
          <p className="text-vestgrey-100">
            Earn points by trading and providing liquidity.{' '}
            <Link
              to="https://docs.vest.exchange/points"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline"
            >
              Learn more
            </Link>
          </p>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border-b border-r border-border p-8">
          <div className="flex flex-col gap-3">
            <h3 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
              Total Points
            </h3>
            <p
              className={cn(
                'h-10 text-3xl font-bold',
                data?.point ? 'text-foreground' : 'text-vestgrey-600',
              )}
            >
              {data?.point ? formatNumber(data.point) : '-'}
            </p>
          </div>
        </div>
        <div className="relative flex-1 border-b border-r border-border p-8">
          <div className="flex flex-col gap-3">
            {/* <div className="flex items-center justify-between gap-3"> */}
            <h3 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
              Twitter
            </h3>
            {/* {isLoading ? (
                <Loader className="absolute right-8 h-10" />
              ) : connections.twitter ? (
                <p className="flex h-4 select-none items-center gap-3 font-mono uppercase text-vestgrey-600">
                  <span className="hidden xl:inline">Connected as </span>
                  <span className="text-primary">{connections.twitter}</span>
                </p>
              ) : (
                <button
                  onClick={() => connectNew('twitter')}
                  className="flex h-4 max-w-max items-center font-mono uppercase text-primary underline"
                >
                  Connect
                </button>
              )}
            </div> */}
            <div className="flex h-10 items-center">
              {isLoading ? (
                <Loader className="w-12" />
              ) : connections.twitter ? (
                <p
                  className={cn(
                    'text-lg',
                    connections.twitter
                      ? 'text-foreground'
                      : 'text-vestgrey-600',
                  )}
                >
                  <span className="mr-2 hidden xl:inline-block">
                    Connected as{' '}
                  </span>
                  <span className="font-mono font-bold uppercase tracking-wide text-primary">
                    {connections.twitter}
                  </span>
                </p>
              ) : (
                <button
                  disabled={!hasAuth}
                  onClick={() => connectNew('twitter')}
                  className="font-mono text-lg font-bold uppercase tracking-wide text-primary underline disabled:opacity-60"
                >
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="relative flex-1 border-b border-r border-border p-8">
          <div className="flex flex-col gap-3">
            {/* <div className="flex items-center justify-between gap-3"> */}
            <h3 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
              Discord
            </h3>
            {/* {true ? (
                <Loader className="absolute right-8 h-10" />
              ) : connections.discord ? (
                <p className="flex h-4 select-none items-center gap-3 font-mono uppercase text-vestgrey-600">
                  <span className="hidden xl:inline">Connected as </span>
                  <span className="text-primary">{connections.discord}</span>
                </p>
              ) : (
                <button
                  onClick={() => connectNew('discord')}
                  className="flex h-4 max-w-max items-center font-mono uppercase text-primary underline"
                >
                  Connect
                </button>
              )}
            </div> */}
            <div className="flex h-10 items-center">
              {isLoading ? (
                <Loader className="w-12" />
              ) : connections.discord ? (
                <p
                  className={cn(
                    'text-lg',
                    connections.discord
                      ? 'text-foreground'
                      : 'text-vestgrey-600',
                  )}
                >
                  <span className="mr-2 hidden xl:inline-block">
                    Connected as{' '}
                  </span>
                  <span className="font-mono font-bold uppercase tracking-wide text-primary">
                    {connections.discord}
                  </span>
                </p>
              ) : (
                <button
                  disabled={!hasAuth}
                  className="font-mono text-lg font-bold uppercase tracking-wide text-primary underline disabled:opacity-60"
                  onClick={() => connectNew('discord')}
                >
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 border-b border-border p-8"></div>
        <div className="flex-1 border-b border-border p-8"></div>
      </div>
      <Table data={data?.history || []} columns={columns} />
    </div>
  );
}
