import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { lazy, Suspense } from 'react';
import { formatBigInt, parseDecimalToBigInt } from '@/utils/value-format';
import { useEffect, useMemo, useState } from 'react';
import { getBalance } from '@wagmi/core';
import { solNetwork, USDC_MINT_ADDRESS, wagmiConfig } from '@/lib/wagmi/config';
import { useAccount, useSwitchChain } from 'wagmi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { createDeposit } from '@/lib/api/create-deposit';
import { NetworkType, OrderStatus, TransferType } from '@/types/enums';
import {
  COLLATERAL_DECIMALS,
  useMarketStores,
} from '@/store/use-markets-store';
import { useCreateWithdraw } from '@/lib/api/create-withdraw';
import { getAvailableFunds } from '@/features/account/utils/math';
import { useOrdersStore } from '@/store/use-orders-store';
import { useAccountStore } from '@/store/use-account-store';
import { ChevronDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useShallow } from 'zustand/react/shallow';
import { getUSDCAddress } from '@/utils/addresses';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useTransfers } from '@/features/account/api/get-transfers';

import { NetworkModal } from './network-modal';
import { useUnifiedAccount } from '@/hooks';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { useSolanaDeposit } from '@/lib/api/create-solana-deposit';
import { useSolanaWithdraw } from '@/lib/api/create-solana-withdraw';
import { useModalStore } from '@/store/use-modal-store';

const transferFormSchema = z.object({
  token: z.string(),
  size: z.string().min(1),
  address: z.string(),
  chainId: z.number(),
});

type TransferFormValues = z.infer<typeof transferFormSchema>;

interface TransferDialogProps {
  orderType: TransferType;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  displayTrigger?: boolean;
}

const LazyTransferDialogContent = lazy(() =>
  Promise.resolve({
    default: TransferDialogContent,
  }),
);

export const WITHDRAW_THRESHOLD_AMOUNT = 50_000;
export const WITHDRAW_THRESHOLD_HOURS = 12;

export const TransferDialog = ({
  orderType,
  isOpen: externalIsOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}: TransferDialogProps) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;
  const { isWrongAddress } = useUnifiedAccount();
  const { handleSwitchModalOpen } = useModalStore();

  useEffect(() => {
    if (isControlled) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen, isControlled]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (isWrongAddress) {
      handleSwitchModalOpen(true);
      return;
    }
    if (!isControlled) {
      setInternalIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      {displayTrigger && (
        <DialogTrigger asChild>
          <Button
            variant={
              orderType === TransferType.DEPOSIT ? 'default' : 'secondary'
            }
            className={cn(
              'rounded-none font-mono text-sm font-normal uppercase tracking-wider',
              orderType === TransferType.WITHDRAW && 'h-10',
            )}
          >
            {orderType === TransferType.DEPOSIT ? 'Deposit' : 'Withdraw'}
          </Button>
        </DialogTrigger>
      )}
      {isOpen && (
        <Suspense fallback={null}>
          <LazyTransferDialogContent
            isOpen={isOpen}
            onOpenChange={handleOpenChange}
            orderType={orderType}
          />
        </Suspense>
      )}
    </Dialog>
  );
};

export function TransferDialogContent({
  orderType,
  isOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}: TransferDialogProps) {
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
  const { connection } = useConnection();

  const { address, chain, networkType } = useUnifiedAccount();
  const { publicKey } = useWallet();
  const { address: _address, chain: _chain } = useAccount();
  const { chains } = useSwitchChain();

  const [balance, setBalance] = useState(BigInt(0));

  const form = useForm<TransferFormValues>({
    resolver: zodResolver(transferFormSchema),
    defaultValues: {
      size: '',
    },
  });

  useEffect(() => {
    if (
      orderType !== TransferType.DEPOSIT ||
      networkType === NetworkType.SOLANA
    )
      return;
    setBalance(0n);
    form.resetField('size');
    const fetchBalance = async () => {
      if (!_address || !_chain?.id) return;

      const balance = await getBalance(wagmiConfig, {
        address: _address,
        blockTag: 'latest',
        token: getUSDCAddress(_chain.id),
      });
      setBalance(balance.value);
    };
    fetchBalance();
  }, [address, _chain?.id, orderType]);

  useEffect(() => {
    if (
      networkType === NetworkType.SOLANA &&
      address &&
      orderType === TransferType.DEPOSIT
    ) {
      setBalance(0n);
      form.resetField('size');
      const fetchBalance = async () => {
        if (!publicKey) return;

        try {
          // Get the associated token address for USDC
          const usdcTokenAccount = await getAssociatedTokenAddress(
            new PublicKey(USDC_MINT_ADDRESS),
            publicKey,
          );

          // Get the account info for the token address
          const accountInfo = await getAccount(connection, usdcTokenAccount);

          // Convert balance from raw lamports to tokens (USDC has 6 decimal places)

          setBalance(accountInfo.amount);
        } catch (error) {
          console.error('Error fetching USDC balance:', error);
        }
      };

      fetchBalance();
    }
  }, [address, networkType, connection]);

  const { account, getAccurateTime } = useAccountStore((state) => ({
    account: state.account,
    getAccurateTime: state.getAccurateTime,
  }));
  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]),
    ),
  );

  useEffect(() => {
    if (orderType !== TransferType.WITHDRAW) return;
    const availableFunds = getAvailableFunds(account, markets, newOrders);
    setBalance(availableFunds.bigint);
  }, [account, markets, newOrders, networkType]);

  const size = form.watch('size');

  const { depositForBurn } = useSolanaDeposit(size);
  const { withdraw: createSolanaWithdraw } = useSolanaWithdraw(size);

  useEffect(() => {
    if (address) {
      form.setValue('address', address);
    }
  }, [address]);

  useEffect(() => {
    if (networkType) {
      form.setValue('chainId', 0);
      form.setValue('token', USDC_MINT_ADDRESS);
    } else if (_chain?.id && !networkType) {
      form.setValue('chainId', _chain.id);
      form.setValue('token', getUSDCAddress(_chain.id));
    }
  }, [_chain?.id, networkType]);

  const { mutateAsync: createWithdraw } = useCreateWithdraw();

  const onSubmit = async (data: TransferFormValues) => {
    const size = parseDecimalToBigInt(
      data.size.endsWith('.') ? `${data.size}00` : data.size,
      COLLATERAL_DECIMALS,
    );
    if (!networkType) {
      if (orderType === TransferType.DEPOSIT) {
        await createDeposit({
          size,
          chainId: data.chainId,
        });
      } else if (orderType === TransferType.WITHDRAW) {
        await createWithdraw({
          ...data,
          size,
        });
      }
    }
    if (networkType) {
      if (orderType === TransferType.DEPOSIT) {
        await depositForBurn();
      } else if (orderType === TransferType.WITHDRAW) {
        createSolanaWithdraw();
      }
    }
    onOpenChange(false);
    form.reset();
  };

  const { isSubmitting, isValid } = form.formState;

  const submitButtonText = useMemo(() => {
    if (isSubmitting) {
      return orderType === TransferType.DEPOSIT
        ? 'CONFIRM IN WALLET'
        : 'WITHDRAW';
    }
    return orderType === TransferType.DEPOSIT ? 'DEPOSIT' : 'WITHDRAW';
  }, [isSubmitting]);

  const bigIntSize = parseDecimalToBigInt(size, COLLATERAL_DECIMALS);

  const notEnoughFunds =
    orderType === TransferType.WITHDRAW && bigIntSize > balance;

  const openChainModal = () => setIsNetworkModalOpen(true);
  const { isPending: areTransfersPending } = useTransfers();
  const { transfers } = useTransfersStore();

  const isTimeLonger = useMemo(() => {
    if (orderType === TransferType.DEPOSIT) return false;
    const currentTime = getAccurateTime();
    const cumulatedAmount = Object.values(transfers)
      .filter(
        (transfer) =>
          transfer.orderType === TransferType.WITHDRAW &&
          transfer.status === OrderStatus.NEW &&
          currentTime - transfer.postTime <= 24 * 60 * 60 * 1000,
      )
      .reduce(
        (prev, transfer) =>
          prev +
          (transfer.status === OrderStatus.REJECTED ? 0n : transfer.size),
        0n,
      );
    return (
      bigIntSize + cumulatedAmount >=
      BigInt(WITHDRAW_THRESHOLD_AMOUNT) * 10n ** COLLATERAL_DECIMALS
    );
  }, [transfers, orderType, bigIntSize]);

  return (
    <DialogContent
      onEscapeKeyDown={
        isNetworkModalOpen ? (e) => e.preventDefault() : undefined
      }
      onPointerDown={isNetworkModalOpen ? (e) => e.preventDefault() : undefined}
      onInteractOutside={
        isNetworkModalOpen ? (e) => e.preventDefault() : undefined
      }
      className={cn(
        'gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none',
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.error('Form validation errors:', errors);
          })}
        >
          <DialogHeader className="px-5 pt-6">
            <DialogTitle className="text-xl text-white">
              {orderType === TransferType.DEPOSIT ? 'Deposit' : 'Withdraw'}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-1.5 px-5 py-6">
            <div className="flex h-10 items-center gap-3">
              <Label
                htmlFor="from"
                className="text-left text-base text-vestgrey-100"
              >
                {orderType === TransferType.DEPOSIT ? 'From' : 'To'}
              </Label>
              {openChainModal && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    openChainModal();
                  }}
                  className="flex h-full flex-1 items-center justify-between bg-vestgrey-800 px-2"
                  type="button"
                  style={{ zIndex: 10000 }}
                >
                  <span className="font-mono uppercase">{chain?.name}</span>
                  <ChevronDown size={16} className="text-vestgrey-100" />
                </button>
              )}
            </div>
            <div className="my-3 flex flex-col gap-3">
              <Label
                htmlFor="amount"
                className="text-left text-base text-vestgrey-100"
              >
                Amount
              </Label>
              <div className="grid grid-cols-[1fr_max-content_max-content] items-center gap-3">
                <Controller
                  control={form.control}
                  name="size"
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="size"
                      className="rounded-none bg-vestgrey-800 font-mono text-base"
                      type="text"
                      value={field.value}
                      onBlur={(e) =>
                        e.target.value.endsWith('.') &&
                        field.onChange(e.target.value.slice(0, -1))
                      }
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/,/g, '');
                        const value = parseDecimalToBigInt(
                          inputValue,
                          COLLATERAL_DECIMALS,
                        );
                        if (
                          value <= balance &&
                          (inputValue === '' ||
                            /^(\d+|\d*\.\d{0,2})$/.test(inputValue))
                        ) {
                          field.onChange(
                            inputValue.endsWith('.') &&
                              size.length > inputValue.length
                              ? inputValue.slice(0, -1)
                              : inputValue,
                          );
                        }
                      }}
                    />
                  )}
                />
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_34_356)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.98108 0.5C15.4935 0.5 19.9622 4.96867 19.9622 10.4811C19.9622 15.9935 15.4935 20.4622 9.98108 20.4622C4.46867 20.4622 0 15.9935 0 10.4811C0 4.96867 4.46867 0.5 9.98108 0.5Z"
                      fill="#2775CA"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2615 3.45891C12.0364 3.38701 11.8524 3.5206 11.8524 3.7569V4.33803C11.8524 4.49647 11.9718 4.6768 12.1206 4.73132C14.5096 5.60633 16.2191 7.90261 16.2191 10.591C16.2191 13.2794 14.5096 15.5756 12.1206 16.4506C11.9574 16.5104 11.8524 16.6702 11.8524 16.844V17.4251C11.8524 17.6613 12.0364 17.795 12.2615 17.723C15.2806 16.7584 17.4667 13.93 17.4667 10.591C17.4667 7.25196 15.2806 4.42352 12.2615 3.45891ZM8.10949 3.7569C8.10949 3.5206 7.92545 3.38701 7.70039 3.45891C4.68129 4.42352 2.49512 7.25192 2.49512 10.591C2.49512 13.9301 4.68129 16.7584 7.70039 17.723C7.92545 17.795 8.10949 17.6613 8.10949 17.4251V16.844C8.10949 16.6855 7.99007 16.5051 7.8413 16.4506C5.45233 15.5756 3.74275 13.2794 3.74275 10.591C3.74275 7.90265 5.45233 5.60633 7.8413 4.73132C7.99007 4.6768 8.10949 4.49647 8.10949 4.33803V3.7569ZM10.2928 5.60046H9.66905C9.49678 5.60046 9.35713 5.74008 9.35713 5.91234V6.87866C8.12045 7.05449 7.32099 7.87748 7.32099 8.93328C7.32099 10.2999 8.14715 10.8302 9.89094 11.0648C11.074 11.2586 11.4107 11.5134 11.4107 12.1867C11.4107 12.8597 10.8393 13.3085 10.0339 13.3085C8.94481 13.3085 8.58643 12.8319 8.45425 12.2151C8.42294 12.069 8.2978 11.9622 8.14836 11.9622H7.43685C7.25762 11.9622 7.11749 12.1236 7.14829 12.3002C7.32948 13.3384 7.99626 14.1001 9.35713 14.2848V15.2696C9.35713 15.4419 9.49678 15.5815 9.66905 15.5815H10.2928C10.4651 15.5815 10.6048 15.4419 10.6048 15.2696V14.2843C11.893 14.0796 12.7161 13.1828 12.7161 12.0744C12.7161 10.6161 11.8288 10.1265 10.1153 9.89201C8.85068 9.7084 8.60608 9.41257 8.60608 8.82102C8.60608 8.26024 9.03429 7.86233 9.86045 7.86233C10.6077 7.86233 11.0374 8.12313 11.215 8.7245C11.2546 8.85871 11.3747 8.95359 11.5146 8.95359H12.1708C12.3538 8.95359 12.4962 8.78544 12.4571 8.60664C12.2494 7.65629 11.6089 7.086 10.6048 6.9061V5.91234C10.6048 5.74008 10.4651 5.60046 10.2928 5.60046Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_34_356">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <button
                  // className="h-full bg-vestgrey-800 px-3 uppercase"
                  className="h-10 bg-black-alt px-4 py-2 font-mono text-sm uppercase"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    form.setValue(
                      'size',
                      formatBigInt(balance, COLLATERAL_DECIMALS, {
                        digits: 2,
                      }).replace(/,/g, ''),
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex h-10 items-center justify-between">
              <p className="text-base text-vestgrey-100">Available</p>
              <p className="text-font flex font-mono">
                {formatBigInt(balance, COLLATERAL_DECIMALS, { digits: 2 })}
              </p>
            </div>
            <div className="flex h-10 items-center justify-between">
              <p className="text-base text-vestgrey-100">
                {orderType === TransferType.DEPOSIT
                  ? 'Deposit Time'
                  : 'Withdraw Time'}
              </p>
              <p className="text-font flex font-mono">
                {networkType && orderType === TransferType.WITHDRAW
                  ? '~ 15 mins'
                  : networkType && orderType === TransferType.DEPOSIT
                    ? '~ 20s'
                    : areTransfersPending
                      ? '-'
                      : isTimeLonger
                        ? `${WITHDRAW_THRESHOLD_HOURS} hours`
                        : 'Instant'}
              </p>
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              className="h-14 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
              disabled={
                !isValid ||
                isSubmitting ||
                parseFloat(size) === 0 ||
                notEnoughFunds
              }
            >
              {notEnoughFunds ? 'INSUFFICIENT SIZE' : submitButtonText}
            </Button>
          </DialogFooter>
        </form>
      </FormProvider>

      <NetworkModal
        isOpen={isNetworkModalOpen}
        onOpenChange={setIsNetworkModalOpen}
      />
    </DialogContent>
  );
}
