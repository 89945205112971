import { COLLATERAL_SYMBOL, useMarketStore } from '@/store/use-markets-store';
import { useParams } from 'react-router-dom';
import { useStore } from 'zustand';
import { Trade, useTrades } from '../api/get-trades';
import { formatNumber, parseDecimalToBigInt } from '@/utils/value-format';
import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import Loader from '@/components/ui/loader';

type Props = {
  unit: string;
};

export default function Trades({ unit }: Props) {
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const trades = useStore(useMarketStore(symbol), (state) => state.trades);
  const { isLoading: areTradesLoading } = useTrades({ symbol });

  if (areTradesLoading) {
    return (
      <div className="relative flex h-full flex-col">
        <table className="mt-1 w-full border-separate border-spacing-y-1">
          <thead className="sticky top-0">
            <tr className="[&>th:first-child]:pl-4 [&>th:first-child]:text-left [&>th:last-child]:pr-4 [&>th]:px-2 [&>th]:text-right [&>th]:font-normal [&>th]:text-vestgrey-100">
              <th>Price</th>
              <th>Size ({unit})</th>
              <th>Time</th>
            </tr>
          </thead>
        </table>
        <div className="flex flex-1 items-center justify-center">
          <Loader className="w-12" />
        </div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-[518px]">
      <table className="mt-1 w-full border-separate border-spacing-y-1">
        <thead className="sticky top-0">
          <tr className="[&>th:first-child]:pl-4 [&>th:first-child]:text-left [&>th:last-child]:pr-4 [&>th]:bg-background [&>th]:px-2 [&>th]:text-right [&>th]:font-normal [&>th]:text-vestgrey-100">
            <th>Price</th>
            <th>Size ({unit})</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {trades.map((trade) => (
            <TradeRow {...trade} unit={unit} key={trade.id} />
          ))}
        </tbody>
      </table>
    </ScrollArea>
  );
}

const TradeRow = ({
  price,
  qty,
  quoteQty,
  time,
  unit,
}: Trade & { unit: string }) => {
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const { priceDecimals, sizeDecimals } = useStore(
    useMarketStore(symbol),
    (state) => state.marketSpec,
  );
  const isLong = parseDecimalToBigInt(qty, sizeDecimals) >= 0n;
  return (
    <tr
      className={cn(
        '[&>td:first-child]:pl-4 [&>td:first-child]:text-left [&>td:last-child]:pr-4 [&>td]:px-2 [&>td]:py-px [&>td]:text-right',
        // isLong ? '[&>td]:bg-green/10' : '[&>td]:bg-red/10',
      )}
    >
      <td className={cn('font-mono', isLong ? 'text-green' : 'text-red')}>
        {formatNumber(price, { digits: Number(priceDecimals), commas: false })}
      </td>
      <td className="font-mono">
        {unit === COLLATERAL_SYMBOL
          ? formatNumber(quoteQty, { digits: 2 }).replace('-', '')
          : formatNumber(qty, { digits: Number(sizeDecimals) }).replace(
              '-',
              '',
            )}
      </td>
      <td className="font-mono">
        {new Intl.DateTimeFormat('en-US', {
          timeStyle: 'medium',
          hour12: false,
        }).format(time)}
      </td>
    </tr>
  );
};
