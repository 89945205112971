export default function VestWhiteIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08095 0.13677L0.702214 5.24583C0.610981 5.32187 0.610981 5.45872 0.702214 5.53474L14.1135 16.2775C14.1819 16.3307 14.2732 16.3307 14.3416 16.2775L27.753 5.53474C27.8441 5.45872 27.8441 5.32187 27.753 5.24583L21.3818 0.13677C21.2601 0.0379339 21.0854 0.129167 21.0854 0.281223V16.726C21.0854 16.8249 21.0017 16.9085 20.9028 16.9085H7.62835C7.5295 16.9085 7.44588 16.8249 7.44588 16.726L7.37746 0.27362C7.37746 0.121565 7.20259 0.0379339 7.08095 0.129167V0.13677Z"
        fill="#E9E9E9"
      />
    </svg>
  );
}
