import { Loader } from 'lucide-react';
import { ProgressBar } from './progressbar';

export const ToastProgressBar = ({
  message,
  limit,
}: {
  message: string;
  limit: number;
}) => {
  return (
    <div className="relative overflow-hidden rounded-lg border-none bg-white p-4 shadow-md">
      <div className="mr-3 flex items-center gap-2 font-semibold text-gray-800">
        <Loader className="w-5 animate-spin duration-2s" />
        {message}
      </div>
      <ProgressBar limit={limit} />
    </div>
  );
};
