import { useMarketStore } from '@/store/use-markets-store';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatNumber,
} from '@/utils/value-format';
import { useParams } from 'react-router-dom';
import { useStore } from 'zustand';
import OrderBookRow from './order-book-row';
import { useRef } from 'react';
import Loader from '@/components/ui/loader';

type Props = {
  unit: string;
};

const ROWS = 10;

export default function OrderBook({ unit }: Props) {
  const tableRef = useRef<HTMLTableElement>(null);
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const { book, marketSpec, marketData } = useStore(
    useMarketStore(symbol),
    (state) => ({
      book: state.book,
      marketSpec: state.marketSpec,
      marketData: state.marketData,
    }),
  );

  const asks = [...book.asks]
    .reverse()
    .slice(book.asks.length - ROWS, book.asks.length);

  const bids = book.bids.slice(0, ROWS);

  if (bids.length === 0 || asks.length === 0) {
    return (
      <div className="relative flex h-full flex-col">
        <table className="mt-1 w-full border-separate border-spacing-y-1">
          <thead>
            <tr className="[&>th:first-child]:pl-4 [&>th:first-child]:text-left [&>th:last-child]:pr-4 [&>th]:pb-1 [&>th]:text-right [&>th]:font-normal [&>th]:text-vestgrey-100">
              <th>Price</th>
              <th className="whitespace-nowrap">Size ({unit})</th>
              <th className="whitespace-nowrap">Total ({unit})</th>
            </tr>
          </thead>
        </table>
        <div className="flex flex-1 items-center justify-center">
          <Loader className="w-12" />
        </div>
      </div>
    );
  }

  const [lowestAskPrice] = asks[asks.length - 1];
  const [highestBidPrice] = bids[0];

  const maxTotalBids = bids.reduce((prev, curr) => prev + curr[1], 0n);
  const maxTotalAsks = asks.reduce((prev, curr) => prev + curr[1], 0n);
  const maxTotal = maxTotalAsks > maxTotalBids ? maxTotalAsks : maxTotalBids;

  return (
    <div className="relative">
      <table
        ref={tableRef}
        className="mt-1 w-full border-separate border-spacing-y-1"
      >
        <thead>
          <tr className="[&>th:first-child]:pl-4 [&>th:first-child]:text-left [&>th:last-child]:pr-4 [&>th]:text-right [&>th]:font-normal [&>th]:text-vestgrey-100">
            <th>Price</th>
            <th className="whitespace-nowrap">Size ({unit})</th>
            <th className="whitespace-nowrap">Total ({unit})</th>
          </tr>
        </thead>
        <tbody>
          {asks.map(([price, size], i, arr) => (
            <OrderBookRow
              price={price}
              size={size}
              unit={unit}
              total={arr
                .slice(i, arr.length)
                .reduce((prev, curr) => prev + curr[1], 0n)}
              maxTotal={maxTotal}
              type="ask"
              key={`ask-${i}`}
            />
          ))}
          <tr>
            <td
              className="border-y border-border bg-vestgrey-800 px-4 py-1"
              colSpan={3}
            >
              <div className="flex items-center justify-center gap-6">
                <span>Spread</span>
                <span className="font-mono font-medium">
                  {bigIntToDecimalStr(
                    lowestAskPrice - highestBidPrice,
                    marketSpec.priceDecimals,
                  )}
                </span>
                <span className="font-mono font-medium">
                  {formatNumber(
                    (100 *
                      bigIntToNum(
                        lowestAskPrice - highestBidPrice,
                        marketSpec.priceDecimals,
                      )) /
                      bigIntToNum(
                        marketData.markPrice,
                        marketSpec.priceDecimals,
                      ),
                    { maxDigits: 4, style: 'percent' },
                  )}
                </span>
              </div>
            </td>
          </tr>
          {bids.map(([price, size], i, arr) => (
            <OrderBookRow
              price={price}
              size={size}
              unit={unit}
              total={arr
                .slice(0, i + 1)
                .reduce((prev, curr) => prev + curr[1], 0n)}
              maxTotal={maxTotal}
              type="bid"
              key={`bid-${i}`}
            />
          ))}
        </tbody>
      </table>
      <div className="absolute top-[26px] flex w-full flex-col gap-1">
        {asks.map(([price, size], i, arr) => (
          <OrderBookRow
            price={price}
            size={size}
            unit={unit}
            total={arr
              .slice(i, arr.length)
              .reduce((prev, curr) => prev + curr[1], 0n)}
            maxTotal={maxTotal}
            type="ask"
            isBar
            key={`ask-bar-${i}`}
          />
        ))}
      </div>
      <div
        style={{ top: 23 * asks.length + 56 }}
        className="absolute flex w-full flex-col gap-1"
      >
        {bids.map(([price, size], i, arr) => (
          <OrderBookRow
            price={price}
            size={size}
            unit={unit}
            total={arr
              .slice(0, i + 1)
              .reduce((prev, curr) => prev + curr[1], 0n)}
            maxTotal={maxTotal}
            type="bid"
            isBar
            key={`bid-bar-${i}`}
          />
        ))}
      </div>
    </div>
  );
}
