import { useFetchAccount } from '@/features/account/api/get-account';
import { cn } from '@/lib/utils';
import { LPOrderType, OrderStatus } from '@/types/enums';
import {
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { useLpOrders } from '../api/get-lp-orders';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { useMemo } from 'react';
import { LPTransferDialog } from './lp-transfer-dialog';
import { useAccountStore } from '@/store/use-account-store';

export default function LPStats() {
  const { data: account } = useFetchAccount();
  const { hasAuth } = useAccountStore();

  const { data: lpOrders } = useLpOrders({
    orderType: LPOrderType.SCHEDULE_WITHDRAW,
    status: OrderStatus.NEW,
  });

  // const totalIgniteRewards = useMemo(() => {
  //   if (
  //     !igniteChains ||
  //     igniteChains.length === 0 ||
  //     igniteChains[0].rewards.length === 0 ||
  //     isExchangeInfoError ||
  //     isExchangeInfoPending
  //   )
  //     return 0;
  //   const { decimals } = igniteChains[0].rewards[0].token;
  //   return (
  //     igniteChains[0].rewards[0].breakdowns
  //       .filter((bd) => bd.reason === 'VEST' && BigInt(bd.claimed) === 0n)
  //       .reduce(
  //         (prev, { pending }) => prev + bigIntToNum(BigInt(pending), decimals),
  //         0,
  //       ) * bigIntToNum(marketData.indexPrice, marketSpec.priceDecimals)
  //   );
  // }, [
  //   igniteChains,
  //   isExchangeInfoPending,
  //   isExchangeInfoError,
  //   marketData.indexPrice,
  //   marketSpec.priceDecimals,
  // ]);

  const pendingWithdrawal: bigint | undefined = useMemo(() => {
    return lpOrders?.reduce(
      (acc, order) =>
        acc + parseDecimalToBigInt(order.size, COLLATERAL_DECIMALS),
      0n,
    );
  }, [lpOrders]);

  const unrealizedPnl = account
    ? parseDecimalToBigInt(account.lp.unrealizedPnl, COLLATERAL_DECIMALS) +
      parseDecimalToBigInt(
        account.lp.internalIgnite.reward,
        COLLATERAL_DECIMALS,
      )
    : 0n;

  return (
    <div className="grid grid-cols-2 border-r border-border">
      <div className="col-span-2 border-b border-border p-8">
        <div className="flex flex-col gap-4">
          <h4 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Current Balance
          </h4>
          <div className="flex items-center justify-between">
            <strong className="text-font flex flex-1 items-center justify-start text-3xl lg:text-4xl">
              {formatNumber(account?.lp.balance, { digits: 2 })}
            </strong>
            {account && Number(account.lp.balance) > 0 && (
              <LPTransferDialog orderType={LPOrderType.SCHEDULE_WITHDRAW}>
                <button
                  className="bg-vestgrey-alt px-8 py-2 font-mono text-sm uppercase tracking-widest"
                  disabled={!hasAuth}
                >
                  Withdraw
                </button>
              </LPTransferDialog>
            )}
          </div>
        </div>
      </div>
      <div className="border-r border-border p-8">
        <div className="flex flex-col gap-3">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Total Unrealized Pnl
          </div>
          {account ? (
            <div className="flex items-center gap-2">
              <span
                className={cn(
                  'text-xl font-bold',

                  account
                    ? unrealizedPnl >= 0
                      ? 'text-green'
                      : 'text-red'
                    : undefined,
                )}
              >
                {formatBigInt(unrealizedPnl, COLLATERAL_DECIMALS, {
                  digits: 2,
                  showChange: true,
                })}
              </span>
              {/* <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info className="text-vestgrey-200" size={16} />
                  </TooltipTrigger>
                  <TooltipContent className="px-4 py-2">
                    <ul className="list-disc pl-4 text-left text-lg">
                      <li>
                        Base:{' '}
                        <span
                          className={cn(
                            'font-mono text-base',
                            +Number(account.lp.unrealizedPnl).toFixed(2) > 0
                              ? 'text-green'
                              : 'text-red',
                          )}
                        >
                          {formatNumber(
                            Number(account.lp.unrealizedPnl).toFixed(2),
                            {
                              digits: 2,
                              showChange: true,
                            },
                          )}
                        </span>
                      </li>
                      <li className="leading-tight">
                        Ignite:{' '}
                        <span
                          className={cn(
                            'font-mono text-base',
                            +Number(account.lp.internalIgnite.reward).toFixed(
                              2,
                            ) > 0
                              ? 'text-green'
                              : 'text-red',
                          )}
                        >
                          {formatNumber(
                            Number(account.lp.internalIgnite.reward).toFixed(2),
                            {
                              digits: 2,
                              showChange: true,
                            },
                          )}
                        </span>{' '}
                        <span className="text-base text-vestgrey-200">
                          (expected; claimable after distribution)
                        </span>
                      </li>
                    </ul>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider> */}
            </div>
          ) : (
            <p className="text-xl font-bold">-</p>
          )}
        </div>
      </div>
      {/* <div className="border-b border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Link
              to="https://app.zksyncignite.xyz/opportunities/zksync-era/VEST/0x7ccF5BbeC69c790D27dA3b5398B9e0d6D6EeC9F3"
              target="_blank"
              rel="noreferrer"
              className="font-mono text-sm uppercase tracking-wider text-vestgrey-600 underline"
            >
              Daily Ignite Rewards
            </Link>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info className="text-vestgrey-600" size={14} />
                </TooltipTrigger>
                <TooltipContent>
                  This box displays rewards on deposits from zksync. Rewards on
                  deposits from other chains are not displayed but will accrue
                  with the same APY and be distributed to your wallet every
                  Monday.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <p className="text-xl font-bold">
            {hasAuth ? (
              areIgniteRewardsLoading ? (
                <Loader className="w-8" />
              ) : (
                formatNumber(totalIgniteRewards, { digits: 2 })
              )
            ) : (
              '-'
            )}
          </p>
        </div>
      </div> */}
      <div className="p-8">
        <div className="flex flex-col gap-3">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Pending Withdrawal
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
            {formatBigInt(pendingWithdrawal, COLLATERAL_DECIMALS, {
              digits: 2,
            })}
          </div>
        </div>
      </div>
      {/* <div className="px-8 py-6">
        <div className="flex flex-col gap-2">
          <h4 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Total Rewards
          </h4>
          {account ? (
            <div className="flex items-center gap-2">
              <span className="text-font text-xl font-bold">
                {formatNumber(totalRewards, { maxDigits: 2 })}
              </span>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Info className="text-vestgrey-600" size={14} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <ul className="list-disc pl-4 text-left text-base">
                      <li>
                        Base -{' '}
                        {formatNumber(account.lp.rewardDistribution.base, {
                          maxDigits: 2,
                        })}
                      </li>
                      <li>
                        Ignite -{' '}
                        {formatNumber(account.lp.rewardDistribution.ignite, {
                          maxDigits: 2,
                        })}{' '}
                        <Link
                          to="https://app.zksyncignite.xyz/opportunities/zksync-era/VEST/0x7ccF5BbeC69c790D27dA3b5398B9e0d6D6EeC9F3"
                          target="_blank"
                          rel="noreferrer"
                          className="text-foreground underline"
                        >
                          Claim
                        </Link>
                      </li>
                    </ul>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ) : (
            <div className="text-font text-xl font-bold">-</div>
          )}
        </div>
      </div> */}
    </div>
  );
}
