import { create } from 'zustand';
import { CancelledEvent, OrderStatus, OrderType, RejectedEvent } from '@/types';
import { getOrders } from '@/features/account/api/get-orders';
import { useAccountStore } from './use-account-store';

export type NewOrder = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: bigint;
  size: bigint;
  reduceOnly: boolean;
  status: OrderStatus.NEW;
  initMarginRatio: bigint;
  postTime: number;
  tpPrice?: bigint;
  slPrice?: bigint;
};

export type TPSLOrder = {
  symbol: string;
  id: string;
  isBuy: boolean;
  orderType: OrderType.TAKE_PROFIT | OrderType.STOP_LOSS;
  limitPrice: string;
  size: string;
  reduceOnly?: boolean | undefined;
  initMarginRatio?: string | undefined;
};

export type FilledOrder = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: bigint;
  tpPrice?: bigint;
  slPrice?: bigint;
  size: bigint;
  reduceOnly: boolean;
  status: OrderStatus.FILLED;
  initMarginRatio: bigint;
  lastFilledSize: bigint;
  lastFilledPrice: bigint;
  lastFilledTime: number;
  avgFilledPrice: bigint;
  realizedPnl: bigint;
  settledFunding: bigint;
  fees: bigint;
  postTime: number;
  markPrice: bigint;
};

export type CancelledOrder = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: bigint;
  tpPrice?: bigint;
  slPrice?: bigint;
  size: bigint;
  reduceOnly: boolean;
  status: OrderStatus.CANCELLED;
  initMarginRatio: bigint;
  postTime: number;
};

export type RejectedOrder = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: bigint;
  tpPrice?: bigint;
  slPrice?: bigint;
  size: bigint;
  reduceOnly: boolean;
  status: OrderStatus.REJECTED;
  initMarginRatio: bigint;
  postTime: number;
  code: number;
};

export type Order = NewOrder | FilledOrder | CancelledOrder | RejectedOrder;

interface OrdersStore {
  orders: Record<string, Order>;
  filledOrderIds: Set<string>;
  newOrderIds: Set<string>;
  rejectedOrderIds: Set<string>;
  cancelledOrderIds: Set<string>;
  setOrder: (order: Order | CancelledEvent | RejectedEvent) => void;
  ordersToPoll: [string, OrderStatus, number][];
  pollOrdersIntervalId: NodeJS.Timeout | null;
  pollOrders: (
    newOrdersToPoll: [string, OrderStatus, number][],
    delay?: number,
  ) => void;
  clearOrdersStore: () => void;
}

export const useOrdersStore = create<OrdersStore>((set, get) => ({
  orders: {},
  filledOrderIds: new Set(),
  newOrderIds: new Set(),
  rejectedOrderIds: new Set(),
  cancelledOrderIds: new Set(),
  setOrder: (order: Order | CancelledEvent | RejectedEvent) => {
    const {
      orders,
      filledOrderIds,
      rejectedOrderIds,
      newOrderIds,
      cancelledOrderIds,
    } = get();

    const existingOrder = orders[order.id];
    const updatedOrder = existingOrder ? { ...existingOrder, ...order } : order;
    set({
      orders: { ...orders, [order.id]: updatedOrder as Order }, // force type to Order b/c we assume rejected/cancelled events are always merged with existing orders
    });

    switch (order.status) {
      case OrderStatus.FILLED:
        set({
          filledOrderIds: new Set([...filledOrderIds, order.id]),
        });
        if (newOrderIds.has(order.id)) {
          newOrderIds.delete(order.id);
          set({ newOrderIds: new Set([...newOrderIds]) });
        }
        break;
      case OrderStatus.REJECTED:
        set({ rejectedOrderIds: new Set([...rejectedOrderIds, order.id]) });
        if (newOrderIds.has(order.id)) {
          newOrderIds.delete(order.id);
          set({ newOrderIds: new Set([...newOrderIds]) });
        }
        break;
      case OrderStatus.NEW:
        set({ newOrderIds: new Set([...newOrderIds, order.id]) });
        break;
      case OrderStatus.CANCELLED:
        newOrderIds.delete(order.id);
        set({
          newOrderIds: new Set([...newOrderIds]),
          cancelledOrderIds: new Set([...cancelledOrderIds, order.id]),
        });
        break;
      default:
        console.error('Invalid order', order);
        break;
    }
  },

  ordersToPoll: [],
  pollOrdersIntervalId: null,

  pollOrders: (
    newOrdersToPoll: [string, OrderStatus, number][],
    delay: number = 1_500,
  ) => {
    const { ordersToPoll, pollOrdersIntervalId } = get();

    set({ ordersToPoll: [...ordersToPoll, ...newOrdersToPoll] });

    if (delay && delay > 0) {
      setTimeout(() => {
        startPolling();
      }, delay);
    } else {
      startPolling();
    }

    function startPolling() {
      if (pollOrdersIntervalId === null) {
        const intervalId = setInterval(() => {
          const {
            ordersToPoll,
            newOrderIds,
            filledOrderIds,
            rejectedOrderIds,
            cancelledOrderIds,
          } = get();

          const now = useAccountStore.getState().getAccurateTime();
          const updatedOrdersToPoll = ordersToPoll.filter(
            ([id, status, time]) => {
              if (now - time > 10_000) {
                // Internal 10s timeout
                return false;
              }

              switch (status) {
                case OrderStatus.NEW:
                  return !newOrderIds.has(id);

                case OrderStatus.PARTIALLY_FILLED:
                case OrderStatus.FILLED:
                  return !filledOrderIds.has(id);

                case OrderStatus.REJECTED:
                  return !rejectedOrderIds.has(id);

                case OrderStatus.CANCELLED:
                  return !cancelledOrderIds.has(id);

                default:
                  console.error('Invalid order status', status);
                  return false;
              }
            },
          );
          set({ ordersToPoll: updatedOrdersToPoll });

          if (updatedOrdersToPoll.length === 0) {
            clearInterval(intervalId);
            set({ pollOrdersIntervalId: null });
            return;
          }

          const minTime = Math.min(
            ...updatedOrdersToPoll.map(([, , time]) => time),
          );

          getOrders({ startTime: minTime });

          updatedOrdersToPoll
            .filter(([, status]) => status === OrderStatus.CANCELLED)
            .forEach(([id]) => {
              getOrders({ id: id, status: OrderStatus.CANCELLED });
            });
        }, 1_500);

        set({ pollOrdersIntervalId: intervalId });
      }
    }
  },

  clearOrdersStore: () => {
    set({
      orders: {},
      filledOrderIds: new Set(),
      rejectedOrderIds: new Set(),
      newOrderIds: new Set(),
      cancelledOrderIds: new Set(),
    });
  },
}));
