import { useWallet, Wallet } from '@solana/wallet-adapter-react';
import { Adapter } from '@solana/wallet-adapter-base';
import { Connector, useAccount, useConnect, useSwitchAccount } from 'wagmi';
import { useMemo } from 'react';
import { useAccountStore } from '@/store/use-account-store';
import { NetworkType } from '@/types';

export const useUnifiedConnectors = () => {
  const { wallets: _wallets, select, connecting } = useWallet();
  const { setNetworkType } = useAccountStore((state) => ({
    setNetworkType: state.setNetworkType,
  }));

  const { connectAsync, connectors, isPending } = useConnect();

  const handleConnect = async (connector: Connector) => {
    try {
      await connectAsync({ connector });
      setNetworkType(NetworkType.EVM);
    } catch (err) {
      console.error('connect wallet error: ', err);
    }
  };

  const handleSolConnect = (adapter: Adapter) => {
    try {
      select(adapter.name);
      setNetworkType(NetworkType.SOLANA);
    } catch (err) {
      console.error('connect wallet error: ', err);
    }
  };

  const wallets = useMemo(() => {
    const evmConnectors = connectors
      // .filter((connector) => connector.id !== 'app.phantom')
      .map((connector) => ({
        connector,
        name:
          connector.id === 'app.phantom'
            ? connector.name + '(EVM)'
            : connector.name,
        handleConnect: () => handleConnect(connector),
      }));

    const solConnectors = _wallets
      .filter((wallet) => wallet.readyState === 'Installed')
      .map(({ adapter: connector }: Wallet) => ({
        connector,
        name:
          connector.name === 'Phantom'
            ? connector.name + '(SOL)'
            : connector.name,
        handleConnect: () => handleSolConnect(connector),
      }));

    return [...evmConnectors, ...solConnectors];
  }, [_wallets, connectors]);

  return { connectors: wallets, isPending: isPending || connecting };
};
