import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  COLLATERAL_DECIMALS,
  COLLATERAL_SYMBOL,
  useMarketStore,
  useMarketStores,
} from '@/store/use-markets-store';
import { useStore } from 'zustand';
import {
  FormItem,
  FormLabel,
} from '@/features/trade/order/components/order-form-components';
import {
  Button,
  Collapsible,
  CollapsibleContent,
  Input,
} from '@/components/ui';
import { OrderCheckbox } from '@/features/trade/order/components';
import {
  CreateOrderInput,
  createOrderInputSchema,
  CreateOrderResponse,
  useCreateOrder,
} from '@/features/trade/order/api/create-order';
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from '@/features/trade/order/components/order-tabs';
import { useParams } from 'react-router-dom';
import {
  adjustDecimals,
  BigDecimal,
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  parseInputToDecimalStr,
} from '@/utils/value-format';
import {
  getEstFillPrice,
  getEstLiqPrice,
  getFee,
  getLimitPriceFromMaxSlippage,
  getMaxFillSize,
  getOrderValue,
  getSizeFromNumeraire,
  getSlippage,
} from '../utils/math';
import {
  getAvailableFunds,
  getPositionValue,
} from '@/features/account/utils/math';
import { useAccountStore } from '@/store/use-account-store';
import {
  FEE_DECIMALS,
  useExchangeInfo,
} from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '@/features/account/api/get-account';
import { useOrdersStore } from '@/store/use-orders-store';
import { TpslInput } from './tpsl-input';
import { OrderStatus, OrderType } from '@/types/enums';
import { ScrollArea } from '@/components/ui/scroll-area';
import { AccountStatsContainer } from '@/features/account/components';
import { useShallow } from 'zustand/react/shallow';
import { SizeInput } from './size-input';
import { MaxSlippageInput } from './max-slippage-input';
import { PriceInput } from './price-input';
import { cn } from '@/lib/utils';
import useGeofenceCheck from '@/utils/geofence-check';
import { abbrFromSym } from '@/utils/token-symbol';
import { useWebSocketStore } from '@/store/use-websocket-store';

interface IOrderFormProps {}

const FormValuesSchema = z.object({
  order: createOrderInputSchema,
  tp: z.object({
    size: z.string().optional(),
    limitPrice: z.string(),
  }),
  sl: z.object({
    size: z.string().optional(),
    limitPrice: z.string(),
  }),
});

type FormValues = z.infer<typeof FormValuesSchema>;

export const OrderFormContainer: FC<IOrderFormProps> = () => {
  const { symbol } = useParams() as { symbol: string };

  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo();

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  useEffect(() => {
    publicWs?.subscribeToChannels([`${symbol}@depth`]);
    return () => {
      publicWs?.unsubscribeFromChannels([`${symbol}@depth`]);
    };
  }, [symbol, publicWs, isUpdated]);

  if (isExchangeInfoPending) {
    return <div className="flex-1"></div>; // TODO: loading skeleton
  }

  if (isExchangeInfoError) {
    return <div>Error</div>; // TODO: error state
  }

  return <OrderForm symbol={symbol} />;
};

const OrderForm: FC<
  IOrderFormProps & {
    symbol: string;
  }
> = ({ symbol }) => {
  const { data: isSanctioned } = useGeofenceCheck();
  const defaultValues = useMemo(() => {
    return {
      order: {
        symbol: symbol,
        isBuy: true,
        size: '',
        orderType: OrderType.MARKET,
        limitPrice: '',
        reduceOnly: false,
      },
      tp: {
        size: '',
        limitPrice: '',
      },
      sl: {
        size: '',
        limitPrice: '',
      },
    };
  }, [symbol]);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValuesSchema),
    defaultValues,
  });
  const book = useStore(useMarketStore(symbol), (state) => state.book);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  useEffect(() => {
    form.setValue('order.symbol', symbol);
  }, [form, symbol]);

  const { account, hasAuth } = useAccountStore((state) => ({
    account: state.account,
    hasAuth: state.hasAuth,
  }));

  const leverage =
    account.leverages.find((lvg) => lvg.symbol === symbol)?.value || 1;

  const currentPosition = account.positions[symbol];

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { marketData, marketSpec } = markets[symbol];

  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]),
    ),
  );

  const { isPending: isFetchAccountPending } = useFetchAccount(); // might need to clear cache on disconnect

  const [tpslChecked, setTpslChecked] = useState(false);
  const [hasSizeInput, setHasSizeInput] = useState<boolean>(false);
  const [size, setSize] = useState(0n);

  const [sizeUnit, setSizeUnit] = useState(COLLATERAL_SYMBOL);

  // useEffect(() => {
  //   const leverageBigInt = parseDecimalToBigInt(String(leverage), 2n);
  //   const initMarginRatio = 10n ** 6n / leverageBigInt; // rounds down
  //   form.setValue(
  //     `order.initMarginRatio`,
  //     bigIntToDecimalStr(initMarginRatio, 4n),
  //   );
  // }, [leverage]);

  const isBuy = useWatch({
    control: form.control,
    name: 'order.isBuy',
  });

  const orderType = useWatch({
    control: form.control,
    name: 'order.orderType',
  });

  const reduceOnly = useWatch({
    control: form.control,
    name: 'order.reduceOnly',
  });

  const orderSize = useWatch({
    control: form.control,
    name: 'order.size',
  });

  const limitPrice = useWatch({
    control: form.control,
    name: 'order.limitPrice',
  });

  useEffect(() => {
    if (tpslChecked) {
      form.setValue('tp.size', orderSize);
      form.setValue('sl.size', orderSize);
    }
  }, [orderSize, tpslChecked]);

  const initMarginRatio = useMemo(
    () => 10n ** 6n / parseDecimalToBigInt(String(leverage), 2n),
    [leverage],
  );

  const sizeInBase = useMemo(() => {
    // const leveragedSize = (size * BigInt(leverage * 10 ** 2)) / 10n ** 2n;
    if (sizeUnit !== COLLATERAL_SYMBOL) return size;
    if (!marketData.markPrice) return 0n;
    return getSizeFromNumeraire(size, marketData.markPrice, marketSpec, false);
  }, [size, sizeUnit, leverage, marketData.markPrice]);

  useEffect(() => {
    const sizeDecimalStr = bigIntToDecimalStr(
      sizeInBase,
      marketSpec.sizeDecimals,
    );
    form.setValue(`order.size`, sizeDecimalStr);
  }, [sizeInBase]);

  // depends on account
  const { availableFunds, estLiqPrice } = useMemo(() => {
    if (isFetchAccountPending)
      return { availableFunds: undefined, estLiqPrice: undefined };

    const availableFunds = getAvailableFunds(account, markets, newOrders);
    const estLiqPrice = getEstLiqPrice(
      symbol,
      isBuy,
      sizeInBase,
      account,
      markets,
      initMarginRatio,
      orderType === 'LIMIT'
        ? parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals)
        : undefined,
    );
    return { availableFunds, estLiqPrice };
  }, [sizeInBase, account, markets, newOrders, limitPrice]);

  const { maxFillSize, openPrice, slippage } = useMemo(() => {
    if (orderType !== OrderType.MARKET)
      return { openPrice: undefined, slippage: undefined };

    const maxFillSize = getMaxFillSize(isBuy, book, marketSpec);
    const estFillPrice = getEstFillPrice(isBuy, sizeInBase, book, marketSpec);
    const openPrice =
      estFillPrice === undefined || estFillPrice.bigint === 0n
        ? ({
            bigint: marketData.markPrice,
            decimal: bigIntToDecimalStr(
              marketData.markPrice,
              marketSpec.priceDecimals,
            ),
          } as BigDecimal)
        : estFillPrice;

    const slippage =
      estFillPrice === undefined
        ? undefined
        : getSlippage(isBuy, openPrice.bigint, book);

    return { maxFillSize, openPrice, slippage };
  }, [orderType, isBuy, sizeInBase, book, marketSpec]);

  const { orderValue, fee } = useMemo(() => {
    const price =
      orderType === OrderType.MARKET
        ? marketData.markPrice
        : parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals);

    const orderValue = getOrderValue(sizeInBase, price, marketSpec);
    const fee = getFee(sizeInBase, price, marketSpec);

    return { orderValue, fee };
  }, [orderType, sizeInBase, limitPrice, marketSpec]);

  const tpPrice = useWatch({
    control: form.control,
    name: 'tp.limitPrice',
  });

  const slPrice = useWatch({
    control: form.control,
    name: 'sl.limitPrice',
  });

  const { buttonDisabled, buttonText } = useMemo(() => {
    if (isSanctioned) {
      return { buttonDisabled: true, buttonText: 'PLACE ORDER' };
    }

    if (!hasAuth) {
      return { buttonDisabled: true, buttonText: 'PLACE ORDER' };
    }

    if (isFetchAccountPending) {
      return { buttonDisabled: true, buttonText: 'PLACE ORDER' };
    }

    if (reduceOnly && (!currentPosition || currentPosition.isLong === isBuy)) {
      return {
        buttonDisabled: true,
        buttonText: 'INVALID REDUCE ONLY',
      };
    }

    if (!orderValue || orderValue.bigint === 0n) {
      return {
        buttonDisabled: true,
        buttonText: hasSizeInput ? 'INSUFFICIENT ORDER SIZE' : 'PLACE ORDER',
      };
    }

    const positionValue = getPositionValue(symbol, account, markets).bigint;

    if (!reduceOnly) {
      const positionOrderValue =
        currentPosition && currentPosition.isLong !== isBuy
          ? positionValue * currentPosition.initMarginRatio
          : 0n;

      const enoughMargin =
        availableFunds &&
        availableFunds.bigint > 0n &&
        orderValue.bigint * initMarginRatio <=
          availableFunds.bigint * 10n ** 4n + positionOrderValue;

      if (!enoughMargin) {
        return { buttonDisabled: true, buttonText: 'INSUFFICIENT SIZE' };
      }
    } else {
      const enoughMargin = positionValue >= orderValue.bigint;
      if (!enoughMargin) {
        return { buttonDisabled: true, buttonText: 'INVALID SIZE' };
      }
    }

    const parsedTpPrice = parseDecimalToBigInt(
      tpPrice,
      marketSpec.priceDecimals,
    );
    const price =
      orderType === OrderType.LIMIT
        ? parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals)
        : marketData.markPrice;

    if (orderType === OrderType.LIMIT) {
      if (
        (isBuy && price >= marketData.markPrice) ||
        (!isBuy && price <= marketData.markPrice)
      )
        return { buttonDisabled: true, buttonText: 'INVALID LIMIT PRICE' };
    }

    // Only validate TP/SL if the checkbox is checked
    if (tpslChecked) {
      if (
        tpPrice !== '' &&
        ((isBuy && parsedTpPrice <= price) ||
          (!isBuy && parsedTpPrice >= price))
      ) {
        return { buttonDisabled: true, buttonText: 'INVALID TP PRICE' };
      }

      const parsedSlPrice = parseDecimalToBigInt(
        slPrice,
        marketSpec.priceDecimals,
      );

      if (
        slPrice !== '' &&
        ((isBuy && parsedSlPrice >= price) ||
          (!isBuy && parsedSlPrice <= price))
      ) {
        return { buttonDisabled: true, buttonText: 'INVALID SL PRICE' };
      }
    }

    return { buttonDisabled: false, buttonText: 'PLACE ORDER' };
  }, [
    size,
    hasSizeInput,
    hasAuth,
    orderValue,
    orderType,
    orderSize,
    limitPrice,
    availableFunds,
    initMarginRatio,
    isBuy,
    reduceOnly,
    tpPrice,
    slPrice,
    tpslChecked, // Add this to re-validate when TP/SL checkbox is toggled
    isFetchAccountPending,
    account.positions[symbol],
    marketSpec.priceDecimals,
    marketSpec.sizeDecimals,
    marketData.markPrice,
    marketSpec.initMarginRatio,
  ]);

  const { mutateAsync: createOrder } = useCreateOrder();

  async function onSubmit(values: FormValues) {
    setIsSubmitSuccessful(false);
    const queue: (() => Promise<CreateOrderResponse>)[] = [];
    const order = values.order;

    // TODO update orders or something chain promises
    const validatedOrder = {
      ...order,
      limitPrice: parseInputToDecimalStr(limitPrice, marketSpec.priceDecimals),
    };

    // Only include TP/SL if the checkbox is checked
    if (tpslChecked && tpPrice) {
      validatedOrder['tpPrice'] = parseInputToDecimalStr(
        tpPrice,
        marketSpec.priceDecimals,
      );
    }

    if (tpslChecked && slPrice) {
      validatedOrder['slPrice'] = parseInputToDecimalStr(
        slPrice,
        marketSpec.priceDecimals,
      );
    }

    queue.push(() =>
      createOrder({
        order: validatedOrder,
        showToast: true,
        waitForConfirm: true,
      }),
    );

    if (
      values.order.orderType != OrderType.LIMIT &&
      tpslChecked &&
      values.tp.limitPrice !== ''
    ) {
      const tpOrder: CreateOrderInput = {
        symbol,
        orderType: OrderType.TAKE_PROFIT,
        isBuy: !order.isBuy,
        size: order.size, // FIXME: should really use values.tp.size, but this is undefined for some reason
        limitPrice: parseInputToDecimalStr(
          values.tp.limitPrice,
          marketSpec.priceDecimals,
        ),
        reduceOnly: true,
      };
      queue.push(() => createOrder({ order: tpOrder, showToast: false }));
    }

    if (
      values.order.orderType != OrderType.LIMIT &&
      tpslChecked &&
      values.sl.limitPrice !== ''
    ) {
      const slOrder: CreateOrderInput = {
        symbol,
        orderType: OrderType.STOP_LOSS,
        isBuy: !order.isBuy,
        size: order.size, // FIXME: should really use values.sl.size, but this is undefined for some reason
        limitPrice: parseInputToDecimalStr(
          values.sl.limitPrice,
          marketSpec.priceDecimals,
        ),
        reduceOnly: true,
      };
      queue.push(() => createOrder({ order: slOrder, showToast: false }));
    }

    // Chain all requests to ensure nonce monotonicity and return array of promise results
    const results = await queue.reduce(
      (
        acc: Promise<CreateOrderResponse[]>,
        curr: () => Promise<CreateOrderResponse>,
      ) => acc.then((results) => curr().then((result) => [...results, result])),
      Promise.resolve([]),
    );

    if (!results.some((item) => item.status === OrderStatus.REJECTED)) {
      setIsSubmitSuccessful(true);
      form.reset({
        ...defaultValues,
        order: {
          ...defaultValues.order,
          symbol,
          isBuy: order.isBuy,
          orderType: order.orderType,
        },
      });
      setHasSizeInput(false);
      setTpslChecked(false);
      setSize(0n);
    }
  }

  const maxSize = useMemo(() => {
    if (!hasAuth) return;
    if (reduceOnly) {
      if (!currentPosition || currentPosition.isLong === isBuy) return 0n;
      switch (sizeUnit) {
        case COLLATERAL_SYMBOL:
          return getPositionValue(symbol, account, markets).bigint;
        case abbrFromSym(symbol):
          return currentPosition.size;
        default:
          return 0n;
      }
    }
    const baseSizeToIncrease =
      currentPosition && currentPosition.isLong !== isBuy
        ? currentPosition.size
        : 0n;
    const leveragedBalance = availableFunds
      ? (availableFunds.bigint * BigInt(leverage * 10 ** 2)) / 10n ** 2n
      : 0n;
    switch (sizeUnit) {
      case COLLATERAL_SYMBOL:
        const numeraireSizeToIncrease =
          marketData.markPrice * baseSizeToIncrease;
        return leveragedBalance + numeraireSizeToIncrease;
      case abbrFromSym(symbol):
        if (!availableFunds || marketData.markPrice === 0n) return 0n;
        return (
          getSizeFromNumeraire(
            leveragedBalance,
            marketData.markPrice,
            marketSpec,
          ) + baseSizeToIncrease
        );
      default:
        return 0n;
    }
  }, [
    reduceOnly,
    currentPosition,
    isBuy,
    sizeUnit,
    marketData.markPrice,
    availableFunds?.bigint,
    leverage,
    hasAuth,
  ]);

  return (
    <div className="flex h-full flex-col">
      <Tabs
        defaultValue="true"
        className="w-full"
        onValueChange={(value) => {
          const isBuyValue = value === 'true';
          form.setValue(`order.isBuy`, isBuyValue);
        }}
      >
        <TabsList className="border-b border-border">
          <TabsTrigger
            value="true"
            className="data-[state=active]:!bg-green/5 data-[state=active]:text-green"
          >
            LONG
          </TabsTrigger>
          <TabsTrigger
            value="false"
            className="border-l border-border data-[state=active]:bg-red/5 data-[state=active]:text-red"
          >
            SHORT
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <Tabs
        defaultValue={OrderType.MARKET}
        className="w-full"
        value={form.watch(`order.orderType`)}
        onValueChange={(value) => {
          setIsSubmitSuccessful(false);
          form.setValue(`order.orderType`, value as OrderType);
          setTpslChecked(false);
          if (value === OrderType.LIMIT) {
            form.setValue(
              'order.limitPrice',
              bigIntToDecimalStr(
                marketData.markPrice,
                marketSpec.priceDecimals,
              ),
            );
            form.setValue('tp.limitPrice', '');
            form.setValue('tp.size', '');
            form.setValue('sl.limitPrice', '');
            form.setValue('sl.size', '');
          }
        }}
      >
        <TabsList>
          <TabsTrigger
            value={OrderType.MARKET}
            className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            MARKET
          </TabsTrigger>
          <TabsTrigger
            value={OrderType.LIMIT}
            className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            LIMIT
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <FormProvider {...form}>
        <form
          className="relative flex-1"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {/* Available Funds (non-input) */}
          {/* <div className="absolute inset-0 h-full w-full">
            <ScrollArea className="h-full"> */}
          <div className="flex h-full flex-col">
            <div className="flex flex-col px-2.5 py-2">
              <div className="flex h-10 items-center justify-between px-3">
                <p className="text-vestgrey-200">Available Funds</p>
                <p className="font-mono text-gray-50">
                  {formatNumber(availableFunds?.decimal, { digits: 2 })}
                </p>
              </div>
              {orderType === OrderType.MARKET && (
                <div className="flex h-10 items-center justify-between px-3">
                  <p className="text-vestgrey-200">Price</p>
                  <p className="font-mono text-gray-50">
                    {formatNumber(openPrice?.decimal, {
                      digits: Number(marketSpec.priceDecimals),
                    })}
                  </p>
                </div>
              )}
              {orderType === OrderType.LIMIT && (
                <PriceInput
                  defaultValue={bigIntToDecimalStr(
                    marketData.markPrice,
                    marketSpec.priceDecimals,
                  )}
                  isSuccess={isSubmitSuccessful}
                  symbol={symbol}
                  onPriceChange={(limitPrice) =>
                    form.setValue('order.limitPrice', limitPrice)
                  }
                />
              )}

              {/* Size Input */}
              <div className="mt-2">
                <SizeInput
                  symbol={symbol}
                  isSuccess={isSubmitSuccessful}
                  maxSize={maxSize}
                  onSizeChange={(value) => setSize(value)}
                  onSizeUnitChange={(unit) => setSizeUnit(unit)}
                  onHasSizeInputChange={(hasSize) => setHasSizeInput(hasSize)}
                  limitPrice={
                    orderType === OrderType.LIMIT
                      ? parseDecimalToBigInt(
                          limitPrice,
                          marketSpec.priceDecimals,
                        )
                      : undefined
                  }
                />
              </div>
              {/* Reduce | TP/SL */}
              {/* TODO: Handle Reset TP/SL params appropriately on uncheck */}
              <Collapsible className="h-[117px]" open={tpslChecked}>
                <div className="flex h-10 items-center">
                  <FormItem className="justify-start">
                    <OrderCheckbox
                      id="reduce-only"
                      checked={reduceOnly}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setTpslChecked(false);
                          form.setValue('tp.limitPrice', '');
                          form.setValue('tp.size', '');
                          form.setValue('sl.limitPrice', '');
                          form.setValue('sl.size', '');
                        }
                        form.setValue(`order.reduceOnly`, checked === true);
                      }}
                    />
                    <FormLabel
                      className="cursor-pointer select-none pl-4"
                      htmlFor="reduce-only"
                    >
                      Reduce Only
                    </FormLabel>
                  </FormItem>
                  <FormItem className="justify-start">
                    <OrderCheckbox
                      id="tpsl-checked"
                      checked={tpslChecked}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          form.setValue('order.reduceOnly', false);
                        } else {
                          // Clear TP/SL values when unchecking
                          form.setValue('tp.limitPrice', '');
                          form.setValue('tp.size', '');
                          form.setValue('sl.limitPrice', '');
                          form.setValue('sl.size', '');
                        }
                        setTpslChecked(checked === true);
                      }}
                      // disabled={account.positions[symbol]?.size > 0n}
                    />
                    <FormLabel
                      className="cursor-pointer select-none pl-4"
                      htmlFor="tpsl-checked"
                    >
                      TP/SL
                    </FormLabel>
                  </FormItem>
                </div>

                {/* TP/SL */}
                {/* <div
                    className={cn(
                      'flex w-full flex-col justify-center',
                      // "h-[160px]"
                    )}
                  > */}
                <CollapsibleContent className="collapsible-content space-y-3 overflow-visible p-3">
                  <TpslInput
                    {...form.register('tp')}
                    orderType={OrderType.TAKE_PROFIT}
                    symbol={symbol}
                    parentOrderName="order"
                    initMarginRatio={initMarginRatio}
                    showOrderType
                    index={0}
                  />
                  <TpslInput
                    {...form.register('sl')}
                    orderType={OrderType.STOP_LOSS}
                    symbol={symbol}
                    parentOrderName="order"
                    initMarginRatio={initMarginRatio}
                    showOrderType
                    index={0}
                  />
                </CollapsibleContent>
                {/* </div> */}
              </Collapsible>
            </div>
            <div className="flex flex-1 flex-col justify-end">
              {/* Non-input Order Params */}
              <div className="flex flex-col px-2.5 pb-4">
                {orderType === OrderType.MARKET ? (
                  <FormItem className="flex h-10 items-center justify-between">
                    <p className="flex items-center gap-2">
                      Slippage
                      {slippage !== undefined &&
                        maxFillSize &&
                        sizeInBase > maxFillSize.bigint && (
                          <TooltipProvider>
                            <Tooltip delayDuration={0}>
                              <TooltipTrigger>
                                <Info size={14} />
                              </TooltipTrigger>
                              <TooltipContent>
                                Due to the large order size, the actual slippage
                                incurred may be much higher than this estimate.
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                    </p>
                    <div className="flex items-center gap-3">
                      <div className="flex items-end gap-2">
                        <span className="text-sm leading-tight text-vestgrey-600">
                          Est:
                        </span>
                        <span
                          className={cn(
                            'font-mono leading-tight text-white',
                            slippage &&
                              Number(Number(slippage.decimal).toFixed(2)) <= 0 // TEMP: ugly
                              ? 'text-green'
                              : null,
                          )}
                        >
                          {formatNumber(slippage?.decimal, {
                            digits: 2,
                            style: 'slippage',
                          })}
                        </span>
                        <span className="px-1 leading-none">/</span>
                        <span className="text-sm leading-tight text-vestgrey-600">
                          Max
                        </span>
                      </div>
                      <div className="flex items-center bg-vestgrey-800">
                        <MaxSlippageInput
                          symbol={symbol}
                          isBuy={isBuy}
                          isSubmitSuccessful={isSubmitSuccessful}
                          onLimitPriceChange={(limitPrice) => {
                            form.setValue(
                              `order.limitPrice`,
                              bigIntToDecimalStr(
                                limitPrice,
                                marketSpec.priceDecimals,
                              ),
                            );
                          }}
                        />
                      </div>
                    </div>
                  </FormItem>
                ) : (
                  <div className="h-10" />
                )}
                <FormItem className="h-10">
                  <p>Liquidation Price</p>
                  <p className="font-mono text-vestgrey-50">
                    {formatNumber(estLiqPrice?.decimal, {
                      digits: Number(marketSpec.priceDecimals),
                    })}
                  </p>
                </FormItem>
                <FormItem>
                  <p>Order Size</p>
                  <p className="font-mono text-vestgrey-50">
                    {orderSize
                      ? `${orderSize} ${abbrFromSym(symbol)} (${formatNumber(orderValue?.decimal, { digits: 2 })})`
                      : '-'}
                  </p>
                </FormItem>
                <FormItem className="h-10">
                  <p>Fee</p>
                  <p className="font-mono text-vestgrey-50">
                    {formatBigInt(marketSpec.takerFee, FEE_DECIMALS - 2n, {
                      style: 'percent',
                    })}{' '}
                    ({formatNumber(fee?.decimal, { digits: 2 })})
                  </p>
                </FormItem>
              </div>
            </div>
            <Button
              type="submit"
              className="h-16 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
              size="lg"
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
            <div className="px-2.5 py-4">
              <AccountStatsContainer />
            </div>
          </div>
          {/* </ScrollArea>
          </div> */}
        </form>
      </FormProvider>
    </div>
  );
};
