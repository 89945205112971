import { queryOptions, useQuery } from '@tanstack/react-query';
import { publicApi } from '@/lib/api-clients/rest-client';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { useMarketStore } from '@/store/use-markets-store';

// TODO: Move to /markets/types or /src/types
export type Trade = {
  id: number;
  price: string;
  qty: string;
  quoteQty: string;
  time: number;
};

export const getTrades = (
  symbol: string,
  startTime?: number,
  endTime?: number,
  limit?: number,
): Promise<Trade[]> => {
  return publicApi
    .get('/trades', {
      params: {
        symbol,
        startTime,
        endTime,
        limit,
      },
    })
    .then((res: Trade[]) => {
      const { setTrades } = useMarketStore(symbol).getState();
      setTrades(res);
      return res;
    });
};

export const getTradesQueryOptions = ({
  symbol,
  startTime,
  endTime,
  limit,
}: {
  symbol: string;
  startTime?: number;
  endTime?: number;
  limit?: number;
}) => {
  return queryOptions({
    queryKey: ['trades', { symbol, startTime, endTime, limit }],
    queryFn: () => getTrades(symbol, startTime, endTime, limit),
  });
};

type UseTradesOptions = {
  symbol: string;
  startTime?: number;
  endTime?: number;
  limit?: number;
  queryConfig?: QueryConfig<typeof getTradesQueryOptions>;
};

export const useTrades = ({
  queryConfig,
  symbol,
  startTime,
  endTime,
  limit,
}: UseTradesOptions) => {
  return useQuery({
    ...getTradesQueryOptions({
      symbol,
      startTime,
      endTime,
      limit,
    }),
    ...queryConfig,
  });
};
