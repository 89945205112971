import { MarketSpec, OrderBook } from '@/store/use-markets-store';
import { abbrFromSym } from '@/utils/token-symbol';
import { parseDecimalToBigInt } from '@/utils/value-format';
import axios from 'axios';

export type ExtExchange = 'hyperliquid' | 'binance';

export const defaultBook: OrderBook = {
  bids: [],
  asks: [],
};

export const SYMBOL_EQUIVALENTS = {
  hyperliquid: {
    kPENGU: 'PENGU',
  },
  binance: {
    kBONK: '1000BONK',
    kPEPE: '1000PEPE',
    kNEIRO: 'NEIRO',
    kPENGU: 'PENGU',
    kSHIB: '1000SHIB',
  },
};

export const SYMBOL_SHOULD_SCALE = {
  hyperliquid: {},
  binance: {
    kNEIRO: 'NEIRO',
  },
}

export async function getHLOrderBook(
  symbol: string,
  marketSpec: MarketSpec,
  shouldScale: boolean = false,
): Promise<Partial<Record<ExtExchange, OrderBook>>> {
  try {
    const symbolAbbr = abbrFromSym(symbol);
    const { data: hyperliquidData } = await axios.post(
      'https://api.hyperliquid.xyz/info',
      {
        type: 'l2Book',
        coin:
          symbolAbbr in SYMBOL_EQUIVALENTS.hyperliquid
            ? SYMBOL_EQUIVALENTS.hyperliquid[
                symbolAbbr as keyof typeof SYMBOL_EQUIVALENTS.hyperliquid
              ]
            : symbolAbbr,
      },
    );
    const [bidLevels, askLevels] = hyperliquidData.levels;

    type HlOrderBookLevel = {
      px: string;
      sz: string;
    };

    const { priceDecimals, sizeDecimals } = marketSpec;

    const parseHlLevelsToBook = (
      levels: HlOrderBookLevel[],
    ): [bigint, bigint][] =>
      levels.map((level) => [
        parseDecimalToBigInt(level.px, priceDecimals + (shouldScale ? 3n : 0n)),
        parseDecimalToBigInt(level.sz, sizeDecimals) / (shouldScale ? 1000n : 1n),
      ]);

    const orderBook: OrderBook = {
      bids: parseHlLevelsToBook(bidLevels as HlOrderBookLevel[]),
      asks: parseHlLevelsToBook(askLevels as HlOrderBookLevel[]),
    };

    return { hyperliquid: orderBook };
  } catch (err) {
    console.error('Failed to get hyperliquid order book:', err);
    return { hyperliquid: defaultBook };
  }
}

export async function getBinanceOrderBook(
  symbol: string,
  marketSpec: MarketSpec,
  shouldScale: boolean = false,
): Promise<Partial<Record<ExtExchange, OrderBook>>> {
  try {
    const symbolAbbr = abbrFromSym(symbol);
    const { data: binanceData } = await axios.get(
      'https://fapi.binance.com/fapi/v1/depth',
      {
        params: {
          symbol:
            (symbolAbbr in SYMBOL_EQUIVALENTS.binance
              ? SYMBOL_EQUIVALENTS.binance[
                  symbolAbbr as keyof typeof SYMBOL_EQUIVALENTS.binance
                ]
              : symbolAbbr) + 'USDT',
          limit: 50,
        },
      },
    );
    const { priceDecimals, sizeDecimals } = marketSpec;
    const orderBook: OrderBook = {
      bids: binanceData.bids.map(([price, qty]: [string, string]) => [
        parseDecimalToBigInt(price, priceDecimals + (shouldScale ? 3n : 0n)),
        parseDecimalToBigInt(qty, sizeDecimals) / (shouldScale ? 1000n : 1n),
      ]),
      asks: binanceData.asks.map(([price, qty]: [string, string]) => [
        parseDecimalToBigInt(price, priceDecimals + (shouldScale ? 3n : 0n)),
        parseDecimalToBigInt(qty, sizeDecimals) / (shouldScale ? 1000n : 1n),
      ]),
    };
    return { binance: orderBook };
  } catch (err) {
    console.error('Failed to get binance order book:', err);
    return { binance: defaultBook };
  }
}
