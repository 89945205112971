import { lazy, Suspense } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui';
import { Label } from '@/components/ui/label';
import { Position, useAccountStore } from '@/store/use-account-store';
import {
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  parseInputToDecimalStr,
} from '@/utils/value-format';
import { useEffect, useMemo, useState } from 'react';
import { abbrFromSym, symbolToBaseQuote } from '@/utils/token-symbol';
import { COLLATERAL_SYMBOL, useMarketStore } from '@/store/use-markets-store';
import { useStore } from 'zustand';
import { createOrderInputSchema, useCreateOrder } from '../api/create-order';
import { z } from 'zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { OrderType } from '@/types/enums';
import {
  getEstFillPrice,
  getLimitPriceFromMaxSlippage,
  getSizeFromNumeraire,
  getSlippage,
} from '../utils/math';
import {
  getPositionInitMargin,
  getPositionValue,
  getRealizedPnl,
  MarketInfo,
} from '@/features/account/utils/math';
import { cn } from '@/lib/utils';
import { SizeInput } from './size-input';
import { MaxSlippageInput } from './max-slippage-input';
import { useWebSocketStore } from '@/store/use-websocket-store';
import { Tabs, TabsList, TabsTrigger } from './order-tabs';
import { PriceInput } from './price-input';

interface CloseDialogProps {
  symbol: string;
  position: Position;
  disabled?: boolean;
}

type CloseFormValues = z.infer<typeof createOrderInputSchema>;

const LazyCloseDialogContent = lazy(() =>
  Promise.resolve({
    default: CloseDialogContent,
  }),
);

export const CloseDialog = ({
  symbol,
  position,
  disabled,
}: CloseDialogProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          disabled={disabled}
          className="h-10 rounded-none bg-black-alt px-6 py-2 text-sm font-normal uppercase tracking-wider text-white transition-colors duration-200 hover:bg-black-alt focus:outline-none"
        >
          CLOSE
        </Button>
      </DialogTrigger>
      {open && (
        <Suspense fallback={null}>
          <LazyCloseDialogContent
            symbol={symbol}
            position={position}
            open={open}
            onOpenChange={setOpen}
          />
        </Suspense>
      )}
    </Dialog>
  );
};

interface CloseDialogContentProps extends CloseDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const CloseDialogContent = ({
  symbol,
  position,
  open,
  onOpenChange,
}: CloseDialogContentProps) => {
  const account = useAccountStore((state) => state.account);
  const { book, marketData, marketSpec } = useStore(
    useMarketStore(symbol),
    (state) => ({
      marketData: state.marketData,
      book: state.book,
      marketSpec: state.marketSpec,
    }),
  );

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  useEffect(() => {
    publicWs?.subscribeToChannels([`${symbol}@depth`]);
    return () => {
      publicWs?.unsubscribeFromChannels([`${symbol}@depth`]);
    };
  }, [symbol, publicWs, isUpdated]);

  const form = useForm<CloseFormValues>({
    resolver: zodResolver(createOrderInputSchema),
    defaultValues: {
      symbol,
      isBuy: !position.isLong,
      size: bigIntToDecimalStr(position.size, marketSpec.sizeDecimals),
      orderType: OrderType.MARKET,
      limitPrice: getLimitPriceFromMaxSlippage(
        !position.isLong,
        parseDecimalToBigInt('5.00', 2n),
        marketData,
        marketSpec,
      ).decimal,
      reduceOnly: true,
    },
  });

  const orderType = useWatch({
    control: form.control,
    name: 'orderType',
  });

  const limitPrice = useWatch({
    control: form.control,
    name: 'limitPrice',
  });

  const isMarketOrder = orderType === OrderType.MARKET;

  useEffect(() => {
    if (!isMarketOrder)
      form.setValue(
        `limitPrice`,
        bigIntToDecimalStr(marketData.markPrice, marketSpec.priceDecimals),
      );
  }, [isMarketOrder]);

  // handle size changes
  const orderSize = parseDecimalToBigInt(
    useWatch({ control: form.control, name: 'size' }),
    marketSpec.sizeDecimals,
  );

  const { execPrice, slippage, pnl, roePct } = useMemo(() => {
    if (orderSize === 0n) {
      return {
        execPrice: isMarketOrder
          ? bigIntToDecimalStr(marketData.markPrice, marketSpec.priceDecimals)
          : limitPrice,
        slippage: undefined,
        pnl: '0',
        roePct: '0',
      };
    }
    const execPrice = isMarketOrder
      ? getEstFillPrice(!position.isLong, orderSize, book, marketSpec)
      : {
          bigint: parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals),
          decimal: limitPrice,
        };

    const pnl = getRealizedPnl(
      symbol,
      !position.isLong,
      orderSize,
      isMarketOrder
        ? marketData.markPrice
        : parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals),
      0n,
      0n,
      account,
      marketSpec,
    );
    const initMargin = getPositionInitMargin(symbol, account, {
      [symbol]: { marketData, marketSpec },
    } as Record<string, MarketInfo>).bigint;

    const roePct = (pnl.bigint * 10n ** 6n) / initMargin / 10n ** 2n;

    if (execPrice === undefined) {
      return {
        execPrice: isMarketOrder
          ? bigIntToDecimalStr(marketData.markPrice, marketSpec.priceDecimals)
          : limitPrice,
        slippage: undefined,
        pnl: pnl.decimal,
        roePct: bigIntToDecimalStr(roePct, 2n),
      };
    }

    if (book.bids.length === 0 || book.asks.length === 0)
      return {
        execPrice: execPrice.decimal,
        pnl: pnl.decimal,
        roePct: bigIntToDecimalStr(roePct, 2n),
      };

    const slippage = getSlippage(!position.isLong, execPrice.bigint, book);
    return {
      execPrice: execPrice.decimal,
      slippage: slippage?.decimal,
      pnl: pnl.decimal,
      roePct: bigIntToDecimalStr(roePct, 2n),
    };
  }, [orderSize, book, marketData, marketSpec, limitPrice]);

  const [sizeUnit, setSizeUnit] = useState(COLLATERAL_SYMBOL);
  // const [baseSize, setBaseSize] = useState(position.size);

  const markets = {
    [symbol]: { marketData, marketSpec },
  };

  const [size, setSize] = useState(
    getPositionValue(symbol, account, markets).bigint,
  );

  useEffect(() => {
    let sizeInBase = size;
    if (sizeUnit === COLLATERAL_SYMBOL) {
      sizeInBase = getSizeFromNumeraire(
        size,
        marketData.markPrice,
        marketSpec,
        false,
      );
    }
    form.setValue(
      'size',
      bigIntToDecimalStr(sizeInBase, marketSpec.sizeDecimals),
    );
  }, [size, sizeUnit]);

  const { mutateAsync: createOrder } = useCreateOrder();

  function onSubmit(data: CloseFormValues) {
    const validatedOrderParams = {
      ...data,
      size: parseInputToDecimalStr(data.size, marketSpec.sizeDecimals),
      limitPrice: parseInputToDecimalStr(
        data.limitPrice,
        marketSpec.priceDecimals,
      ),
    };

    createOrder({ order: validatedOrderParams, showToast: true });
    onOpenChange(false);
    form.reset();
  }

  const isLimitPriceInvalid = useMemo(() => {
    if (orderType !== OrderType.LIMIT) return false;
    const price = parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals);
    if (
      (position.isLong && price <= marketData.markPrice) ||
      (!position.isLong && price >= marketData.markPrice)
    )
      return true;
    return false;
  }, [
    orderType,
    position.isLong,
    limitPrice,
    marketData.markPrice,
    marketSpec.priceDecimals,
  ]);

  const maxSize = useMemo(() => {
    switch (sizeUnit) {
      case COLLATERAL_SYMBOL:
        return getPositionValue(symbol, account, markets).bigint;
      case abbrFromSym(symbol):
        return position.size;
      default:
        return 0n;
    }
  }, [account.positions[symbol], marketData.markPrice, sizeUnit]);

  return (
    <DialogContent
      className="gap-0 border border-border bg-background p-0 sm:max-w-[425px] sm:rounded-none"
      onOpenAutoFocus={(e) => e.preventDefault()}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.error('Form validation errors:', errors);
          })}
        >
          <DialogHeader className="px-5 pt-6">
            <DialogTitle className="text-xl text-white">
              Close {symbolToBaseQuote(symbol)} Position
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col px-0 pb-2 pt-3">
            <Tabs
              defaultValue={OrderType.MARKET}
              className="w-full"
              value={form.watch(`orderType`)}
              onValueChange={(value) => {
                form.setValue(`orderType`, value as OrderType);
              }}
            >
              <TabsList>
                <TabsTrigger
                  value={OrderType.MARKET}
                  className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
                >
                  MARKET
                </TabsTrigger>
                <TabsTrigger
                  value={OrderType.LIMIT}
                  className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
                >
                  LIMIT
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <div className="flex flex-col p-2.5">
              <div className="flex h-10 items-center justify-between px-3">
                <Label
                  htmlFor="available-to-close"
                  className="text-left text-base text-vestgrey-100"
                >
                  Available to Close
                </Label>
                <div className="font-mono text-vestgrey-50">
                  {`${formatBigInt(position.size, marketSpec.sizeDecimals)} ${abbrFromSym(symbol)} (${formatBigInt(
                    position.size * marketData.markPrice,
                    marketSpec.sizeDecimals + marketSpec.priceDecimals,
                    { digits: 2 },
                  )})`}
                </div>
              </div>
              {isMarketOrder && (
                <div className="flex h-10 items-center justify-between px-3">
                  <Label
                    htmlFor="available-to-close"
                    className="text-left text-base text-vestgrey-100"
                  >
                    Close Price
                  </Label>
                  <div className="font-mono text-vestgrey-50">
                    {formatNumber(execPrice)}
                  </div>
                </div>
              )}
              {!isMarketOrder && (
                <PriceInput
                  symbol={symbol}
                  defaultValue={bigIntToDecimalStr(
                    marketData.markPrice,
                    marketSpec.priceDecimals,
                  )}
                  onPriceChange={(limitPrice) =>
                    form.setValue('limitPrice', limitPrice)
                  }
                />
              )}
              <div className="mt-2">
                <SizeInput
                  symbol={symbol}
                  maxSize={maxSize}
                  onSizeChange={(value) => setSize(value)}
                  onSizeUnitChange={setSizeUnit}
                  limitPrice={
                    isMarketOrder
                      ? 0n
                      : parseDecimalToBigInt(
                          limitPrice,
                          marketSpec.priceDecimals,
                        )
                  }
                />
              </div>
              <div className="mt-4 flex h-10 items-center justify-between px-3">
                <Label
                  htmlFor="available-to-close"
                  className="text-left text-base text-vestgrey-100"
                >
                  Slippage
                </Label>
                <div className="flex items-center gap-3">
                  {isMarketOrder ? (
                    <>
                      <div className="flex items-end gap-2">
                        <span className="text-sm leading-tight text-vestgrey-600">
                          Est:
                        </span>
                        <span
                          className={cn(
                            'text-font font-mono text-base leading-tight',
                            slippage && Number(Number(slippage).toFixed(2)) <= 0
                              ? 'text-green'
                              : null,
                          )}
                        >
                          {formatNumber(slippage, {
                            digits: 2,
                            style: 'slippage',
                          })}
                        </span>
                        <span className="px-1 leading-none text-vestgrey-600">
                          /
                        </span>
                        <span className="text-sm leading-tight text-vestgrey-600">
                          Max
                        </span>
                      </div>
                      <div className="flex items-center bg-vestgrey-800">
                        <MaxSlippageInput
                          symbol={symbol}
                          isBuy={!position.isLong}
                          onLimitPriceChange={(limitPrice) => {
                            form.setValue(
                              `limitPrice`,
                              bigIntToDecimalStr(
                                limitPrice,
                                marketSpec.priceDecimals,
                              ),
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <span className="px-1 leading-none text-vestgrey-600">
                      -
                    </span>
                  )}
                </div>
              </div>

              <div className="flex h-10 items-center justify-between px-3">
                <Label
                  htmlFor="available-to-close"
                  className="text-left text-base text-vestgrey-100"
                >
                  PNL
                </Label>
                <div className="flex items-center gap-4">
                  <div
                    className={cn(
                      'text-font flex font-mono text-base',
                      Number(pnl) < 0 ? 'text-red' : 'text-green',
                    )}
                  >
                    {formatNumber(pnl, { digits: 2, showChange: true })} /{' '}
                    {formatNumber(roePct, {
                      digits: 2,
                      style: 'percent',
                      abs: true,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className="mt-2">
            <Button
              type="submit"
              className="h-16 w-full rounded-none py-6 font-mono text-lg font-normal tracking-wider"
              disabled={
                orderSize === 0n ||
                orderSize > position.size ||
                isLimitPriceInvalid
              }
            >
              {isLimitPriceInvalid ? 'INVALID LIMIT PRICE' : 'PLACE ORDER'}
            </Button>
          </DialogFooter>
        </form>
      </FormProvider>
    </DialogContent>
  );
};
