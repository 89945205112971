import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { create, StoreApi } from 'zustand';
import { Account } from './use-account-store';
import { Trade } from '@/features/markets/api/get-trades';

export const COLLATERAL_SYMBOL = 'USDC';
export const COLLATERAL_DECIMALS = 6n; // TODO: maybe env var
export const FUNDING_RATE_DECIMALS = 8n;
export const CUM_FUNDING_DECIMALS = 12n;

export interface MarketData {
  markPrice: bigint;
  indexPrice: bigint;
  oneHrFundingRate: bigint;
  priceChange: bigint;
  priceChangePct: bigint;
  cumFunding: bigint;
  volume?: bigint;
  quoteVolume?: bigint;
  openInterest?: bigint;
}

export interface MarketSpec {
  displayName: string;
  base: string;
  quote: string;
  sizeDecimals: bigint;
  priceDecimals: bigint;
  initMarginRatio: bigint;
  maintMarginRatio: bigint;
  takerFee: bigint;
}

export interface OrderBook {
  bids: [bigint, bigint][]; // price, qty (sorted ascending)
  asks: [bigint, bigint][]; // price, qty (sorted descending)
}

export interface MarketStore {
  marketData: MarketData;
  marketSpec: MarketSpec;
  book: OrderBook;
  trades: Trade[];
  setTrades: (trades: Trade[]) => void;
  setMarketData: (marketData: MarketData) => void;
  setMarketSpec: (marketSpecs: MarketSpec) => void;
  setBook: (book: OrderBook) => void;
}

const marketStores: Record<string, StoreApi<MarketStore>> = {};

const _createMarketStore = () =>
  create<MarketStore>((set, get) => ({
    marketData: {
      markPrice: 0n,
      indexPrice: 0n,
      oneHrFundingRate: 0n,
      priceChange: 0n,
      priceChangePct: 0n,
      cumFunding: 0n,
    },
    marketSpec: {
      displayName: '',
      base: '',
      quote: '',
      sizeDecimals: 0n,
      priceDecimals: 0n,
      initMarginRatio: 0n,
      maintMarginRatio: 0n,
      takerFee: 0n,
    },
    book: { bids: [], asks: [] },
    trades: [],
    setTrades: (trades: Trade[]) => set({ trades }),
    setMarketData: (marketData: MarketData) => set({ marketData }),
    setMarketSpec: (marketSpec: MarketSpec) => set({ marketSpec }),
    setBook: (book: OrderBook) => set({ book }),
  }));

export const useMarketStore = (symbol: string) => {
  if (!marketStores[symbol]) {
    marketStores[symbol] = _createMarketStore();
  }
  return marketStores[symbol];
};

export const createMarketStore = (symbol: string) => {
  if (!marketStores[symbol]) {
    marketStores[symbol] = _createMarketStore();
  }
};

export const getAllSymbols = () => {
  return Object.keys(marketStores);
};

export function useMarketStores<T>(
  selector: (state: MarketStore) => T,
  symbols?: string[], // DEPRECATED // WARNING: if symbols changes, the stores might not update before you access a new symbol
) {
  const symbolsToSub = useMemo(() => symbols || getAllSymbols(), [symbols]);

  const [marketStoreSlices, setMarketStoreSlices] = useState<Record<string, T>>(
    (symbols || getAllSymbols()).reduce(
      (acc, symbol) => {
        acc[symbol] = selector(useMarketStore(symbol).getState());
        return acc;
      },
      {} as Record<string, T>,
    ),
  );
  const storeSubs = useRef<Record<string, () => void>>({});

  const updateMarketStoreSlice = useCallback(
    (symbol: string) => {
      const store = useMarketStore(symbol);
      const slice = selector(store.getState());
      setMarketStoreSlices((prev) => ({
        ...prev,
        [symbol]: slice,
      }));
    },
    [selector],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const debouncedUpdate = () => {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          symbolsToSub.forEach(updateMarketStoreSlice);
          timeoutId = null;
        }, 125);
      }
    };

    symbolsToSub.forEach((symbol) => {
      const store = useMarketStore(symbol);
      storeSubs.current[symbol] = store.subscribe(debouncedUpdate);
    });

    return () => {
      Object.values(storeSubs.current).forEach((unsubscribe) => unsubscribe());
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [symbolsToSub]);

  // const isReady = useMemo(() => {
  //   const marketSymbols = new Set(Object.keys(marketStoreSlices));
  //   const symbols = new Set(symbolsToSub);
  //   return symbols.symmetricDifference(marketSymbols).size === 0;
  // }, [symbolsToSub, Object.keys(marketStoreSlices)]);

  return marketStoreSlices;
}

export const getAccountMarketStores = <T>(
  account: Account,
  marketStoresSlices: Record<string, T>,
) => {
  const accountMarkets = Object.keys(marketStoresSlices)
    .filter((symbol) => symbol in account.positions)
    .reduce(
      (acc, symbol) => {
        acc[symbol] = marketStoresSlices[symbol];
        return acc;
      },
      {} as Record<string, T>,
    );
  return accountMarkets;
};

export const getAllMarketStoreStates = () => {
  return Object.keys(marketStores).reduce(
    (acc, symbol) => {
      acc[symbol] = useMarketStore(symbol).getState();
      return acc;
    },
    {} as Record<string, MarketStore>,
  );
};
