import { QueryConfig } from '@/lib/api-clients/react-query';
import { publicApi } from '@/lib/api-clients/rest-client';
import { useMarketStore } from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { queryOptions, useQuery } from '@tanstack/react-query';

type OIsResponse = {
  ois: OIRecord[];
};

export type OIRecord = {
  symbol: string;
  shortOi: string;
  longOi: string;
};

async function getOpenInterest(symbols?: string[]): Promise<OIRecord[]> {
  return publicApi
    .get('/oi', { params: symbols ? { symbols: symbols.join(',') } : {} })
    .then((res: OIsResponse) => {
      res.ois.forEach((ticker: OIRecord) => {
        const { setMarketData, marketData, marketSpec } = useMarketStore(
          ticker.symbol,
        ).getState();
        const longCoinsSize = parseDecimalToBigInt(
          ticker.longOi,
          marketSpec.sizeDecimals,
        );
        const shortCoinsSize = parseDecimalToBigInt(
          ticker.shortOi,
          marketSpec.sizeDecimals,
        );
        setMarketData({
          ...marketData,
          openInterest: (longCoinsSize + shortCoinsSize) * marketData.markPrice,
        });
      });
      return res.ois;
    });
}

const getOpenInterestQueryOptions = ({
  symbols,
}: { symbols?: string[] } = {}) => {
  return queryOptions({
    queryKey: symbols ? ['open-interest', { symbols }] : ['open-interest'],
    queryFn: () => getOpenInterest(symbols),
  });
};

type UseOpenInterestOptions = {
  symbols?: string[];
  queryConfig?: QueryConfig<typeof getOpenInterest>;
};

export default function useOpenInterest({
  symbols,
  queryConfig,
}: UseOpenInterestOptions) {
  return useQuery({
    ...getOpenInterestQueryOptions({ symbols }),
    // refetchInterval: 2000,
    ...queryConfig,
  });
}
