import { privateApi } from '@/lib/api-clients/rest-client';
import { useQuery } from '@tanstack/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { useUnifiedAccount } from '@/hooks';

export type Trader = {
  trader: string;
  date_joined: string;
  volume_traded: number;
  fees_paid: number;
  rewards_earned: number;
};

export type Referrals = {
  ref_code: string;
  traders_referred: number;
  volume_traded: number;
  rewards_earned: number | null;
  has_twitter_access: boolean;
  traders: Trader[];
  proof: {
    index: number;
    account: string;
    amount: string;
    proof: string[];
  };
  rebate_amount: string;
};

async function getReferrals(): Promise<Referrals> {
  return privateApi.get('/referrals');
}

const useReferrals = () => {
  const { address } = useUnifiedAccount();
  const { hasAuth, authParams } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    authParams: state.getAuthParamsByAddress(address),
  }));

  return useQuery({
    enabled: hasAuth,
    queryKey: ['referrals', authParams?.signingKey],
    queryFn: getReferrals,
  });
};

export default useReferrals;
