import { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { cn } from '@/utils/cn';

interface UnitSelectProps {
  defaultValue?: string;
  units: string[];
  onSelect: (value: string) => void;
  disabled?: boolean;
  className?: string;
  fitContent?: boolean;
}

export const UnitSelect: React.FC<UnitSelectProps> = ({
  defaultValue,
  units,
  onSelect,
  disabled = false,
  className,
  fitContent = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue || units[0]);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!units.includes(selected)) {
      handleSelect(units[1]); // TEMP fix to base size unit not changing when symbol changes
    }
  }, [units, selected]);

  return (
    <div className={cn('relative', className)}>
      <button
        className={`flex h-10 w-full items-center justify-between gap-2 bg-vestgrey-800 p-2 text-center font-mono ${
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        }`}
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.preventDefault();
        }}
        disabled={disabled}
      >
        <div className="relative flex items-center justify-end">
          {!fitContent && units.length > 0 && (
            <p className="opacity-0">
              {units.reduce(
                (prev, curr) => (curr.length > prev.length ? curr : prev),
                units[0],
              )}
            </p>
          )}
          <span className={cn('text-vestgrey-50', !fitContent && 'absolute')}>
            {selected}
          </span>
        </div>
        <ChevronDownIcon
          className={cn('text-vestgrey-200 transition-transform duration-300', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div className="absolute left-auto right-0 top-full z-10 w-min overflow-hidden border border-border bg-vestgrey-900 shadow-lg">
          {units.map((unit) => (
            <button
              type="button"
              className="w-full cursor-pointer border-b border-border px-4 py-2 font-mono uppercase text-foreground last:border-b-0 hover:bg-vestgrey-800"
              onClick={() => handleSelect(unit)}
              key={unit}
            >
              {unit}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
