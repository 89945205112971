import { useState, useEffect } from 'react';

// ✅ Bottom Border Progress Bar Component
export const ProgressBar = ({ limit }: { limit: number }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 100 / (limit / 50); // Adjust increment based on time limit
      });
    }, 50);

    return () => clearInterval(interval);
  }, [limit]);

  return (
    <div className="absolute -bottom-0 left-0 h-1 w-full rounded-full">
      <div
        className="h-full rounded-full bg-primary transition-all duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};
