export default function GeofenceBanner() {
  return (
    <div className="flex items-center bg-brown px-8 py-2.5">
      <p className="text-lg tracking-wider text-primary">
        <strong>
          You are accessing our service from a restricted jurisdiction.
        </strong>{' '}
        We do not allow access to our service from the United States of America
        or any other restricted jurisdiction. If you believe this is an error,
        please contact us.
      </p>
    </div>
  );
}
