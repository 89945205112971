import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import getDatafeed from '@/lib/tv/datafeed';
import getTimezoneId from '@/utils/get-timezones';
import {
  widget as Widget,
  IChartingLibraryWidget,
  ResolutionString,
  IPositionLineAdapter,
  IOrderLineAdapter,
  LineToolsAndGroupsState,
  // EntityId,
  // IChartWidgetApi,
  // } from '@/lib/tv/charting_library';
} from '../../../../public/charting_library';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { Position, useAccountStore } from '@/store/use-account-store';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatNumber,
} from '@/utils/value-format';
import { useMarketStores } from '@/store/use-markets-store';
import {
  getPositionLiqPrice,
  getPositionPnl,
} from '@/features/account/utils/math';
import { NewOrder, Order, useOrdersStore } from '@/store/use-orders-store';
import { useShallow } from 'zustand/react/shallow';
import { OrderType } from '@/types/enums';
import { useWebSocketStore } from '@/store/use-websocket-store';
import { useOrders } from '@/features/account/api/get-orders';
import { useWindowWidth } from '@/hooks';

const mobileDisabledFeatures = [
  'left_toolbar',
  'control_bar',
  'header_widget',
  'legend_widget',
  'main_series_scale_menu',
];

interface ITradingView {
  symbol: string;
}

const stringifyLineTools = (state: LineToolsAndGroupsState) =>
  JSON.stringify({
    ...state,
    sources: state.sources ? Array.from(state.sources.entries()) : [],
    groups: Array.from(state.groups.entries()),
  });

export default function TradingView({ symbol }: ITradingView) {
  const account = useAccountStore((state) => state.account);
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});

  useOrders();

  const positionLineRef = useRef<IPositionLineAdapter>();
  const liquidationLineRef = useRef<IPositionLineAdapter>();
  const orderLines = useRef<Record<string, IOrderLineAdapter>>({});

  const [interval, setInterval] = useState(
    localStorage.getItem('tvInterval') || '1',
  );
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget | null>(null);
  const isFirstRender = useRef(true);

  const { isMobile, isTablet } = useWindowWidth();

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  const orders = useOrdersStore((state) =>
    Array.from(state.newOrderIds, (id) => state.orders[id]).filter(
      (order) =>
        order.symbol === symbol &&
        (order.orderType === OrderType.LIMIT ||
          order.orderType === OrderType.TAKE_PROFIT ||
          order.orderType === OrderType.STOP_LOSS),
    ),
  );

  const hasAuth = useAccountStore((state) => state.hasAuth);

  // const orders = useMemo(
  //   () => ordersStore.map((order) => order),
  //   [ordersStore],
  // );

  const { priceDecimals, sizeDecimals } = markets[symbol].marketSpec;

  useEffect(() => {
    if (
      isExchangeInfoPending ||
      isExchangeInfoError ||
      !publicWs ||
      !publicWs.isReady()
    )
      return;

    const datafeed = getDatafeed(publicWs);
    const nowSecs = Date.now() / 1000;
    const intervalMins = parseInt(interval);
    const widget = new Widget({
      container: document.getElementById('chart-container'),
      datafeed,
      library_path: '/charting_library/',
      debug: false,
      fullscreen: false,
      symbol,
      interval,
      timeframe: { from: nowSecs - 120 * intervalMins * 60, to: nowSecs }, // have 120 bars on screen
      theme: 'dark',
      allow_symbol_change: false,
      timezone: getTimezoneId(),
      autosize: true,
      custom_css_url: '/tradingview.css',
      // toolbar_bg: isMobile ? '#0F0E0C' : '#1A1A1A',
      toolbar_bg: '#0F0F0F',
      loading_screen: {
        // backgroundColor: isMobile ? '#0F0E0C' : '#1A1A1A',
        backgroundColor: '#0F0F0F',
        foregroundColor: '#FF7456',
      },
      overrides: {
        'mainSeriesProperties.candleStyle.upColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.downColor': '#E03737',
        'mainSeriesProperties.candleStyle.borderUpColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.borderDownColor': '#E03737',
        'mainSeriesProperties.candleStyle.wickUpColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.wickDownColor': '#E03737',
        'paneProperties.backgroundType': 'solid',
        // 'paneProperties.background': isMobile ? '#0F0E0C' : '#1A1A1A',
        'paneProperties.background': '#0F0F0F',
        'paneProperties.vertGridProperties.color':
          isMobile || isTablet ? 'transparent' : '#222127',
        'paneProperties.horzGridProperties.color':
          isMobile || isTablet ? 'transparent' : '#222127',
        'scalesProperties.lineColor':
          isMobile || isTablet ? '#0F0E0C' : '#AEADAD',
      },
      disabled_features: [
        'header_symbol_search',
        'use_localstorage_for_settings',
        'timeframes_toolbar',
        'header_undo_redo',
        ...(isMobile || isTablet ? mobileDisabledFeatures : []),
      ],
      enabled_features: ['saveload_separate_drawings_storage'],
      // save_load_adapter: new LocalStorageDrawingsPerSymbolSaveLoadAdapter(),
    } as any);

    widget.onChartReady(() => {
      const chart = widget.chart();

      chart.onIntervalChanged().subscribe(null, (interval: string) => {
        localStorage.setItem('tvInterval', interval);
        setInterval(interval);
      });

      chart.onDataLoaded().subscribe(null, () => chart.refreshMarks(), false);

      if (!chart.dataReady()) {
        if (isMobile || isTablet) {
          widget.remove();
        }
      }

      setTvWidget(widget);
    });
  }, [isExchangeInfoPending, isExchangeInfoError, isUpdated]);

  const clearPositionLine = () => {
    positionLineRef.current?.remove();
    positionLineRef.current = undefined;
    liquidationLineRef.current?.remove();
    liquidationLineRef.current = undefined;
  };

  // const initPositionLine = (widget: IChartingLibraryWidget) => {
  const initPositionLine = (position: Position) => {
    if (!tvWidget) return;
    const chart = tvWidget.chart();
    clearPositionLine();
    if (!position || !chart.dataReady()) return;
    const pnl = getPositionPnl(symbol, account, markets);
    const profitable = pnl.bigint >= 0;
    positionLineRef.current = chart
      .createPositionLine()
      .setText(
        `PNL ${formatNumber(pnl.decimal, { showChange: true, digits: 2 })}`,
      )
      .setProtectTooltip('Protect position')
      .setCloseTooltip('Close position')
      .setReverseTooltip('Reverse position!')
      .setQuantity(bigIntToDecimalStr(position.size, sizeDecimals))
      .setPrice(bigIntToNum(position.cost / position.size, priceDecimals))
      .setLineColor(profitable ? '#56f5be' : '#f32b46')
      .setBodyBackgroundColor(profitable ? '#56f5be' : '#f32b46')
      .setBodyBorderColor(profitable ? '#56f5be' : '#f32b46')
      .setQuantityBackgroundColor(profitable ? '#56f5be' : '#f32b46')
      .setQuantityBorderColor(profitable ? '#56f5be' : '#f32b46')
      .setBodyTextColor(profitable ? '#000' : '#fff')
      .setQuantityTextColor(profitable ? '#000' : '#fff')
      .setLineStyle(3)
      .setLineLength(20, 'percentage');
    liquidationLineRef.current = chart
      .createPositionLine()
      .setText('Liq. Price')
      .setPrice(
        parseFloat(getPositionLiqPrice(symbol, account, markets).decimal),
      )
      .setQuantity('')
      .setLineColor('#f32b46')
      .setBodyBackgroundColor('#f32b46')
      .setBodyBorderColor('#f32b46')
      .setQuantityBackgroundColor('#f32b46')
      .setQuantityBorderColor('#f32b46')
      .setBodyTextColor('#fff')
      .setQuantityTextColor('#fff')
      .setLineStyle(3)
      .setLineLength(20, 'percentage');
  };

  useEffect(() => {
    if (!tvWidget) return;
    const onDraw = (_id: string, type: string) => {
      try {
        const chart = tvWidget.chart();
        if (!chart.dataReady() || type === 'properties_changed') return;
        const drawingsState = chart.getLineToolsState();
        localStorage.setItem(
          `tv-chart-drawings-${chart.symbol()}`,
          stringifyLineTools(drawingsState),
        );
      } catch (err) {
        console.warn("Couldn't save drawing", err);
      }
    };

    // if (!isFirstRender.current) {
    //   setChartDataLoaded(false);
    // }
    try {
      const chart = tvWidget.chart();

      chart.clearMarks();

      if (isFirstRender.current) {
        chart.dataReady(() => {
          initPositionLine(account.positions[symbol]);
          orders.forEach(initOrderLine);
        });
      } else {
        clearPositionLine();
        Object.values(orderLines.current).forEach((line) => line.remove());
        orderLines.current = {};
      }

      isFirstRender.current = false;

      tvWidget.setSymbol(symbol, interval as ResolutionString, () => {
        initPositionLine(account.positions[symbol]);
        orders.forEach(initOrderLine);
      });

      chart.dataReady(() => {
        const tvChartDrawings = localStorage.getItem(
          `tv-chart-drawings-${symbol}`,
        );
        if (tvChartDrawings) {
          const state = JSON.parse(tvChartDrawings);
          chart.applyLineToolsState({
            ...state,
            sources: new Map(state.sources),
            groups: new Map(state.groups),
          });
        }
        tvWidget.subscribe('drawing_event', onDraw);
      });

      // chart.onDataLoaded().subscribe(null, () => {

      // });
    } catch (error) {
      console.error(error);
    }
    return () => {
      try {
        tvWidget && tvWidget.unsubscribe('drawing_event', onDraw);
      } catch (err) {
        console.warn("Couldn't unmount", err);
      }
    };
  }, [symbol, tvWidget]);

  useEffect(() => {
    if (!tvWidget || !hasAuth) return;
    const unsubscribe = useAccountStore.subscribe((state, prevState) => {
      const currentPosition = state.account.positions[symbol];
      const prevPosition = prevState.account.positions[symbol];
      if (currentPosition && !prevPosition) {
        initPositionLine(currentPosition);
      } else if (!currentPosition && prevPosition) {
        clearPositionLine();
      } else if (
        currentPosition &&
        prevPosition &&
        currentPosition.size !== prevPosition.size
      ) {
        clearPositionLine();
        initPositionLine(currentPosition);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [tvWidget, hasAuth, symbol]);

  const initOrderLine = (order: Order) => {
    if (!tvWidget) return;
    const chart = tvWidget.chart();
    if (!chart.dataReady()) return;
    const priceStr = formatNumber(
      bigIntToDecimalStr(order.limitPrice, priceDecimals),
      { digits: 2 },
    );
    const isPositive =
      order.orderType === OrderType.LIMIT
        ? order.isBuy
        : order.orderType === OrderType.TAKE_PROFIT;
    orderLines.current[order.id] = chart
      .createOrderLine()
      .setText(
        order.orderType === OrderType.LIMIT
          ? `LIMIT ${order.isBuy ? 'BUY' : 'SELL'}: ${priceStr}`
          : `${order.orderType === OrderType.TAKE_PROFIT ? 'TP' : 'SL'}: ${priceStr}`,
      )
      .setQuantity(bigIntToDecimalStr(order.size, sizeDecimals))
      .setPrice(bigIntToNum(order.limitPrice, priceDecimals))
      .setLineColor(isPositive ? '#56f5be' : '#f32b46')
      .setBodyBackgroundColor(isPositive ? '#56f5be' : '#f32b46')
      .setBodyBorderColor(isPositive ? '#56f5be' : '#f32b46')
      .setQuantityBackgroundColor(isPositive ? '#56f5be' : '#f32b46')
      .setQuantityBorderColor(isPositive ? '#56f5be' : '#f32b46')
      .setBodyTextColor(isPositive ? '#000' : '#fff')
      .setQuantityTextColor(isPositive ? '#000' : '#fff')
      .setLineStyle(3)
      .setLineLength(20, 'percentage');
  };

  useEffect(() => {
    if (!tvWidget || !hasAuth) return;

    const unsubscribe = useOrdersStore.subscribe((state, prevState) => {
      const {
        filledOrderIds: currentFilledOrderIds,
        newOrderIds: currentNewOrderIds,
        orders,
      } = state;
      const {
        filledOrderIds: previousFilledOrderIds,
        newOrderIds: prevNewOrderIds,
      } = prevState;
      const hasNewFilledOrder = Array.from(currentFilledOrderIds)
        .filter((id) => orders[id].symbol === symbol)
        .some((id) => !previousFilledOrderIds.has(id));

      if (hasNewFilledOrder) {
        try {
          const chart = tvWidget.chart();
          chart.refreshMarks();
        } catch (err) {
          console.warn("Couldn't refresh marks: ", err);
        }
      }

      const newOrders = Array.from(
        currentNewOrderIds,
        (id) => orders[id],
      ).filter(({ orderType }) =>
        [OrderType.LIMIT, OrderType.TAKE_PROFIT, OrderType.STOP_LOSS].includes(
          orderType,
        ),
      );

      const newOrder = newOrders.find(
        (order) => !prevNewOrderIds.has(order.id),
      );

      if (!!newOrder) {
        initOrderLine(newOrder);
      }

      const oldLineId = Object.keys(orderLines.current).find(
        (id) => !currentNewOrderIds.has(id),
      );

      if (!!oldLineId) {
        orderLines.current[oldLineId].remove();
        delete orderLines.current[oldLineId];
      }
    });

    return () => unsubscribe();
  }, [tvWidget, hasAuth, symbol]);

  useEffect(() => {
    if (!tvWidget) return;
    // refresh marks based on the auth state
    try {
      const chart = tvWidget.chart();
      if (hasAuth) {
        chart.refreshMarks();
        if (!positionLineRef.current && account.positions[symbol]) {
          initPositionLine(account.positions[symbol]);
        }
        // initPositionLine(account.positions[symbol]);
      } else {
        chart.clearMarks();
        clearPositionLine();
        Object.values(orderLines.current).forEach((line) => line.remove());
        orderLines.current = {};
      }
    } catch (err) {
      console.warn("Couldn't refresh marks: ", err);
    }
  }, [tvWidget, hasAuth]);

  useEffect(() => {
    if (!tvWidget || !hasAuth || !positionLineRef.current) return;
    try {
      const pnl = getPositionPnl(symbol, account, markets);
      const profitable = pnl.bigint >= 0;
      positionLineRef.current
        .setText(
          `PNL ${formatNumber(pnl.decimal, { showChange: true, digits: 2 })}`,
        )
        .setLineColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyBackgroundColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyBorderColor(profitable ? '#56f5be' : '#f32b46')
        .setQuantityBackgroundColor(profitable ? '#56f5be' : '#f32b46')
        .setQuantityBorderColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyTextColor(profitable ? '#000' : '#fff')
        .setQuantityTextColor(profitable ? '#000' : '#fff');
      liquidationLineRef.current?.setPrice(
        parseFloat(getPositionLiqPrice(symbol, account, markets).decimal),
      );
    } catch (err) {
      console.warn("Couldn't modify the position line text - widget unmounted");
    }
  }, [tvWidget, hasAuth, markets[symbol].marketData.markPrice]);

  return (
    <div className="h-full min-h-[400px] flex-1">
      <div id="chart-container" className="h-full w-full" />
    </div>
  );
}
