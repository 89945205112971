import { ArrowLeftRight, Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Table } from '@/components/ui/';
import { createColumnHelper } from '@tanstack/react-table';
import { PositionRow } from './position-row';
import { useMemo, useState } from 'react';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '../api/get-account';
import {
  abbrFromSym,
  imgFromAbbr,
  symbolToBaseQuote,
} from '@/utils/token-symbol';
import { cn } from '@/utils/cn';
import { useOrders } from '../api/get-orders';
import {
  bigIntToDecimalStr,
  formatBigInt,
  formatDate,
  formatNumber,
  formatOrderStatus,
  formatOrderType,
} from '@/utils/value-format';
import { Order, useOrdersStore } from '@/store/use-orders-store';
import {
  ErrorCode,
  ErrorMsgs,
  OrderHistoryFilterType,
  OrderStatus,
  OrderType,
} from '@/types/enums';
import {
  COLLATERAL_DECIMALS,
  getAllMarketStoreStates,
  MarketSpec,
} from '@/store/use-markets-store';
import { useShallow } from 'zustand/react/shallow';
import { getOrderValueFromPrice } from '@/features/trade/order/utils/math';
import { Link } from 'react-router-dom';
import Share from '@/features/trade/components/share';
import { getOrderEntryPrice } from '../utils/math';

type OrderHistory =
  | {
      id: string;
      status: OrderStatus.REJECTED | OrderStatus.CANCELLED;
      orderType: OrderType;
      symbol: string;
      side: boolean;
      size: string;
      value: string;
      limitPrice: string;
      time: number;
      code?: ErrorCode;
      settledFunding: undefined;
      leverage: undefined;
      entryPrice: undefined;
      fees: undefined;
      lastFilledPrice: undefined;
      lastFilledSize: undefined;
      realizedPnl: undefined;
      roePct: undefined;
    }
  | {
      id: string;
      status: OrderStatus.FILLED;
      orderType: OrderType;
      symbol: string;
      side: boolean;
      size: string;
      limitPrice: string;
      lastFilledSize: string;
      lastFilledPrice: string;
      settledFunding: bigint;
      fees: bigint;
      realizedPnl: bigint;
      time: number;
      leverage: string;
      roePct: bigint;
      entryPrice: string;
      code: undefined;
    };

const columnHelper = createColumnHelper<OrderHistory>();

const filterFn = (filter: OrderHistoryFilterType, order: Order) => {
  if (filter === OrderHistoryFilterType.ALL) {
    return true;
  } else if (filter === OrderHistoryFilterType.FILLED) {
    return order.status === OrderStatus.FILLED;
  } else if (filter === OrderHistoryFilterType.REJECTED) {
    return order.status === OrderStatus.REJECTED;
  } else if (filter === OrderHistoryFilterType.CANCELLED) {
    return order.status === OrderStatus.CANCELLED;
  }
};

export const OrdersHistoryTableContainer = ({
  filter,
}: {
  filter: OrderHistoryFilterType;
}) => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});
  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount({});

  useOrders();

  if (isExchangeInfoPending) {
    return <></>;
  }

  if (isExchangeInfoError) {
    return <div>Error</div>;
  }

  return <OrdersHistoryTable filter={filter} />;
};

const OrdersHistoryTable = ({ filter }: { filter: OrderHistoryFilterType }) => {
  const [valueActive, setValueActive] = useState(false);
  const ordersHistory = useOrdersStore(
    useShallow((state) =>
      Array.from(
        state.filledOrderIds
          .union(state.rejectedOrderIds)
          .union(state.cancelledOrderIds),
        (id) => state.orders[id as string],
      ).filter(
        (order) =>
          order !== undefined &&
          (order.status === OrderStatus.FILLED || // TEMP
            order.status === OrderStatus.REJECTED ||
            order.status === OrderStatus.CANCELLED),
      ),
    ),
  );

  const marketSpecs = useMemo(() => {
    const marketStores = getAllMarketStoreStates();
    return Object.entries(marketStores).reduce(
      (acc, [symbol, store]) => {
        acc[symbol] = store.marketSpec;
        return acc;
      },
      {} as Record<string, MarketSpec>,
    );
  }, []);

  const data: OrderHistory[] = useMemo(() => {
    return ordersHistory
      .filter(
        (order) =>
          marketSpecs[order.symbol] !== undefined && filterFn(filter, order),
      )
      .map((order) => {
        const marketSpec = marketSpecs[order.symbol];
        switch (order.status) {
          case OrderStatus.FILLED:
            const entryPrice = getOrderEntryPrice(order);
            const roePct =
              ((order.realizedPnl * 10n ** (COLLATERAL_DECIMALS + 4n)) /
                (order.initMarginRatio * order.size * entryPrice)) *
              100n;
            return {
              id: order.id,
              orderType: order.orderType,
              symbol: order.symbol,
              side: order.isBuy,
              entryPrice: bigIntToDecimalStr(
                entryPrice,
                marketSpec.priceDecimals,
              ),
              roePct,
              size: `${bigIntToDecimalStr(
                order.size,
                marketSpec.sizeDecimals,
              )} ${abbrFromSym(order.symbol)}`,
              value:
                formatNumber(
                  getOrderValueFromPrice(
                    order.lastFilledSize,
                    order.lastFilledPrice,
                    marketSpec,
                  )?.decimal,
                  { digits: 2, round: 'ceil' },
                ) ?? '0',
              limitPrice: bigIntToDecimalStr(
                order.limitPrice,
                marketSpec.priceDecimals,
              ),
              lastFilledSize: bigIntToDecimalStr(
                order.lastFilledSize,
                marketSpec.sizeDecimals,
              ),
              lastFilledPrice: bigIntToDecimalStr(
                order.lastFilledPrice,
                marketSpec.priceDecimals,
              ),
              settledFunding: order.settledFunding,
              fees: order.fees,
              realizedPnl: order.realizedPnl,
              leverage: formatBigInt(10n ** 6n / order.initMarginRatio, 2n, {
                digits: 0,
                round: 'ceil',
              }),
              status: order.status,
              time: order.lastFilledTime,
            };
          case OrderStatus.REJECTED:
            return {
              id: order.id,
              orderType: order.orderType,
              symbol: order.symbol,
              side: order.isBuy,
              size: `${bigIntToDecimalStr(
                order.size,
                marketSpec.sizeDecimals,
              )} ${abbrFromSym(order.symbol)}`,
              value:
                formatNumber(
                  getOrderValueFromPrice(
                    order.size,
                    order.limitPrice,
                    marketSpec,
                  )?.decimal,
                  { digits: 2, round: 'ceil' },
                ) ?? '0',
              limitPrice: bigIntToDecimalStr(
                order.limitPrice,
                marketSpec.priceDecimals,
              ),
              status: order.status,
              time: order.postTime,
              code: order.code,
            };
          case OrderStatus.CANCELLED:
            return {
              id: order.id,
              orderType: order.orderType,
              symbol: order.symbol,
              side: order.isBuy,
              size: `${bigIntToDecimalStr(
                order.size,
                marketSpec.sizeDecimals,
              )} ${abbrFromSym(order.symbol)}`,
              value:
                formatNumber(
                  getOrderValueFromPrice(
                    order.size,
                    order.limitPrice,
                    marketSpec,
                  )?.decimal,
                  { digits: 2, round: 'ceil' },
                ) ?? '0',
              limitPrice: bigIntToDecimalStr(
                order.limitPrice,
                marketSpec.priceDecimals,
              ),
              status: order.status,
              time: order.postTime,
            };
        }
      })
      .sort((a, b) => (b?.time ?? 0) - (a?.time ?? 0));
  }, [ordersHistory, marketSpecs, filter]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="text-vestgrey-100">
                  <Info size={14} />
                </TooltipTrigger>
                <TooltipContent className="break-all">
                  {info.getValue()}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        header: () => 'ID',
        meta: { width: 'w-[5%]' },
      }),
      columnHelper.accessor('time', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {formatDate(new Date(info.getValue()).getTime(), {
              showTime: true,
              showSeconds: true,
            })}
          </div>
        ),
        header: () => 'TIME',
        meta: { width: 'w-[15%]' },
      }),
      columnHelper.accessor('orderType', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {formatOrderType(info.getValue())}
          </div>
        ),
        header: () => 'TYPE',
        meta: { width: 'w-[7.5%]' },
      }),
      columnHelper.accessor('symbol', {
        cell: (info) => (
          <Link
            to={`/trade/${info.getValue()}`}
            className="flex items-center gap-4 font-sans"
          >
            <img
              src={imgFromAbbr(abbrFromSym(info.getValue()))}
              title={info.getValue()}
              className="h-5 w-5"
            />
            {symbolToBaseQuote(info.getValue())}
          </Link>
        ),
        header: () => 'MARKET',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('side', {
        cell: (info) => (
          <p className={cn(info.getValue() ? 'text-green' : 'text-red')}>
            {info.getValue() ? 'Long' : 'Short'}
          </p>
        ),
        header: () => 'SIDE',
        meta: { width: 'w-[7.5%]' },
      }),
      columnHelper.accessor(
        (valueActive ? 'value' : 'size') as keyof OrderHistory,
        {
          cell: (info) => info.getValue(),
          header: () => (
            <div className="flex items-center gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setValueActive((prev) => !prev);
                }}
              >
                <ArrowLeftRight size={14} />
              </button>
              <span className="uppercase">
                {valueActive ? 'Value' : 'Size'}
              </span>
            </div>
          ),
          meta: { width: 'w-[10%]' },
        },
      ),
      columnHelper.accessor('limitPrice', {
        cell: (info) =>
          info.row.original.orderType === OrderType.MARKET ||
          info.row.original.orderType === OrderType.LIQUIDATION
            ? '-'
            : formatNumber(info.getValue()),
        header: () => 'LIMIT PRICE',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('lastFilledPrice', {
        cell: (info) => formatNumber(info.getValue()),
        header: () => 'FILL PRICE',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('settledFunding', {
        cell: ({ row }) => (
          <p
            className={cn(
              row.original.settledFunding
                ? row.original.settledFunding < 0n
                  ? 'text-red'
                  : 'text-green'
                : 'text-vestgrey-100',
            )}
          >
            {row.original.status === OrderStatus.FILLED
              ? formatNumber(
                  bigIntToDecimalStr(
                    row.original.settledFunding,
                    COLLATERAL_DECIMALS,
                  ),
                  { digits: 2, showChange: true },
                )
              : '-'}
          </p>
        ),
        header: () => 'FUNDING',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('fees', {
        cell: ({ row }) => (
          <p
            className={cn(
              row.original.fees ? 'text-vestgrey' : 'text-vestgrey-100',
            )}
          >
            {row.original.status === OrderStatus.FILLED
              ? formatNumber(
                  bigIntToDecimalStr(row.original.fees, COLLATERAL_DECIMALS),
                  { digits: 2, round: 'ceil' },
                )
              : '-'}
          </p>
        ),
        header: () => 'FEE',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('realizedPnl', {
        cell: ({ row }) => {
          const {
            realizedPnl,
            roePct,
            status,
            symbol,
            lastFilledPrice,
            entryPrice,
            leverage,
            fees,
            side,
            settledFunding,
          } = row.original;
          return (
            <p
              className={cn(
                realizedPnl
                  ? realizedPnl < 0n
                    ? 'text-red'
                    : 'text-green'
                  : 'text-vestgrey-100',
              )}
            >
              {status === OrderStatus.FILLED
                ? formatNumber(
                    bigIntToDecimalStr(realizedPnl, COLLATERAL_DECIMALS),
                    { digits: 2, showChange: true },
                  )
                : '-'}
              {status === OrderStatus.FILLED &&
                realizedPnl !== settledFunding - fees && (
                  <Share
                    symbol={symbol}
                    roePct={bigIntToDecimalStr(roePct, COLLATERAL_DECIMALS)}
                    leverage={leverage}
                    isLong={!side}
                    pnl={bigIntToDecimalStr(realizedPnl, COLLATERAL_DECIMALS)}
                    entryPrice={entryPrice}
                    markPrice={lastFilledPrice}
                    isFilled
                  />
                )}
            </p>
          );
        },
        header: () => 'PNL',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('status', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {info.getValue() ? formatOrderStatus(info.getValue()) : '-'}
            {info.getValue() === OrderStatus.REJECTED &&
              info.row.original.code && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <Info size={14} />
                    </TooltipTrigger>
                    <TooltipContent>
                      {ErrorMsgs[info.row.original.code]}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
          </div>
        ),
        header: () => 'STATUS',
        meta: { width: 'w-[10%]' },
      }),
    ],
    [valueActive],
  );

  return (
    // @ts-ignore
    <Table data={data} columns={columns} RowComponent={PositionRow} />
  );
};
