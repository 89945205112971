import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import OrderBook from './order-book';
import Trades from './trades';
import { useEffect, useState } from 'react';
import { abbrFromSym } from '@/utils/token-symbol';
import { useParams } from 'react-router-dom';
import { UnitSelect } from '@/features/trade/order/components/unit-select';
import { COLLATERAL_SYMBOL } from '@/store/use-markets-store';
import { useTrades } from '../api/get-trades';
import { useWebSocketStore } from '@/store/use-websocket-store';

export default function Activity() {
  const { symbol } = useParams() as Readonly<{ symbol: string }>;
  const [unit, setUnit] = useState(abbrFromSym(symbol));

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  const { isLoading: areTradesLoading } = useTrades({ symbol });

  useEffect(() => {
    if (!publicWs || areTradesLoading) return;
    const smbl = symbol;
    publicWs.subscribeToChannels([`${smbl}@trades`]);
    return () => {
      publicWs.unsubscribeFromChannels([`${smbl}@trades`]);
    };
  }, [symbol, publicWs, areTradesLoading, isUpdated]);

  return (
    <Tabs
      defaultValue="order-book"
      className="flex w-full max-w-sm flex-col border-l border-border"
    >
      <TabsList className="flex h-[38px]">
        <TabsTrigger value="order-book" className="h-full flex-1 border-t-0">
          Depth
        </TabsTrigger>
        <TabsTrigger value="trades" className="h-full flex-1 border-t-0">
          Trades
        </TabsTrigger>
        <UnitSelect
          defaultValue={abbrFromSym(symbol)}
          className="h-full max-w-max border-b border-border [&>button]:h-full [&>button]:bg-background [&>button]:px-4"
          units={[COLLATERAL_SYMBOL, abbrFromSym(symbol)]}
          onSelect={(value) => setUnit(value)}
        />
      </TabsList>
      <TabsContent className="relative flex-1" value="order-book">
        <OrderBook unit={unit} />
      </TabsContent>
      <TabsContent className="relative flex-1" value="trades">
        <Trades unit={unit} />
      </TabsContent>
    </Tabs>
  );
}
