import { FC, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui';

import { useModalStore } from '@/store/use-modal-store';

export const SwitchModal: FC = () => {
  const { isSwitchModalOpen, handleSwitchModalOpen } = useModalStore();

  return (
    <Dialog open={isSwitchModalOpen} onOpenChange={handleSwitchModalOpen}>
      <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-lg sm:rounded-none">
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">
            You are trying to interact with an inactive account in your wallet
          </DialogTitle>
        </DialogHeader>

        <div className="relative p-5 text-lg">
          <div className="pb-3">
            Since this address is not currently active in your wallet,
            <br /> it must be switched before interacting with the smart
            contract.
          </div>
          <div className="pb-2">To switch a new address </div>
          - Open your wallet. <br />- Select the correct account.
          <div className="py-5">
            Thanks for your patience. Since wallet providers don’t allow
            programmatic address switching, this must be done manually.
          </div>
          <a
            href="https://support.metamask.io/configure/accounts/switching-accounts-in-metamask/"
            target="_blank"
            className="text-primary hover:underline"
          >
            How to Switch Accounts in MetaMask
          </a>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            className="h-14 w-full rounded-none font-mono text-lg font-normal tracking-wider"
            onClick={() => handleSwitchModalOpen(false)}
          >
            Got it
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
